import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { ListSelectableDirective } from '../../directives/list-selectable.directive';
import { ListSelectionHandler } from '../../services/list-selection-handler.service';
import { AbstractList } from '../abstract-list';

/**
 * CelumVirtualList
 *
 * This list is capable of a combined infinite and virtual scroll and it uses the perfect scrollbar.
 * It extends from the AbstractList but not all functionality is implemented.
 * In addition to the explicit Inputs/Outputs, you can set "hasBottomMore" and listen to "requestNextPage".
 *
 * In order to get the virtual scrolling working - there are some limitations on how you can use this component
 *  -- the height of a single item has to be fixed and known as it has to be passed as input parameter
 *  -- only one column is possible
 *  -- it can only be used vertically (but this is something that could be changed in the future)
 *
 * Scrollbar configurations
 *  -- scrollbarOverContent: false -> scrollbar will be visible next to the content and not over it and it will always be visible
 *  -- scrollbarOverContent: true -> scrollbar will be visible on top of the content and only on hover of the list
 *
 * Example:
 *
 * <celum-virtual-list [items]="[1, 2, 3]" [itemHeightPx]="10" [maxContainerHeightPx]="100">
 *   <ng-template let-item>Number {{ item }}</ng-template>
 * </celum-virtual-list>
 */
@Component({
  selector: 'celum-virtual-list',
  templateUrl: './virtual-list.html',
  styleUrls: ['./virtual-list.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class CelumVirtualList<T = any> extends AbstractList<T> implements OnChanges, AfterViewInit {
  // Mandatory
  @Input() public itemHeightPx: number;

  // Optional
  @Input() public maxContainerHeightPx: number | undefined; // If undefined, scroll container's height is 100%
  @Input() public darkScrollbar = false;
  // Only used for perfect scrollbar, if more space is needed between the list and the scrollbar for native scroller, use the
  // --clm-virtual-list-item-padding-right less variable
  @Input() public scrollbarOverContent = false; // If true, the scrollbar will be rendered over the content, not next to it
  @Input() public trackByFn: (index: number, item: T) => any = (index, item) => item;

  @ViewChild(CdkVirtualScrollViewport, { static: false }) public viewport: CdkVirtualScrollViewport;
  @ContentChild(TemplateRef, { static: false }) public templateRef: TemplateRef<any>;

  @HostBinding('class.virtual-list') public hostCls = true;

  public scrollContainerStyles: Record<string, string> = {
    height: '100%'
  };

  private lastEmitCount = 0;

  constructor(
    changeDetector: ChangeDetectorRef,
    @Optional() protected listSelectionDirective?: ListSelectableDirective<T>
  ) {
    super(changeDetector);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const { items, itemHeightPx, maxContainerHeightPx } = changes;

    if (this.maxContainerHeightPx && (items || itemHeightPx || maxContainerHeightPx)) {
      this.setContainerScrollHeight();
    }

    // reset emit count if number of items decreases to avoid endless loading, this ensures that the next scroll to bottom triggers an emission
    if (items && items.previousValue?.length > this.items?.length) {
      this.lastEmitCount = 0;
    }
  }

  public ngAfterViewInit(): void {
    // if no selection handler provided, try to take it from directive
    this.selectionHandler = this.selectionHandler ?? this.listSelectionDirective;

    super.ngAfterViewInit();
  }

  protected virtualScrolled(): void {
    if (!this.hasMoreBottom) {
      return;
    }

    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();

    if (end >= total && end !== this.lastEmitCount) {
      this.lastEmitCount = end; // if scrolled while loading, this would emit multiple times - so remember last end count of items and check if it changed
      this.requestNextPage.emit(true);
    }
  }

  protected isSelected(selection: T[], item: T, selectionHandler: ListSelectionHandler<T>): boolean {
    return selectionHandler?.isInSelection(item);
  }

  private setContainerScrollHeight(): void {
    const neededHeight = this.items.length * this.itemHeightPx;
    // the viewport has to have a fixed size but we want to be able to shrink if we don't need so much space - so this will calculate how many
    // space is really needed and sets the height according to that.
    this.scrollContainerStyles = {
      ...this.scrollContainerStyles,
      height: `${Math.min(neededHeight, this.maxContainerHeightPx)}px`
    };
    requestAnimationFrame(() => this.viewport.checkViewportSize()); // scrollContainerHeight has to be set in order to recalculation to work
  }
}
