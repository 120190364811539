import { produce } from 'immer';

import { GraphEntity, groupArrayBy, hasValueChanged, LastLoaded, OptionalAttribute, RecursivePartial } from '@celum/core';

import { EntitiesState } from './entities-state';
import { Entity, EntityId } from '../../entity/entity';
import { UpdateStrategy } from '../../entity/entity-registry';
import { EntityState } from '../../entity-store/entity-state';

interface HasIndexSignature {
  [key: string]: any;
}

interface UpdateEntityResult<T> {
  /* represents the value after doing all updates */
  result: T;
  /* represents updated properties which were influenced by the given update strategy */
  strategyResult: RecursivePartial<T>;
  /* gives information if anything changed in comparison the the existing value that was passed in */
  hasChanges: boolean;
}

export function updateDetailCounts(entity: GraphEntity, mergedEntity: GraphEntity, oldEntity: GraphEntity, lastLoadInfo: LastLoaded): boolean {
  let hasChanges = false;

  entity.detailCounts.forEach((value, key) => {
    if (mergedEntity.detailCounts.get(key) !== value) {
      if (mergedEntity.detailCounts === oldEntity.detailCounts) {
        mergedEntity.detailCounts = new Map(oldEntity.detailCounts);
      }

      mergedEntity.detailCounts.set(key, entity.detailCounts.get(key));
      hasChanges = true;
    }

    lastLoadInfo.detailCounts[key] = Date.now();
  });

  return hasChanges;
}

export function updatePermissions(entity: GraphEntity, mergedEntity: GraphEntity, oldEntity: GraphEntity, lastLoadInfo: LastLoaded): boolean {
  let hasChanges = false;

  entity.permissions.forEach((value, key) => {
    lastLoadInfo.permissions[key] = Date.now();

    if (mergedEntity.permissions.get(key) !== value) {
      if (mergedEntity.permissions === oldEntity.permissions) {
        mergedEntity.permissions = new Map(oldEntity.permissions);
      }

      mergedEntity.permissions.set(key, entity.permissions.get(key));
      hasChanges = true;
    }
  });

  return hasChanges;
}

export function updateRelations(entity: GraphEntity, mergedEntity: GraphEntity, oldEntity: GraphEntity, lastLoadInfo: LastLoaded): boolean {
  let hasChanges = false;
  const now = Date.now();

  // handle relations map
  entity.relations.forEach((value, key) => {
    lastLoadInfo.relations[key] = now;

    if (!mergedEntity.relations.has(key)) {
      if (mergedEntity.relations === oldEntity.relations) {
        mergedEntity.relations = new Map(oldEntity.relations);
      }

      mergedEntity.relations.set(key, value);
      hasChanges = true;
    }
  });

  return hasChanges;
}

export function updateKnownRelationTypes(entity: GraphEntity, mergedEntity: GraphEntity, oldEntity: GraphEntity): boolean {
  let hasChanges = false;

  // handle knownRelationTypesMap
  entity.knownRelationTypes.forEach(value => {
    if (!mergedEntity.knownRelationTypes.has(value)) {
      if (mergedEntity.knownRelationTypes === oldEntity.knownRelationTypes) {
        mergedEntity.knownRelationTypes = new Set(oldEntity.knownRelationTypes);
      }

      mergedEntity.knownRelationTypes.add(value);
      hasChanges = true;
    }
  });

  return hasChanges;
}

export function updateKnownDetailCountIdentifier(entity: GraphEntity, mergedEntity: GraphEntity, oldEntity: GraphEntity): boolean {
  let hasChanges = false;

  // handle knownDetailCountIdentifier
  entity.knownDetailCountIdentifier.forEach(value => {
    if (!mergedEntity.knownDetailCountIdentifier.has(value)) {
      if (mergedEntity.knownDetailCountIdentifier === oldEntity.knownDetailCountIdentifier) {
        mergedEntity.knownDetailCountIdentifier = new Set(oldEntity.knownDetailCountIdentifier);
      }

      mergedEntity.knownDetailCountIdentifier.add(value);
      hasChanges = true;
    }
  });

  return hasChanges;
}

export function updateOptionalAttributes(entity: GraphEntity, mergedEntity: GraphEntity & HasIndexSignature, lastLoadInfo: LastLoaded): boolean {
  let hasChanges = false;

  entity.optionalAttributes.forEach((_, key) => {
    const oldValue = mergedEntity[key] as OptionalAttribute<any>;
    const newValue = (entity as any)[key] as OptionalAttribute<any>;

    lastLoadInfo.optionalAttributes[key] = Date.now();

    if (newValue.isPresent()) {
      if (!oldValue.isPresent()) {
        // property was not loaded before
        mergedEntity[key] = newValue;
        hasChanges = true;
      } else {
        // property already was loaded before
        if (hasValueChanged(oldValue.get(), newValue.get())) {
          hasChanges = true;
          mergedEntity[key] = newValue;
        }
      }
    }
  });

  return hasChanges;
}

export function updatePartialAttributes(entity: GraphEntity, mergedEntity: GraphEntity & HasIndexSignature): boolean {
  let hasChanges = false;

  entity.partialAttributeMergeFunctions.forEach((mergeFn, name) => {
    const wasPartiallyLoaded = mergedEntity.partialAttributes.has(name);
    const isFullyLoadedNow = !entity.partialAttributes.has(name);

    const oldValue: any = mergedEntity[name];
    const nextValue: any = (entity as any)[name];
    const mergedValue = isFullyLoadedNow || !oldValue ? nextValue : mergeFn(oldValue, nextValue);

    if (hasValueChanged(oldValue, mergedValue)) {
      hasChanges = true;
      mergedEntity[name] = mergedValue;
    }

    if (wasPartiallyLoaded && isFullyLoadedNow) {
      hasChanges = true;
      mergedEntity.partialAttributes = new Set<string>([...mergedEntity.partialAttributes]);
      mergedEntity.partialAttributes.delete(name);
    }
  });

  return hasChanges;
}

/**
 * A function which merges an entity onto an existing one based on the given updateStrategy
 * @param newValue entity from which properties should be copied to the existing entity
 * @param existingValue entity to receive updated values from the new entity
 * @param updateStrategy which defines which properties should be updated in which way
 */
export function updateEntityAttributes<T>(newValue: T, existingValue: T, updateStrategy: UpdateStrategy<T>): UpdateEntityResult<T> {
  let hasChanges = false;

  if (typeof updateStrategy === 'function') {
    const merged = updateStrategy(existingValue, newValue);
    hasChanges = hasValueChanged(existingValue, merged);
    return { result: merged, hasChanges, strategyResult: merged };
  }

  const strategyResult: RecursivePartial<T> = {};

  const keys = Object.keys(updateStrategy) as (keyof T)[];
  keys.forEach(key => {
    const oldValue = existingValue[key];
    const value = newValue[key];

    // right now it would be sufficient to just call the updateStrategy from here instead of doing it via an recursive call
    // it is implemented that way because it was meant to be recursive initially but then was dropped to save some time
    // the only thing that's hindering the user currently is on type level, as the UpdateStrategy is not a recursive type
    // we'll keep this implementation though to make transitioning to an recursive type easier (only type and tests need to be adapted then)
    const updateResult = updateEntityAttributes(value, oldValue, updateStrategy[key]);
    strategyResult[key] = updateResult.strategyResult;

    if (updateResult.hasChanges) {
      hasChanges = true;
      existingValue[key] = updateResult.result;
    }
  });

  return { result: existingValue, hasChanges, strategyResult };
}

/**
 * A function that takes in entities and makes sure that they are correctly represented in the byType record.
 * @param entities which should be added to the record
 * @param byType the currently existing record of typeKey/Set<EntityId>
 */
export function updateEntitiesByTypeRecord<T extends Entity>(entities: T[], byType: Record<string, Set<T['id']>>): void {
  const entityByTypeMap = groupArrayBy(entities, entity => entity.typeKey);

  entityByTypeMap.forEach((entitiesForType, typeKey) => {
    const entitiesByType = byType[typeKey];

    if (!entitiesByType) {
      byType[typeKey] = new Set(entitiesForType.map(entity => entity.id));
      return;
    }

    const newIdsForType = new Set(byType[typeKey]);

    let hasChanged = false;
    entitiesForType
      .filter(entity => !newIdsForType.has(entity.id))
      .forEach(entity => {
        hasChanged = true;
        newIdsForType.add(entity.id);
      });

    if (hasChanged) {
      byType[typeKey] = newIdsForType;
    }
  });
}

export function deleteEntities<STATE extends EntityState | EntitiesState>(state: STATE, entityIdsToDelete: EntityId[]): STATE {
  const entitiesToDelete = new Set(entityIdsToDelete);

  return produce(state, draft => {
    entityIdsToDelete.forEach(id => delete draft.entitiesById[id]);

    Object.values(draft.entitiesByType).forEach(typeEntityIds => {
      entitiesToDelete.forEach(id => {
        const deleted = typeEntityIds.delete(id as any);
        deleted && entitiesToDelete.delete(id);
      });
    });
  });
}
