import { produce } from 'immer';

import { UploadActions, UploadActionTypes } from './upload-actions';
import { initialUploadState, UploadState } from './upload-state';
import { ItemProgress, ProgressStatus, TicketProgressStates } from '../../model/progress-state';

export function uploadReducer(state: UploadState = initialUploadState, action: UploadActions): UploadState {
  return produce(state, draft => {
    switch (action.type) {
      case UploadActionTypes.PrepareUploads: {
        // Create queue if necessary
        if (!draft.queues[action.queueId]) {
          draft.queues[action.queueId] = {
            progress: {
              done: 0,
              size: 0,
              status: ProgressStatus.Queued,
              identifier: action.queueId
            } as ItemProgress,
            tickets: new Set(),
            ticketStates: {
              [ProgressStatus.Queued]: 0,
              [ProgressStatus.Running]: 0,
              [ProgressStatus.Error]: 0,
              [ProgressStatus.Success]: 0
            } as TicketProgressStates
          };
        }

        // Add tickets to queue
        const existingTickets = draft.queues[action.queueId].tickets;
        const newTickets = action.ticketInfo.filter(info => !existingTickets.has(info.ticket));

        if (newTickets.length > 0) {
          draft.queues[action.queueId].tickets = new Set([...Array.from(existingTickets), ...newTickets.map(info => info.ticket)]);
          draft.queues[action.queueId].progress.size += newTickets.reduce((acc, next) => acc + next.size, 0);
          draft.queues[action.queueId].ticketStates[ProgressStatus.Queued] += newTickets.length;
        }

        action.ticketInfo.forEach(ticketInfo => {
          draft.tickets[ticketInfo.ticket] = {
            queueId: action.queueId,
            progress: {
              done: 0,
              size: Math.max(1, ticketInfo.size), // avoid divided by zero exception for zero-byte files, UploadFinished handles progress.
              status: ProgressStatus.Queued,
              identifier: ticketInfo.ticket
            }
          };
        });

        break;
      }
      case UploadActionTypes.StartUpload: {
        action.tickets.forEach(ticket => {
          const queue = draft.queues[draft.tickets[ticket].queueId];

          const previousStatus = draft.tickets[ticket].progress.status;
          queue.ticketStates[previousStatus]--;
          queue.ticketStates[ProgressStatus.Running]++;

          draft.tickets[ticket].progress.status = ProgressStatus.Running;
          queue.progress.status = calculateProgressForQueue(queue.ticketStates);
        });

        break;
      }
      case UploadActionTypes.RemoveUpload: {
        const ticket = draft.tickets[action.ticket];

        // Update queue if existent
        if (ticket && ticket.queueId && draft.queues[ticket.queueId]) {
          const queue = draft.queues[ticket.queueId];
          queue.ticketStates[ticket.progress.status] -= 1;
          queue.progress.done -= ticket.progress.done;
          queue.progress.size -= ticket.progress.size;
          queue.progress.status = calculateProgressForQueue(queue.ticketStates);
          const existingTickets = queue.tickets;
          if (existingTickets.has(action.ticket)) {
            const newTickets = new Set(existingTickets);
            newTickets.delete(action.ticket);
            draft.queues[ticket.queueId].tickets = newTickets;
          }
        }

        // Delete from tickets
        delete draft.tickets[action.ticket];

        break;
      }
      case UploadActionTypes.RemoveUploadQueue: {
        const queue = action.queueId && draft.queues[action.queueId];
        if (queue) {
          const tickets = draft.queues[action.queueId].tickets || new Set();
          delete draft.queues[action.queueId];
          tickets.forEach(ticket => delete draft.tickets[ticket]);
        }

        break;
      }
      case UploadActionTypes.UploadEvent: {
        action.events
          .filter(event => !!draft.tickets[event.ticket])
          .forEach(event => {
            event.type === 'progress' && handleUploadProgress(draft, event.ticket, event.progress);
            event.type === 'failed' && handleUploadFailed(draft, event.ticket, event.error);
            event.type === 'finished' && handleUploadFinished(draft, event.ticket);
          });

        break;
      }
    }
  });
}

const handleUploadProgress = (draft: UploadState, ticketId: string, done: number) => {
  const ticket = draft.tickets[ticketId];
  const sanitizedDone = Math.min(done, ticket.progress.size);
  draft.queues[ticket.queueId].progress.done += sanitizedDone - ticket.progress.done;
  ticket.progress.done = sanitizedDone;
};

const handleUploadFailed = (draft: UploadState, ticketId: string, error: any) => {
  const ticket = draft.tickets[ticketId];
  const queue = draft.queues[ticket.queueId];

  // Update queue
  queue.ticketStates[ticket.progress.status] -= 1;
  queue.ticketStates[ProgressStatus.Error] += 1;
  queue.progress.done += ticket.progress.size - ticket.progress.done;
  queue.progress.status = calculateProgressForQueue(queue.ticketStates);

  // Update ticket
  ticket.progress.status = ProgressStatus.Error;
  ticket.progress.error = error;
  ticket.progress.done = ticket.progress.size;
};

const handleUploadFinished = (draft: UploadState, ticketId: string) => {
  const ticket = draft.tickets[ticketId];
  const queue = draft.queues[ticket.queueId];

  // Update queue
  queue.ticketStates[ticket.progress.status] -= 1;
  queue.ticketStates[ProgressStatus.Success] += 1;
  queue.progress.done += ticket.progress.size - ticket.progress.done;
  queue.progress.status = calculateProgressForQueue(queue.ticketStates);

  // Update ticket
  ticket.progress.status = ProgressStatus.Success;
  ticket.progress.done = ticket.progress.size; // Progress should always be 100% when upload finished (zero-byte-files, missed progress event)
};

/**
 * Evaluates the state of the queue
 * @param ticketStates number of states per possible option
 * @return
 * ProgressStatus.Queued: No elements or only queued elements
 * ProgressStatus.Running: Any state with running elements or state with queued elements and at least one error or success element
 * ProgressStatus.Error: No queued or running elements and a error element
 * ProgressStatus.Success: Only success elements
 */
export const calculateProgressForQueue = (ticketStates: TicketProgressStates): ProgressStatus => {
  const queued = ticketStates[ProgressStatus.Queued];
  const running = ticketStates[ProgressStatus.Running];
  const error = ticketStates[ProgressStatus.Error];
  const success = ticketStates[ProgressStatus.Success];
  const warning = ticketStates[ProgressStatus.Warning];

  if (running > 0) {
    return ProgressStatus.Running;
  }

  if (queued + running + error + success + warning === 0) {
    return ProgressStatus.Queued;
  }

  if (queued > 0) {
    return error + success + warning === 0 ? ProgressStatus.Queued : ProgressStatus.Running;
  }

  if (error > 0) {
    return ProgressStatus.Error;
  }

  if (warning > 0) {
    return ProgressStatus.Warning;
  }

  return ProgressStatus.Success;
};
