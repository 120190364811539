export class PreviewUtil {
  /**
   * Evaluates the best option for css-background-size property for given preview
   * @param previewState preview state
   * @returns css style for background-size
   */
  public static evaluatePreviewBackgroundSize(previewState: PreviewState): ImageBackgroundSize {
    const ps = previewState;

    const imageSmallerThanParent = ps.parentWidth >= ps.imageWidth && ps.parentHeight >= ps.imageHeight;

    if (ps.isDefaultPreview) {
      return '50% 50%';
    } else if (ps.parentWidth + ps.parentHeight === 0 || imageSmallerThanParent) {
      return 'initial';
    } else if (ps.parentWidth === ps.parentHeight) {
      return ps.descriptor === Descriptor.DOCUMENT ? 'contain' : 'cover';
    } else {
      const portrait = ps.imageHeight > ps.imageWidth;

      const breakPointByDescriptor = new Map([
        [Descriptor.IMAGE, portrait ? 1.33 : 2.1],
        [Descriptor.VIDEO, 1000],
        [Descriptor.DOCUMENT, 1.33]
      ]);

      const breakPoint = breakPointByDescriptor.get(ps.descriptor) ?? 0;
      const ratio = Math.max(ps.imageHeight / ps.imageWidth, ps.imageWidth / ps.imageHeight);
      return ratio < breakPoint ? 'cover' : 'contain';
    }
  }
}

export type ImageBackgroundSize = 'cover' | 'contain' | 'initial' | '50% 50%' | '90% 90%';

export class PreviewState {
  /**
   * Holds state of a preview item
   * @param isDefaultPreview if only filetype icon is shown
   * @param parentWidth width of parent element
   * @param parentHeight height of parent element
   * @param imageWidth width of preview
   * @param imageHeight height of preview
   * @param descriptor file descriptor
   */
  constructor(
    public isDefaultPreview: boolean,
    public parentWidth: number,
    public parentHeight: number,
    public imageWidth: number,
    public imageHeight: number,
    public descriptor?: Descriptor
  ) {}
}

export enum Descriptor {
  IMAGE = 'imageDescriptor',
  VIDEO = 'videoDescriptor',
  DOCUMENT = 'documentDescriptor'
}
