import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ColorConstants } from '../../base/color-constants';
import { IconConfiguration } from '../../icon/icon-configuration';

@Component({
  selector: 'celum-inline-form-field-buttons',
  templateUrl: './celum-inline-form-field-buttons.html',
  styleUrls: ['./celum-inline-form-field-buttons.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CelumInlineFormFieldButtons implements OnChanges {
  @Input() public iconColor: string;

  @Output() public readonly save: EventEmitter<void> = new EventEmitter<void>();
  // eslint-disable-next-line  @angular-eslint/no-output-native
  @Output() public readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  public saveIcon = IconConfiguration.medium('check-m').withColor(ColorConstants.SYSTEM_WHITE);
  public cancelIcon = IconConfiguration.medium('cancel-m').withColor(ColorConstants.SYSTEM_WHITE);
  public editIcon = IconConfiguration.medium('edit-m').withColor(ColorConstants.SYSTEM_WHITE);

  public active = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnChanges({ iconColor }: SimpleChanges): void {
    if (iconColor.currentValue) {
      this.saveIcon = IconConfiguration.medium('check-m').withColor(iconColor.currentValue);
      this.cancelIcon = IconConfiguration.medium('cancel-m').withColor(iconColor.currentValue);
      this.editIcon = IconConfiguration.medium('edit-m').withColor(iconColor.currentValue);
    }
  }

  public changeActiveState(active: boolean): void {
    this.active = active;
    this.cdRef.markForCheck();
  }

  public onSave(event: MouseEvent): void {
    this.save.emit();
    event.stopPropagation();
  }

  public onCancel(event: MouseEvent): void {
    this.cancel.emit();
    event.stopPropagation();
  }
}
