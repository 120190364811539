export interface CelumError {
  failures: Failure[];
  nestedException: any;
}

export interface Failure {
  arguments: any[];
  failureCode: FailureCode;
}

export interface FailureCode {
  code: number;
  featureIdentifier: FeatureIdentifier;
  message: string;
}

export interface FeatureIdentifier {
  identifier: string;
}

export class EntityNotFound {}

export class CommunicationException {
  constructor(
    public messageKey: string,
    public error?: any
  ) {}
}

export class ObjectGraphException {
  constructor(public messageKey: string) {}
}
