import { MatDialogConfig } from '@angular/material/dialog';

import { Context, Extension, NoEntity, Scope } from '@celum/core';

import { CelumDialogConfiguration } from './model/celum-dialog-configuration';

export abstract class DialogExtension implements Extension {
  public static type = 'DialogExtension';

  public getScope(): Scope {
    return Scope.forContext(NoEntity, Context.of(this.getDialogType()));
  }

  public abstract getDialogType(): string;

  public abstract getComponentClass(): any;

  public abstract getConfiguration(elementId: string): CelumDialogConfiguration;

  public abstract getIdentifier(): string;

  public getTypeIdentifier(): string {
    return DialogExtension.type;
  }

  public getMatDialogConfiguration(): MatDialogConfig {
    return undefined;
  }
}
