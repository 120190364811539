import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  OnInit,
  OutputEmitterRef,
  Type,
  viewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { select, Store } from '@ngrx/store';
import { isObservable } from 'rxjs';
import { take } from 'rxjs/operators';

import { RemoveSnackbar, SnackbarComponent, SnackbarElementComponent, SnackbarRegistry } from '@celum/common-components';
import { ComponentCreatorUtil, ElementTag } from '@celum/ng2base';

import { getSnackbars, SnackbarCreationInformation } from '../store/snackbar-state';

@Component({
  selector: 'snackbar-list',
  template: `
    <ng-container #snackbarList></ng-container>
  `,
  styleUrls: ['./snackbar-list.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SnackbarList implements OnInit {
  public snackbars: { [key: string]: ComponentRef<SnackbarComponent<any>> | ElementTag<SnackbarElementComponent<any>> } = {};

  @HostBinding('class.snackbar-list') public hostCls = true;

  private snackbarListContainerRef = viewChild('snackbarList', { read: ViewContainerRef });
  private changeDetector = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private store = inject(Store);
  private elementRef = inject(ElementRef);

  public ngOnInit(): void {
    this.store.pipe(select(getSnackbars), takeUntilDestroyed(this.destroyRef)).subscribe(snackbars => {
      const modified = snackbars.filter(snackbar => this.snackbars[snackbar.id]);
      const removed = Object.keys(this.snackbars).filter(id => !snackbars.find(snackbar => snackbar.id === id));
      const added = snackbars.filter(snackbar => !this.snackbars[snackbar.id]);

      added.forEach(snackbar => {
        const index = snackbar.placeOnTop ? 0 : this.snackbarListContainerRef().length;
        this.createSnackbar(snackbar.id, SnackbarRegistry.getComponentOrElementTag(snackbar.id), { ...snackbar.config }, index);
      });
      modified.forEach(snackbar => this.handleModifiedSnackbar(snackbar));
      removed.forEach(id => this.destroySnackbar(id));

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  private handleModifiedSnackbar(snackbar: SnackbarCreationInformation): void {
    const existingSnackbar = this.snackbars[snackbar.id];
    if (!(existingSnackbar instanceof ComponentRef)) {
      ComponentCreatorUtil.updateExtensionProperties(this.snackbars[snackbar.id] as ElementTag<SnackbarElementComponent<any>>, { config: snackbar.config });
      return;
    }

    const existingComponentType = existingSnackbar.componentType;
    const newComponentType = SnackbarRegistry.getComponentOrElementTag(snackbar.id);

    if (existingComponentType === newComponentType) {
      existingSnackbar.instance.configure({ ...snackbar.config });
      return;
    }

    // If the component type changed (example: from progress snackbar to simple snackbar), we destroy the snackbar and create the new one in its place
    const index = this.snackbarListContainerRef().indexOf(existingSnackbar.hostView);
    this.destroySnackbar(snackbar.id);
    this.createSnackbar(snackbar.id, newComponentType, { ...snackbar.config }, index);
  }

  private createSnackbar(id: string, componentOrElementTag: Type<SnackbarComponent<any>> | string, config: any, index: number): void {
    if (!componentOrElementTag) {
      console.error(`No component or elementTag registered for snackbar with id ${id}`);
      return;
    }

    if (typeof componentOrElementTag === 'string') {
      const angularElement = ComponentCreatorUtil.createAngularElement<SnackbarElementComponent<any>>(
        componentOrElementTag,
        this.elementRef.nativeElement,
        index
      );
      this.snackbars[id] = angularElement;
      ComponentCreatorUtil.updateExtensionProperties(angularElement, { config });
      const dismiss$ = ComponentCreatorUtil.listenToExtensionOutput(angularElement, 'dismiss');
      dismiss$?.pipe(take(1)).subscribe(() => this.store.next(new RemoveSnackbar(id)));
    } else {
      const componentRef: ComponentRef<SnackbarComponent<any>> = this.snackbarListContainerRef().createComponent(componentOrElementTag, { index });
      this.snackbars[id] = componentRef;

      componentRef.instance.configure({ ...config }); // as this comes from the store and is not allowed to be modified... make sure that actual snackbar cannot
      // produce errors
      isObservable(componentRef.instance.dismiss) && componentRef.instance.dismiss.pipe(take(1)).subscribe(() => this.store.next(new RemoveSnackbar(id)));
      isOutputEmitterRef(componentRef.instance.dismiss) && componentRef.instance.dismiss.subscribe(() => this.store.next(new RemoveSnackbar(id)));
    }

    this.changeDetector.detectChanges();
  }

  private destroySnackbar(id: string): void {
    const componentOrAngularElement = this.snackbars[id];
    delete this.snackbars[id];
    if (componentOrAngularElement instanceof ComponentRef) {
      componentOrAngularElement.destroy();
    } else {
      componentOrAngularElement.remove();
    }
  }
}

function isOutputEmitterRef<T>(output: any): output is OutputEmitterRef<T> {
  return output && !isObservable(output) && output.subscribe !== undefined;
}
