import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

import { Systembar } from '../../model/systembar/systembar';

@Component({
  selector: 'celum-systembar',
  templateUrl: './systembar-component.html',
  styleUrls: ['./systembar-component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class CelumSystembarComponent {
  @Input() public systembar: Systembar;

  public celumIconConfiguration = new IconConfiguration('celum').withIconDimensions(25, 32);

  @HostBinding('class.celum-systembar') public hostCls = true;
}
