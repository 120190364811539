import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { skip, takeUntil } from 'rxjs/operators';

import { LocalizedValue, ReactiveService } from '@celum/core';

import { TranslationHelper } from '../translation/translation-helper';

/**
 * This pipe sorts the incoming array by the translation of it's values
 * It will listen for locale changes and adapts the sorting as needed while keeping change detection cycles to a minimum
 */
@Pipe({
  name: 'sortLocalized',
  pure: false, // needs to be false as we call change detection again if locale changes
  standalone: false
})
export class SortLocalizedPipe extends ReactiveService implements PipeTransform {
  private currentResult: any[];
  // [inputArray, toLocalizedValueFn, inputDirection, locale]
  private args: [any[], (value: any) => string | LocalizedValue, boolean, string];

  constructor(
    protected translate: TranslationHelper,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  /***
   * Sorts the incoming array
   * @param array which should be sorted
   * @param toLocalizedValueFn to convert one array element to it's localized value
   * @param ascending sort direction (false means descending)
   */
  public transform<V>(array: V[], toLocalizedValueFn: (value: V) => string | LocalizedValue, ascending = true): V[] {
    if (!array || !toLocalizedValueFn) {
      return null;
    }

    if (this.isInputEqual(array, toLocalizedValueFn, ascending)) {
      return this.currentResult; // just return the cached value if no input parameter or locale changed
    }

    if (!this.currentResult) {
      // only start listening to locale changes once
      this.runChangeDetectionOnLocaleChange();
    }

    return this.setValue(array, toLocalizedValueFn, ascending);
  }

  /**
   * Check if the current input is the same as it was for the previous result
   */
  private isInputEqual<V>(array: V[], toLocalizedValueFn: (value: V) => string | LocalizedValue, ascending: boolean): boolean {
    return this.args?.[0] === array && this.args?.[1] === toLocalizedValueFn && this.args?.[2] === ascending && this.args?.[3] === this.translate.locale;
  }

  /**
   * Sort values according to given inputs and save the arguments to be able to serve a cached value afterwards
   */
  private setValue<V>(array: V[], toLocalizedValueFn: (value: V) => string | LocalizedValue, ascending: boolean): V[] {
    const sortFn = (a: V, b: V) => this.translateValue(toLocalizedValueFn(a))?.localeCompare(this.translateValue(toLocalizedValueFn(b)));
    const result = [...array].sort((a, b) => sortFn(ascending ? a : b, ascending ? b : a));

    this.args = [array, toLocalizedValueFn, ascending, this.translate.locale];
    this.currentResult = result;
    return result;
  }

  private translateValue(value: string | LocalizedValue): string {
    return this.translate.instant(value, this.translate.locale, this.translate.locale);
  }

  private runChangeDetectionOnLocaleChange(): void {
    // change detection causes another call of the transform function which will re-evaluate the result
    this.translate
      .currentLocaleStream()
      .pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => this.changeDetector.markForCheck());
  }
}
