import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EscapeHtmlPipe } from './escape-html.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FnWrapperPipe } from './fn-wrapper.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { ObservablePipe } from './observable';
import { ParseLineBreaksPipe } from './parse-line-breaks.pipe';
import { ParseLinksPipe } from './parse-links.pipe';
import { SanitizedPipe } from './sanitized.pipe';
import { SortLocalizedPipe } from './sort-localized.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { StripUrlProtocolPipe } from './strip-url-protocol';
import { TruncateTextPipe } from './truncate-text.pipe';

@NgModule({
  imports: [CommonModule, FnWrapperPipe],
  declarations: [
    EscapeHtmlPipe,
    FileSizePipe,
    ObservablePipe,
    ParseLinksPipe,
    ParseLineBreaksPipe,
    StripHtmlPipe,
    FormatDatePipe,
    FormatNumberPipe,
    SanitizedPipe,
    StripUrlProtocolPipe,
    SortLocalizedPipe,
    TruncateTextPipe
  ],
  exports: [
    EscapeHtmlPipe,
    FileSizePipe,
    FnWrapperPipe,
    ObservablePipe,
    ParseLinksPipe,
    ParseLineBreaksPipe,
    StripHtmlPipe,
    FormatDatePipe,
    FormatNumberPipe,
    SanitizedPipe,
    StripUrlProtocolPipe,
    SortLocalizedPipe,
    TruncateTextPipe
  ]
})
export class CelumPipesModule {}
