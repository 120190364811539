import { HttpContextToken } from '@angular/common/http';

import { hasValue } from '@celum/core';

export type AuthToken = {
  token: string;
  expiresAt?: Date;
};

export type NamedAuthToken = AuthToken & { name: string };

export type B2CTokenRequestDto = { b2cTokenRequest: boolean };

export type B2CAccessTokenRequestDto = { b2cAccessTokenRequest: boolean; scope: string[] };

export const B2C_SERVICE_TOKEN_REQUEST_DTO: B2CTokenRequestDto = { b2cTokenRequest: true };

export type ServiceTokenRequestDto = {
  clientId: string;
  orgId: string;
};

export type TokenRequestDto = ServiceTokenRequestDto | B2CTokenRequestDto | B2CAccessTokenRequestDto;

export function isB2CTokenRequestDto(dto: TokenRequestDto): dto is B2CTokenRequestDto {
  return (dto as B2CTokenRequestDto)?.b2cTokenRequest === true;
}

export function isB2CAccessTokenRequestDto(dto: TokenRequestDto): dto is B2CAccessTokenRequestDto {
  return (dto as B2CAccessTokenRequestDto)?.b2cAccessTokenRequest === true;
}

export function isServiceTokenRequestDto(dto: TokenRequestDto): dto is ServiceTokenRequestDto {
  return hasValue((dto as ServiceTokenRequestDto).clientId);
}

/**
 * The AUTH_CONTEXT is a HttpContextToken which can be used to pass additional auth context information to interceptors.
 */
export const AUTH_CONTEXT = new HttpContextToken<AuthContext>(() => ({}));

/**
 * The AuthContext is a map of key-value pairs which can be used to pass additional information to the token provider.
 *
 * E.g. for Portals we create the following auth context:
 * { portalId: <uuid> }
 *
 * Our token endpoint gets this context and creates a token for this specific portal, which
 * can later be used to access portal specific resources.
 */
export type AuthContext = { [name: string]: string };

/**
 * Options for getting an authentication token.
 */
export type AuthTokenRequestOptions = {
  tokenLeadTimeInMilliseconds?: number;
  authContext?: AuthContext;
};
