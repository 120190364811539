<ng-container *ngIf="service.vm$ | async as vm">
  <celum-systembar [systembar]="vm.systemBar">
    @if (vm.systemBar?.notificationFeatureEnabled) {
      <systembar-notifications class="celum-systembar_components" notifications></systembar-notifications>
    }
    @if (vm.systemBar?.formatsFeatureEnabled && vm.activeFormatsAccountAccesses.length > 0) {
      <celum-systembar-formats class="celum-systembar_components" formats [contextMenuItems]="vm.formats.contextMenuItems"></celum-systembar-formats>
    }
    <celum-user-avatar small-avatar [accountAccessToken]="vm.token" [showTooltip]="false" [size]="AVATAR_SIZE.m" [user]="vm.user"></celum-user-avatar>
    <celum-user-avatar big-avatar [accountAccessToken]="vm.token" [size]="60" [user]="vm.user" [showTooltip]="false"></celum-user-avatar>
  </celum-systembar>
</ng-container>
