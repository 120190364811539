import { Inject, Injectable, Optional } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { PageContext } from '@celum/common-components';
import {
  AnyEntity,
  callExternalMethod,
  DataUtil,
  flattenObservableArray,
  NoEntity,
  OperationContext,
  OperationDefinition,
  OperationsManager
} from '@celum/core';

import { MAGIC_BUTTON_NO_ENTITY_MEANS_ANY_OR_NO_ENTITY } from '../model/magic-button-no-entity-token';
import { getContext, getFeaturedOperations, getOperations } from '../state/magic-button-reducers';

@Injectable()
export class MagicButtonService {
  public operations$: Observable<OperationDefinition[]>;
  public featuredOperations$: Observable<OperationDefinition[]>;
  public dataContext$: Observable<PageContext>;

  public subscription: Subscription;

  constructor(
    store: Store<any>,
    @Optional() @Inject(MAGIC_BUTTON_NO_ENTITY_MEANS_ANY_OR_NO_ENTITY) private noEntityMeansNoAndAny: boolean
  ) {
    this.operations$ = store.pipe(select(getOperations));

    this.featuredOperations$ = store.pipe(
      select(getFeaturedOperations),
      distinctUntilChanged((prev, curr) => MagicButtonService.checkIfOperationsChanged(prev, curr))
    );

    this.dataContext$ = store.pipe(select(getContext));
  }

  /**
   * note: only valid if the PageContext contains the "viewContext" param!
   */
  public collectAllOperations(dataContext: PageContext): Observable<{ featured: OperationDefinition[]; operations: OperationDefinition[] }> {
    if (!dataContext) {
      return of({
        operations: [],
        featured: []
      });
    }

    const contexts = dataContext.viewContext;

    const operationObs: Observable<OperationDefinition[]>[] = [];

    // just collect here, when collecting the available operations, duplicates are removed!
    if (DataUtil.isEmpty(dataContext.getSelection())) {
      // if there is an empty selection, collect those operations that are registered for AnyEntity, as this also includes no entity
      // call separately, because otherwise only operations which are defined for ALL entity types are returned!
      operationObs.push(OperationsManager.getAvailableOperations(contexts, [AnyEntity], dataContext));
    } else {
      operationObs.push(OperationsManager.getAvailableOperations(contexts, dataContext.getEntityTypes(), dataContext));
    }

    if (this.noEntityMeansNoAndAny !== false) {
      // collect operations for "empty space" (i.e. no selection) as well (regardless of any actually existing selection)
      operationObs.push(OperationsManager.getAvailableOperations(contexts, [NoEntity], dataContext));
    }

    // collect operations from the "general" context
    operationObs.push(OperationsManager.getAvailableOperationsForContext(OperationContext.GENERAL, dataContext));

    return flattenObservableArray(operationObs).pipe(
      switchMap(operations => {
        const dedupedOperations = this.addOperations(operations, []);

        return this.calculateFeaturedOperations(dedupedOperations, dataContext).pipe(
          map(featuredOps => ({
            featured: featuredOps,
            operations: dedupedOperations
          }))
        );
      })
    );
  }

  public destroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  private calculateFeaturedOperations(availableOperations: OperationDefinition[], dataContext: PageContext): Observable<OperationDefinition[]> {
    const featured: OperationDefinition[] = availableOperations.filter(operationConfig => operationConfig.priority > 0);

    const priorityObservables = featured.map(operation => {
      return callExternalMethod(
        () => operation.operation.hasPriority(dataContext),
        (err: any) => {
          console.warn(`MagicButtonService: "hasPriority" method of operation ${operation.operation.getKey()} threw an error!`, err);
          return false;
        }
      ).pipe(map(hasPrio => ({ hasPrio, operation })));
    });

    return flattenObservableArray(priorityObservables).pipe(
      map(definitions => {
        const featuredOperations = definitions.filter(def => def.hasPrio).map(def => def.operation);

        // sort by highest priority desc
        featuredOperations.sort((a, b) => b.priority - a.priority);

        // return return the first 3
        featuredOperations.splice(3);

        return featuredOperations;
      })
    );
  }

  private addOperations(operations: OperationDefinition[], containedOperations: string[]): OperationDefinition[] {
    return operations.filter(operation => {
      const opKey = operation.operation.getKey();

      // operation was already added, do not add again
      if (containedOperations.indexOf(opKey) >= 0) {
        return false;
      } else {
        containedOperations.push(opKey);
        return true;
      }
    });
  }

  private static checkIfOperationsChanged(prev: OperationDefinition[], curr: OperationDefinition[]): boolean {
    // eslint-disable-next-line eqeqeq
    return JSON.stringify(prev.map(op => op.operation.getKey())) == JSON.stringify(curr.map(op => op.operation.getKey()));
  }
}
