<div class="celum-autocomplete-inline-elements">
  <mat-form-field celum-form-field [floatLabel]="floatLabel" class="celum-autocomplete-inline-elements_form-field" aria-autocomplete="none">
    <input
      matInput
      class="celum-autocomplete-inline-elements_input"
      #autoCompleteTrigger
      [autocomplete]="inputAutocompleteAttrType"
      [placeholder]="placeholder"
      [maxLength]="maxValueLength"
      [matAutocomplete]="autocomplete"
      [formControl]="formControl"
      [readonly]="readonly"
      [required]="required"
      (keydown.arrowdown)="openPanel()"
      (click)="openPanel()"
      (focus)="onInputFocus()"
      data-private />
    <celum-icon
      class="celum-autocomplete-inline-elements_icon celum-autocomplete-inline-elements_icon--selector"
      *ngIf="withArrow"
      [configuration]="arrowDown"
      (click)="iconClicked($event)"></celum-icon>
  </mat-form-field>
  <div class="celum-autocomplete-inline-elements_icon">
    <ng-content select="[icon-after-input]"></ng-content>
  </div>
</div>
<ng-content></ng-content>
