import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration } from '@celum/common-components';
import { NavigationUtil } from '@celum/ng2base';

/**
 * Note: needs to be providedIn "any" (see https://angular.io/guide/providers). If providedIn 'root' this service needs to be provided by using modules in
 * every lazy loaded module again, because otherwise every dialog opened via this service is opened in the context of "root" and therefore any other service
 * which is only available in the lazy loaded module will not be available for created dialogs!
 * Example: DetailViewAgent is only provided in the DetailViewModule. If CelumDialogOpener is providedIn 'root' the DetailViewDialog is "created" in the
 * context of root (regardless from which component or other service it is called!) and has no access to services only provided in the DetailViewModule - as
 * for example the DetailViewAgent.
 * Therefore, use providedIn 'any' to make sure that every module gets its own instance and dialogs opened via the opener are created in the correct context.
 */
@Injectable({ providedIn: 'any' })
export class CelumDialogOpener {
  private openedDialogs = new Map<string, { dialogRef: MatDialogRef<CelumDialog<CelumDialogConfiguration>>; openedInDialogOutlet: boolean }>();

  // add specific inject for moduleId to satisfy ng2 compiler
  constructor(
    private dialog: MatDialog,
    private router: Router
  ) {}

  public closeAll(): void {
    this.dialog && this.dialog.closeAll();
  }

  public showDialog<D extends CelumDialog<C>, C extends CelumDialogConfiguration>(
    dialogId: string,
    type: new (...args: any[]) => D,
    configuration: C,
    dialogConfiguration?: MatDialogConfig
  ): Promise<any> {
    return this.openDialog(dialogId, type, configuration, dialogConfiguration);
  }

  public closeDialog(dialogId: string): void {
    const dialog = this.openedDialogs.get(dialogId);

    if (dialog) {
      dialog.dialogRef.close();
    }
  }

  private openDialog<D extends CelumDialog<C>, C extends CelumDialogConfiguration>(
    dialogId: string,
    type: new (...args: any[]) => D,
    configuration: C,
    dialogConfiguration?: MatDialogConfig
  ): Promise<any> {
    const config = dialogConfiguration || new MatDialogConfig();
    CelumDialogOpener.addTestIdToPanelClasses(dialogId, config);

    return new Promise(resolve => {
      this.configureAnOpenDialog(dialogId, configuration, type, config, resolve);
    });
  }

  private configureAnOpenDialog<D extends CelumDialog<C>, C extends CelumDialogConfiguration>(
    dialogId: string,
    configuration: C,
    type: new (...args: any[]) => D,
    config: MatDialogConfig,
    resolve: (args?: any) => any
  ): void {
    let closeAllEmitter: EventEmitter<any>;

    // fixes ExpressionChangedAfterItHasBeenCheckedError with Mat Dialog, see https://github.com/angular/material2/issues/10705
    setTimeout(() => {
      const dialogRef: MatDialogRef<CelumDialog<CelumDialogConfiguration>> = this.dialog.open(type as any, config);

      this.openedDialogs.set(dialogId, {
        openedInDialogOutlet: configuration.openedInDialogOutlet,
        dialogRef
      });

      // make sure to not miss closed dialog!
      dialogRef.afterClosed().subscribe((result: any) => {
        this.onDialogClosed(result, dialogId);
        resolve(result);
      });

      // close all dialogs
      closeAllEmitter = dialogRef.componentInstance.onCloseAll;
      closeAllEmitter && closeAllEmitter.pipe(take(1)).subscribe(() => this.dialog.closeAll());

      dialogRef.componentInstance.configure(configuration);
    }, 0);
  }

  private onDialogClosed(result: any, dialogId: string): void {
    const dialogInfo = this.openedDialogs.get(dialogId);

    if (dialogInfo) {
      dialogInfo.dialogRef.close(result);

      if (dialogInfo.openedInDialogOutlet && NavigationUtil.isDialogOutletActive(this.router)) {
        this.closeOutlet();
      }

      this.openedDialogs.delete(dialogId);
    }
  }

  private closeOutlet(): Promise<boolean> {
    return this.router.navigate([{ outlets: { dialog: null } }], {
      queryParamsHandling: 'preserve'
    });
  }

  private static addTestIdToPanelClasses(dialogId: string, dialogConfig: MatDialogConfig): void {
    const dialogIdClass = `data-test-dialog-id=${dialogId}`;
    const currentClasses = Array.isArray(dialogConfig.panelClass) ? dialogConfig.panelClass : dialogConfig.panelClass ? [dialogConfig.panelClass] : [];
    dialogConfig.panelClass = [...currentClasses, dialogIdClass];
  }
}
