import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MessageConfiguration } from './message-configuration';

@Component({
  selector: 'message',
  templateUrl: './message.html',
  styleUrls: ['./message.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class Message {
  @Input() public configuration: MessageConfiguration;
}
