/**
 * Using Math.random is not as sophisticated as some crypto-techniques, but should be more than sufficient for our use case.
 * Do not rely on uniqueness of this code, it might in very, very rare cases give you a duplicate.
 */
export function generateRandomCharacterString(length: number, includeNumbers = true): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';

  if (includeNumbers) {
    return Array.from({ length }, (_, i) =>
      i % 2 === 0 ? characters[Math.floor(Math.random() * characters.length)] : numbers[Math.floor(Math.random() * numbers.length)]
    ).join('');
  } else {
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  }
}
