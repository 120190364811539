<div class="dialog">
  <ng-content></ng-content>
</div>

<celum-icon
  *ngIf="!hideCloseAllBtn"
  class="dialog_close-all-btn"
  data-test-close-all-dialogs
  [configuration]="closeAllIconConfig"
  (click)="closeAll($event)"></celum-icon>
