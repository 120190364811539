/**
 * Provides utility methods for objects of any type.
 */
import { isArrayEqual, isEqualSimpleObject } from '../../shared/util/compare-util';
import { GraphEntity } from '../model/graph-entity';

/** Checks if the given value is empty. For more details see {@link DataUtil#isEmpty} */
export function isEmpty(value: any): boolean {
  return DataUtil.isEmpty(value);
}

/** Checks if a given value has any content. It is the opposite of {@link isEmpty} */
export function hasValue(value: any): boolean {
  return !DataUtil.isEmpty(value);
}

export class DataUtil {
  /**
   * Checks if a value is empty. TRUE is returned for
   * * NULL
   * * UNDEFINED
   * * Empty Array
   * * Empty Object (works on keys) -> object is empty if there are no keys available
   * * Empty string: zero length as well as value which only contains space chars
   */
  public static isEmpty(value: any): boolean {
    // eslint-disable-next-line eqeqeq
    if (value == null) {
      return true;
    }

    // functions are not empty
    if (typeof value === 'function') {
      return false;
    }

    // strings
    if (typeof value === 'string') {
      return value.trim().length === 0;
    }

    if (value instanceof Map) {
      return value.size === 0;
    }

    if (value instanceof Set) {
      return value.size === 0;
    }

    if (isDate(value)) {
      return false;
    }

    // arrays, strings
    const len = value.length;
    // eslint-disable-next-line eqeqeq
    if (len != null) {
      return len === 0;
    }

    // temporary solution -> will probably not work for everything
    if (typeof value === 'object') {
      const keys = Object.keys(value);
      return keys.length === 0;
    }

    // todo: further checks on objects (hasMembers for {} for example)
    // todo: may we check for an isEmpty() method on objects?

    return false;
  }

  /**
   * Cast an object and recursively all it's child objects to it's specific type.
   * This sets the type properties and makes type methods available
   * @deprecated
   * @param object Object which should be casted
   * @param allTypes All class types which could be nested, if a type is not set, it'll remain an object. Type has to have property _className
   */
  public static cast<T>(object: any, allTypes: (new (...args: any[]) => any)[]): T {
    // eslint-disable-next-line eqeqeq
    if (object == null) {
      return object;
    }

    // Run recursively through all child properties which are of type object
    Object.keys(object)
      .map(key => object[key])
      .filter(innerObject => typeof innerObject === 'object')
      .forEach(innerObject => this.cast(innerObject, allTypes));

    // Get class identifier and try to mach with given parameter
    const classIdentifier = object._class;
    const classType = classIdentifier && allTypes.filter(type => (type as any)._className === classIdentifier)[0];

    if (classType) {
      // If class matches, set right prototype
      object.__proto__ = classType.prototype;
    }

    return object;
  }

  /**
   * Returns the same object but with a changed reference
   */
  public static updateObjectReference<T>(object: T): T {
    const newObject = { ...(object as any) };
    Object.setPrototypeOf(newObject, Object.getPrototypeOf(object));
    return newObject;
  }
}

export function isDate(obj: any | Date): obj is Date {
  // see https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date
  return obj && Object.prototype.toString.call(obj) === '[object Date]';
}

/**
 * Type guard to check if a given entity is from a specific type
 * @param entity which should be tested
 * @param clazz which should be tested against
 */
export function isEntityOfType<T extends GraphEntity>(entity: GraphEntity, clazz: new (...args: any[]) => T): entity is T {
  return entity?.typeKey === (clazz as any).TYPE_KEY;
}

export function createMapFromObject<V>(object: { [key: string]: V }): Map<string, V> {
  return new Map(Object.entries(object));
}

/** Delete from a given Set in an immutable manner - Set will be cloned and only the cloned Set will be updated. */
export function deleteFromSetImmutably<T>(set: Set<T>, itemsToRemove: T[] | Set<T>): Set<T> {
  if (!set) {
    return null;
  }

  if (!itemsToRemove) {
    return new Set(set);
  }

  const cloned = new Set(set);
  [...itemsToRemove].forEach(itemToRemove => cloned.delete(itemToRemove));
  return cloned;
}

/**
 * Deep merge two objects. Copied from https://stackoverflow.com/a/34749873
 * Note: is different from <code>{...target, ...source}</code> as it will apply everything from source to target regardless of the values present in target!
 * Note: if you want to keep the values from your `target`, just switch the positions and use the actual `target` as `source`. In this case, it behaves as
 * regular <code>{...target, ...source}</code> just with deep merge!
 */
export function mergeDeep<T extends { [key: string]: any }, S extends { [key: string]: any }>(target: T, source: S): S & T {
  const output: any = { ...(target ?? source) };

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          output[key] = { ...source[key] };
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else if (source[key] !== undefined) {
        output[key] = source[key];
      }
    });
  }

  return output;
}

export function isObject(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function hasValueChanged(oldValue: any, newValue: any): boolean {
  // eslint-disable-next-line eqeqeq
  if (oldValue == null && newValue == null) {
    return false;
  }

  // eslint-disable-next-line eqeqeq
  if (oldValue == null || newValue == null) {
    return true;
  }

  if (newValue instanceof Date && oldValue instanceof Date) {
    return newValue.getTime() !== oldValue.getTime();
  }

  if (Array.isArray(newValue)) {
    return !isArrayEqual(oldValue, newValue, { respectArrayOrder: true, customEqualityCheck: (prev, next) => !hasValueChanged(prev, next) });
  }

  if (typeof newValue === 'object') {
    return !isEqualSimpleObject(oldValue, newValue);
  }

  // eslint-disable-next-line eqeqeq
  return newValue != oldValue;
}

/***
 * Add a value to an iterable that is referenced through a map.
 * This replaces typical code like this
 *
 * if (!map.has(key)) {
 *   map.set(key, new Set());
 * }
 *
 * // depending on what the value is (simple value or array) one of these lines is necessary
 * map.get(key).add(value);
 * value.forEach(item => map.get(key).add(item)) // in various combinations for arrays/sets.
 *
 * with
 * addToMapWithIterableValue(map, key, value, new Set())
 *
 * @param map which the {@param value} should be added to
 * @param key of the {@param map} which points to the iterable where the {@param value} should be added to
 * @param value which should be added, can be a single value or an array or set
 * @param defaultValue which should be set if the {@param map} does not have a value for the given {@param key} yet
 */
export function addToMapWithIterableValue<K, V, DV extends Set<V> | V[]>(map: Map<K, DV>, key: K, value: V | V[] | Set<V>, defaultValue: DV): void {
  if (!map.has(key)) {
    map.set(key, defaultValue);
  }

  const iterable = map.get(key);
  const newValues = Array.isArray(value) ? value : value instanceof Set ? [...value] : [value];

  if (Array.isArray(iterable)) {
    iterable.push(...newValues);
  } else {
    newValues.forEach(newValue => iterable.add(newValue));
  }
}

interface PickOptions {
  /** if set to true, keys with value "undefined" will not be copied to the returned object */
  skipUndefinedValues?: boolean;
}

/**
 * Create a new object that mimics the given {@see object} but only includes the properties specified by {@see keys}
 * @param object from which only a subset of properties should be returned as a new object
 * @param keys which should be copied to the returned object
 */
export function pick<T extends object, KEYS extends (keyof T)[]>(object: T, keys: KEYS, options?: PickOptions): Pick<T, KEYS[number]> {
  if (!object) {
    return object;
  }

  const partial = {} as Pick<T, KEYS[number]>;
  if (!keys) {
    return partial;
  }

  // eslint-disable-next-line eqeqeq
  keys.filter(key => Object.hasOwn(object, key) && (!options?.skipUndefinedValues || object[key] !== undefined)).forEach(key => (partial[key] = object[key]));
  return partial;
}
