import { inject, Injectable, signal } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';

import { DataUtil } from '@celum/core';
import { AccountUser, Notification, NotificationWithUsers } from '@celum/shared/domain';
import { AccountUserService } from '@celum/shared/shared';

@Injectable({ providedIn: 'root' })
export class NotificationUserService {
  private accountUserService = inject(AccountUserService);
  private cachedUsers = signal<Map<string, AccountUser>>(new Map());

  public attachUserInfo(notifications: Notification[]): Observable<NotificationWithUsers[]> {
    const userIds: string[] = [];
    notifications.forEach(notification => {
      if (notification.data.affectedUserIds) {
        userIds.push(...notification.data.affectedUserIds);
      }
      userIds.push(notification.senderId);
    });

    return this.load([...new Set(userIds)]).pipe(
      map(foundUsersMap => {
        return notifications.map(notification => {
          const affectedUsersMap = new Map(notification.data.affectedUserIds.map(id => [id, foundUsersMap.get(id)]));
          const sender = foundUsersMap.get(notification.senderId);
          return { ...notification, affectedUsersMap, sender };
        });
      })
    );
  }

  private load(ids: string[]): Observable<Map<string, AccountUser>> {
    const idsNotCachedYet = [...new Set(ids)].filter(id => !this.cachedUsers().has(id));

    if (DataUtil.isEmpty(idsNotCachedYet)) {
      return of(new Map(ids.map(id => [id, this.cachedUsers().get(id)])));
    }

    return this.accountUserService.findMembers(idsNotCachedYet).pipe(
      tap(({ entities }) => this.cacheUsers(entities, idsNotCachedYet)),
      map(() => new Map(ids.map(id => [id, this.cachedUsers().get(id)])))
    );
  }

  private cacheUsers(newUsers: AccountUser[], idsNotCachedYet: string[]): void {
    const users = new Map(this.cachedUsers());
    idsNotCachedYet.forEach(id => users.set(id, newUsers.find(user => user.id === id) ?? null));
    this.cachedUsers.set(users);
  }
}
