import { Directive, Host, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * This directive allows to use locally provided icons for the mat-icon component in a lightweight manner.
 * Usage <mat-icon icon="my-icon" [iconSize]="2">/<mat-icon>
 *
 * If you need more advanced features, consider <celum-icon> instead.
 * Note: Icons must be accessible via the /icons path, so make sure to copy them accordingly in your application project.json
 */
@Directive({
  selector: 'mat-icon[icon]',
  standalone: true
})
export class IconDirective implements OnChanges {
  @Input() public icon: string;

  @Input()
  public iconWidthRem: number;

  @Input()
  public iconHeightRem: number;

  @Input()
  public iconSizeRem: number;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Host() private matIcon: MatIcon
  ) {}

  @HostBinding('style.width.rem')
  public get iconStyleWidthRem(): number {
    return this.iconWidthRem ?? this.iconSizeRem;
  }
  @HostBinding('style.height.rem')
  public get iconStyleHeightRem(): number {
    return this.iconHeightRem ?? this.iconSizeRem;
  }

  public ngOnChanges({ icon }: SimpleChanges): void {
    if (icon?.currentValue) {
      const iconUrl = `icons/${this.icon}.svg`;
      const sanitizedIconPath = this.sanitizer.bypassSecurityTrustResourceUrl(iconUrl);
      // add icon to registry only if it is not already there to avoid unnecessary requests
      this.iconRegistry.getNamedSvgIcon(this.icon).subscribe({ error: () => this.iconRegistry.addSvgIcon(this.icon, sanitizedIconPath) });
      this.matIcon.svgIcon = this.icon;
    }
  }
}
