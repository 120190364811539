import { animate, group, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { DARK_COLOR, ICON_COLOR_VARIANT, ICON_TEXT_ALIGN, IconConfiguration, LIGHT_COLOR } from '../icon-configuration';

@Component({
  selector: 'celum-icon',
  templateUrl: './icon.html',
  styleUrls: ['./icon.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('textHidden', [
      state(
        'yes',
        style({
          transform: 'translateX(-100%)',
          opacity: '0',
          width: '0',
          paddingLeft: '0',
          paddingRight: '0'
        })
      ),
      state(
        'no',
        style({
          transform: 'translateX(0)',
          opacity: '1',
          width: '*',
          paddingLeft: '*',
          paddingRight: '*'
        })
      ),
      transition(
        'yes => no',
        group([
          animate(
            '500ms',
            style({
              transform: 'translateX(0)',
              width: '*'
            })
          ),
          animate(
            '250ms 250ms',
            style({
              opacity: '1'
            })
          )
        ])
      ),
      transition(
        'no => yes',
        group([
          animate(
            '250ms 250ms',
            style({
              transform: 'translateX(-100%)',
              width: '0'
            })
          ),
          animate(
            '500ms',
            style({
              opacity: '0'
            })
          )
        ])
      )
    ])
  ],
  standalone: false
})
export class CelumIcon implements OnChanges {
  @Input() public configuration: IconConfiguration = new IconConfiguration(undefined);
  @Input() public clickable = true;

  @HostBinding('class.celum-icon') public hostCls = true;

  public iconStyle: any = {};
  public outlineCss: string;
  public textHidden = 'no';

  private darkColor: string = DARK_COLOR;
  private lightColor: string = LIGHT_COLOR;
  private hoverActive = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  @HostBinding('class.celum-icon--display')
  public get displayCls(): boolean {
    return !this.clickable;
  }

  public ngOnChanges({ configuration }: SimpleChanges): void {
    if (this.configuration && this.configuration.icon) {
      this.iconStyle = this.getIconStyle();
      this.changeDetectorRef.markForCheck();
    }

    // TODO solve with "HostBinding('class') after update to ivy!
    if (configuration && configuration.currentValue) {
      const oldCls = configuration.previousValue ? configuration.previousValue.cssCls : '';
      const newCls = configuration.currentValue.cssCls;

      if (oldCls !== newCls) {
        oldCls && this.el.nativeElement.classList.remove(oldCls);
        newCls && this.el.nativeElement.classList.add(newCls);
      }
    }
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.toggleHover(true);
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.toggleHover(false);
  }

  public hideText(): void {
    this.textHidden = 'yes';
    this.changeDetectorRef.markForCheck();
  }

  public showText(): void {
    this.textHidden = 'no';
    this.changeDetectorRef.markForCheck();
  }

  public toggleHover(hover: boolean): void {
    this.hoverActive = hover;

    // eslint-disable-next-line eqeqeq
    if (this.configuration.hoverColor != null || this.configuration.textHoverColor) {
      this.iconStyle = this.getIconStyle();
      this.changeDetectorRef.markForCheck();
    }
  }

  public getTextAlignment(): any {
    let direction: string;

    switch (this.configuration.textAlign) {
      case ICON_TEXT_ALIGN.left:
        direction = 'row-reverse';
        break;
      case ICON_TEXT_ALIGN.right:
        direction = 'row';
        break;
      case ICON_TEXT_ALIGN.top:
        direction = 'column-reverse';
        break;
      case ICON_TEXT_ALIGN.bottom:
        direction = 'column';
        break;
      default:
        direction = 'row';
        break;
    }
    return {
      'flex-direction': direction
    };
  }

  public getTextColor(): string {
    const baseColor = this.configuration.textColor || (this.configuration.colorVariant === ICON_COLOR_VARIANT.dark ? this.darkColor : this.lightColor);

    if (this.hoverActive) {
      return this.configuration.textHoverColor || baseColor;
    } else {
      return baseColor;
    }
  }

  public getIconStyle(): any {
    const sizeWidth = this.configuration.iconWidth / 10 + 'rem';
    const sizeHeight = this.configuration.iconHeight / 10 + 'rem';

    let color = this.configuration.color || (this.configuration.colorVariant === ICON_COLOR_VARIANT.dark ? this.darkColor : this.lightColor);
    if (this.hoverActive) {
      color = this.configuration.hoverColor || color;
    }

    const boxShadow = this.configuration.outlineColor ? `0 0 0 ${this.configuration.outlineSize}rem ${this.configuration.outlineColor}` : null;
    return {
      width: sizeWidth,
      height: sizeHeight,
      lineHeight: '0',
      color,
      background: this.configuration.backgroundColor,
      boxShadow,
      ...this.configuration.customStyles
    };
  }
}
