import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';
import { Notification, NotificationState, NotificationWithUsers } from '@celum/shared/domain';

import { NotificationUserService } from '../service/notification-user.service';

interface NotificationsPaginatedResponse {
  results: NotificationWithUsers[];
  paginationInformation: {
    elementsFollow: boolean;
    totalElementCount: number;
  };
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private http = inject(HttpClient);
  private notificationUserService = inject(NotificationUserService);

  public markAllAsRead(): Observable<void> {
    return this.http.post<void>(`${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/notifications/mark-as-read`, {});
  }

  public markAsRead(notificationId: string, newState: NotificationState): Observable<NotificationWithUsers> {
    const notificationApiUrl = `${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/notifications/${notificationId}`;

    return this.http
      .patch<Notification>(notificationApiUrl, { state: newState })
      .pipe(
        switchMap(responseNotification => this.notificationUserService.attachUserInfo([responseNotification]).pipe(map(notifications => notifications[0])))
      );
  }

  public fetchNotifications(
    offset: number,
    limit: number,
    showOnlyUnread: boolean
  ): Observable<{ notifications: NotificationWithUsers[]; paginationInfo: { elementsFollow: boolean; totalElementCount: number } }> {
    const requestBody = {
      filter: {
        channel: 'INAPP',
        state: showOnlyUnread ? NotificationState.UNREAD : null
      },
      paging: {
        offset,
        limit
      },
      sorting: {
        field: 'createdOn',
        direction: 'desc'
      }
    };

    return this.http
      .post<NotificationsPaginatedResponse>(`${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/notifications/search`, requestBody)
      .pipe(
        switchMap(results =>
          this.notificationUserService
            .attachUserInfo(results.results)
            .pipe(map(notifications => ({ notifications, paginationInfo: results.paginationInformation })))
        )
      );
  }
}
