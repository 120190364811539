import { Pipe, PipeTransform } from '@angular/core';

import { StringUtil } from '@celum/core';

@Pipe({
  name: 'truncate',
  pure: true,
  standalone: false
})
export class TruncateTextPipe implements PipeTransform {
  public transform(text: any, length = 200): string {
    return typeof text === 'string' ? StringUtil.truncateWithEllipses(text, length) : text;
  }
}
