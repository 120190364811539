import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { DataUtil, ValidatorUtil } from '@celum/core';

export function validEmail(c: AbstractControl): any {
  let validationResult = true;

  if (!DataUtil.isEmpty(c.value)) {
    validationResult = ValidatorUtil.isEmailValid(c.value);
  }

  return validationResult
    ? null
    : {
        validEmail: {
          valid: false,
          messageKey: 'VALIDATION.IS_EMAIL',
          params: { format: 'email@example.com' }
        }
      };
}

@Directive({
  selector: '[validEmail][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: validEmail,
      multi: true
    }
  ],
  standalone: false
})
export class EmailValidator {}
