import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CelumEmptyPageModule, CelumLoadingMaskModule, EmptyPage } from '@celum/common-components';
import { HasOverflowDirective, InViewportDirective } from '@celum/ng2base';
import { NotificationState } from '@celum/shared/domain';

import { NotificationListService } from './notification-list.service';
import { NotificationClientConfig } from '../model/notification-client-config.model';
import { NotificationListHeaderComponent } from '../notification-list-header/notification-list-header.component';
import { NotificationsCenterStore } from '../notifications-center/notifications-center.service';
import { NotificationItemComponent } from '../notifications-item/notification-item.component';

@Component({
  selector: 'clm-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  imports: [
    CommonModule,
    MatProgressSpinner,
    MatSlideToggleModule,
    CelumEmptyPageModule,
    CelumLoadingMaskModule,
    HasOverflowDirective,
    InViewportDirective,
    NotificationListHeaderComponent,
    NotificationItemComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationListService]
})
export class NotificationListComponent implements OnInit {
  public notificationConfigs = input<NotificationClientConfig>();
  public notificationCount = input<number>();
  public notificationRefreshCount = input<number>();

  protected notificationListService = inject(NotificationListService);
  protected notificationsCenterStore = inject(NotificationsCenterStore);
  protected vm = toSignal(this.notificationListService.vm$);

  protected emptyPage = computed(() => {
    const { error, showOnlyUnread } = this.vm();
    const icon = error ? 'notification-load-error' : showOnlyUnread ? 'no-unread-notifications' : 'no-notification';
    const partialMessageKey = error ? 'ERROR_FALLBACK_TEXT' : showOnlyUnread ? 'EMPTY_UNREAD_FALLBACK_TEXT' : 'EMPTY_FALLBACK_TEXT';
    const messageKeyKey = `SHARED.SYSTEM_BAR.NOTIFICATIONS.${partialMessageKey}`;
    return EmptyPage.noActionConfig(icon, icon, messageKeyKey, 'normal', 148);
  });

  public ngOnInit(): void {
    this.notificationListService.init(this.notificationConfigs());
  }

  public onNotificationStateChange(event: { id: string; newState: NotificationState }): void {
    this.notificationListService.updateNotificationState({ notificationId: event.id, newState: event.newState });
  }

  protected onMarkAllAsRead(): void {
    this.notificationListService.markAllAsRead();
  }

  protected onShowOnlyUnread(showOnlyUnread: boolean): void {
    this.notificationListService.updateShowOnlyUnread(showOnlyUnread);
  }

  protected onRefresh(): void {
    this.notificationListService.load();
    this.notificationsCenterStore.storeCompareCount();
  }
}
