import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { registerExtensionType } from '@celum/core';
import { TranslationModule } from '@celum/ng2base';

import { AlertDialog } from './components/alert-dialog/alert-dialog';
import { Dialog } from './components/base-dialog/dialog';
import { DialogContent } from './components/base-dialog/dialog-content';
import { DialogError } from './components/base-dialog/dialog-error';
import { DialogFooter } from './components/base-dialog/dialog-footer';
import { DialogHeader } from './components/base-dialog/dialog-header';
import { InfoDialog } from './components/info-dialog/info-dialog';
import { MessageDialog } from './components/message-dialog/message-dialog';
import { SpinnerDialog } from './components/spinner-dialog/spinner-dialog';
import { StandardDialog } from './components/standard-dialog/standard-dialog';
import { DialogExtension } from './dialog-extension';
import { CelumButtonModule } from '../button/button.module';
import { CelumIconModule } from '../icon/icon.module';
import { CelumMessageModule } from '../message/message.module';
import { CelumSpinnerModule } from '../spinner/spinner.module';

@NgModule({
  imports: [
    // basic angular modules and 3rd party modules
    CommonModule,
    RouterModule,
    TranslateModule,
    // needed material modules
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    // @celum modules
    TranslationModule,
    // our own modules
    CelumIconModule,
    CelumButtonModule,
    CelumSpinnerModule,
    CelumMessageModule
  ],
  declarations: [
    Dialog,
    AlertDialog,
    InfoDialog,
    StandardDialog,
    DialogFooter,
    DialogHeader,
    DialogFooter,
    DialogError,
    DialogContent,
    SpinnerDialog,
    MessageDialog
  ],
  exports: [
    TranslateModule,
    Dialog,
    AlertDialog,
    InfoDialog,
    SpinnerDialog,
    StandardDialog,
    DialogFooter,
    DialogHeader,
    DialogFooter,
    DialogError,
    MessageDialog,
    DialogContent,
    CelumIconModule,
    CelumButtonModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class CelumDialogModule {
  constructor() {
    registerExtensionType(DialogExtension, DialogExtension.type);
  }
}
