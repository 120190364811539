import { BlockScrollStrategy, Overlay, RepositionScrollStrategy } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { CelumIconModule, CelumInputsModule } from '@celum/common-components';

import { CelumAutocompleteArea } from './components/autocomplete-area';

// This fix autocomplete floating in IOS
export function MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY(overlay: Overlay, platform: Platform): () => BlockScrollStrategy | RepositionScrollStrategy {
  const FACTORY = () => (platform.IOS ? overlay.scrollStrategies.block() : overlay.scrollStrategies.reposition());
  return FACTORY;
}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, CelumIconModule, CelumInputsModule],
  declarations: [CelumAutocompleteArea],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      deps: [Overlay, Platform],
      useFactory: MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY
    }
  ],
  exports: [CelumAutocompleteArea, MatFormFieldModule, MatInputModule, CelumIconModule]
})
export class CelumAutocompleteAreaModule {}
