import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[celum-square-button]',
  standalone: false
})
export class CelumSquareButtonDirective {
  @HostBinding('class.celum-square-btn--active')
  @Input()
  public isActive = false;

  @HostBinding('class.celum-square-btn--disabled')
  @Input()
  public isDisabled = false;

  @HostBinding('class.celum-square-btn--light-ripple')
  @Input()
  public isRippleLight = false;

  @HostBinding('class.celum-square-btn') public hostClass = true;
}
