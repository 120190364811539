import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

import { SnackbarComponent } from '../../model/snackbar-component';
import { ProgressSnackbarConfiguration } from '../snackbar/snackbar-configurations';

@Component({
  selector: 'progress-snackbar',
  templateUrl: './progress-snackbar.html',
  styleUrls: ['./progress-snackbar.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ProgressSnackbar implements SnackbarComponent<ProgressSnackbarConfiguration> {
  @Output() public readonly dismiss = new EventEmitter<void>();

  public config: ProgressSnackbarConfiguration;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public get isDeterminate(): boolean {
    return this.config && this.config.mode === 'determinate';
  }

  public configure(config: ProgressSnackbarConfiguration): void {
    this.config = config;
    this.changeDetector.markForCheck();
  }
}
