import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { DataContext, DataUtil, ICON_SIZE, OperationDefinition } from '@celum/core';

@Component({
  selector: 'celum-magic-button',
  templateUrl: './magic-button.html',
  styleUrls: ['./magic-button.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class MagicButton {
  @Input() public items: OperationDefinition[];
  @Input() public currentDataContext: DataContext;
  @Input() public buttonColor: SafeStyle;
  @Input() public disabledColor: SafeStyle = ColorConstants.BLUE_GRAY_200;
  @Input() public disableButtonIfNoOperations = false;

  @Output() public readonly openPage = new EventEmitter<void>();

  public buttonIcon = IconConfiguration.xLarge('option-m', '', 40).withColor('#ffffff');

  public get operations(): OperationDefinition[] {
    return this.items || [];
  }

  public get disabled(): boolean {
    return this.disableButtonIfNoOperations && DataUtil.isEmpty(this.items);
  }

  public operationClicked({ operation }: OperationDefinition): void {
    operation.execute(this.currentDataContext);
  }

  public iconConfig({ operation }: OperationDefinition): IconConfiguration {
    return IconConfiguration.large(operation.getIcon(ICON_SIZE.l), '').withColor('#ffffff');
  }

  public onOpenClick(): void {
    if (this.disableButtonIfNoOperations && DataUtil.isEmpty(this.items)) {
      return;
    }

    this.openPage.emit();
  }
}
