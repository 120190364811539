<div *ngIf="configuration && configuration.icon" class="icon-flex" [ngStyle]="getTextAlignment()" title="{{ configuration.title | translate }}">
  <icon-content [iconStyle]="iconStyle" [configuration]="configuration"></icon-content>

  <span
    [@textHidden]="textHidden"
    class="icon-text"
    *ngIf="!!configuration.text"
    [style.fontSize.rem]="configuration.textSize / 10"
    [style.lineHeight.rem]="configuration.textSize / 10"
    [style.color]="getTextColor()"
    [style.textTransform]="configuration.textTransform">
    {{ configuration.text | translate }}
  </span>
  <span
    [@textHidden]="textHidden"
    class="icon-text"
    *ngIf="!!configuration.safeHtml"
    [style.fontSize.rem]="configuration.textSize / 10"
    [style.lineHeight.rem]="configuration.textSize / 10"
    [style.color]="getTextColor()"
    [style.textTransform]="configuration.textTransform"
    [innerHTML]="configuration.safeHtml"></span>
</div>
