import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityActions, InternalEntityActions } from './entity-actions';
import { deviate } from '../entity/entity-registry';
import { EntityResolverService } from '../entity-resolver/entity-resolver.service';
import { selectEntityState } from '../entity-store/entity-state';

@Injectable()
export class EntityEffects {
  /** Effect that transforms an upsert action into an upsertMany which is handled by the {@see upsertEntities$} effect. */
  public upsertEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.upsert),
      map(action => InternalEntityActions.upsertMany({ entities: [action.entity], deviations: deviate(action.entity.typeKey, action.deviation) }))
    )
  );

  /** Effect that calls the EntityResolver and puts the given entities into the store afterwards. */
  public upsertEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.upsertMany),
      concatLatestFrom(() => this.store.select(selectEntityState)),
      tap(([action, { entitiesById }]) => this.entityResolver.resolve(action.entities, entitiesById, action.deviations)),
      map(([action]) => InternalEntityActions._UpsertMany(action))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private entityResolver: EntityResolverService
  ) {}
}
