export abstract class CelumMap<KEY, VALUE> {
  private map: Map<string, VALUE> = new Map<string, VALUE>();

  public has(key: KEY): boolean {
    return this.map.has(this.translateKey(key));
  }

  public set(key: KEY, value: VALUE): void {
    this.map.set(this.translateKey(key), value);
  }

  public get(key: KEY): VALUE {
    return this.map.get(this.translateKey(key));
  }

  public values(): Iterator<VALUE> {
    return this.map.values();
  }

  public size(): number {
    return this.map.size;
  }

  public abstract translateKey(key: KEY): string;
}
