import { Observable } from 'rxjs';

import { LocalizedValue } from '@celum/core';

import { ColorConstants } from '../../../base/color-constants';
import { IconConfiguration } from '../../../icon/icon-configuration';
import { CelumDialogConfiguration } from '../../model/celum-dialog-configuration';
import { buttonType } from '../base-dialog/dialog-footer';

type OkButtonActionConfigOverrides = Partial<
  Pick<MessageDialogConfiguration, 'buttons' | 'disableOnCancelClosing' | 'loading' | 'hideCloseAllBtn' | 'disableBackdropClose'>
>;

export class MessageDialogConfiguration extends CelumDialogConfiguration {
  public messageParams: object;
  public titleParams: object;
  public okButtonText = 'COMMON.OK';
  public cancelButtonText = 'COMMON.CANCEL';
  public color: string;
  public icon: IconConfiguration;
  public okBtnFunction: () => void | Observable<void> | Promise<void>;
  public cancelBtnFunction: () => void;
  public disableOnCancelClosing = false;
  public disableOnConfirmClosing = false;
  public disableBackdropClose = false;
  public loading = false;
  public okButtonActionConfigOverrides: OkButtonActionConfigOverrides;

  private constructor(
    public messages: (string | LocalizedValue)[],
    public title: string | LocalizedValue,
    public dialogType: 'error' | 'warning' | 'info' = 'info',
    public buttons: buttonType[] = ['ok', 'cancel'],
    public hideCloseAllBtn = false
  ) {
    super('main');

    this.color = dialogType === 'warning' ? ColorConstants.WARNING : dialogType === 'error' ? ColorConstants.ERROR : ColorConstants.PRIMARY;
  }

  /**
   * Define the messages that should be visible. Overwrites all previously set messages
   * @param messages that should be visible
   */
  public withMessages(messages: (string | LocalizedValue)[]): MessageDialogConfiguration {
    this.messages = messages;
    return this;
  }

  /**
   * Define your custom icon to show. Color will still be applied!
   */
  public withIcon(icon: IconConfiguration): MessageDialogConfiguration {
    this.icon = icon;
    return this;
  }

  /**
   * Define the text for the 'ok' button (if shown). Default is 'COMMON.OK'.
   */
  public withOkButtonText(text: string): MessageDialogConfiguration {
    this.okButtonText = text || this.okButtonText;
    return this;
  }

  public withDisableBackdropClose(disableBackdropClose = false): MessageDialogConfiguration {
    this.disableBackdropClose = disableBackdropClose;
    return this;
  }

  /**
   * Define a function that is immediately called when the OK button is clicked. This is useful if you want to execute an action (like upload) that has to be
   * executed directly in reaction to a user interaction.
   */
  public withOkButtonAction(
    fn: (() => void) | (() => Observable<void>) | (() => Promise<void>),
    okButtonActionConfigOverrides?: OkButtonActionConfigOverrides
  ): MessageDialogConfiguration {
    this.okBtnFunction = fn;
    this.okButtonActionConfigOverrides = okButtonActionConfigOverrides;
    return this;
  }

  /**
   * Define a function that is immediately called when the Cancel button is clicked.
   */
  public withCancelButtonAction(fn: () => void): MessageDialogConfiguration {
    this.cancelBtnFunction = fn;
    return this;
  }

  /**
   * Define the text for the 'cancel' (or 'close') button (if one is shown). Default is 'COMMON.CANCEL'.
   */
  public withCancelButtonText(text: string): MessageDialogConfiguration {
    this.cancelButtonText = text || this.cancelButtonText;
    return this;
  }

  /**
   * Define message params to use for translating the given message.
   */
  public withMessageParams(params: object): MessageDialogConfiguration {
    this.messageParams = params;
    return this;
  }

  /**
   * Define message params to use for translating the given title.
   */
  public withTitleParams(params: object): MessageDialogConfiguration {
    this.titleParams = params;
    return this;
  }

  /**
   * Define which buttons to show. Can be up to 2.
   * Note: You cannot use 'cancel' and 'close' in combination!
   */
  public withButtons(buttons: buttonType[]): MessageDialogConfiguration {
    this.buttons = buttons;
    return this;
  }

  /**
   * Define a color to use for the dialog. Will be applied to the buttons, the icon and the title text of the dialog. Default color defined by the "type" of
   * the dialog. When using this to specify a custom color, this will overwrite the default one.
   */
  public withColor(color: string): MessageDialogConfiguration {
    this.color = color;
    return this;
  }

  public withHideCloseButton(): MessageDialogConfiguration {
    this.hideCloseAllBtn = true;
    return this;
  }

  public withLoading(): MessageDialogConfiguration {
    this.loading = true;
    return this;
  }

  /** Define that the dialog should not automatically close on cancel */
  public withDisableOnCancelClosing(): MessageDialogConfiguration {
    this.disableOnCancelClosing = true;
    return this;
  }

  /** Define that the dialog should not automatically close on confirm */
  public withDisableOnConfirmClosing(disableOnCancelClosing = true): MessageDialogConfiguration {
    this.disableOnConfirmClosing = disableOnCancelClosing;
    return this;
  }

  /**
   * Create the configuration for the dialog. Define at least the message to show and the title. If nothing else is specified, the dialog has the look and feel
   * of an 'info' dialog (info icon, blue color, ok and cancel buttons).
   */
  public static createConfig(
    message: string | LocalizedValue,
    title: string | LocalizedValue,
    dialogType?: 'info' | 'warning' | 'error'
  ): MessageDialogConfiguration {
    return new MessageDialogConfiguration([message], title, dialogType ?? 'info');
  }

  /**
   * Create the configuration for the dialog with type 'warning'. Define at least the message to show and the title. If nothing else is specified, the dialog
   * will have the color definition for 'warning' (orange) used for the icon, button color and will show only the 'close' buttons.
   */
  public static createWarnConfig(message: string | LocalizedValue, title: string | LocalizedValue): MessageDialogConfiguration {
    return new MessageDialogConfiguration([message], title, 'warning', ['close']);
  }

  /**
   * Create the configuration for a message dialog with type 'error'. Define at least the message to show and the title. If nothing else is specified, the
   * dialog will have the color definition for 'error' (red) used for info icon, button color and will show only the 'close' buttons.
   */
  public static createErrorConfig(message: string | LocalizedValue, title: string | LocalizedValue): MessageDialogConfiguration {
    return new MessageDialogConfiguration([message], title, 'error', ['close']);
  }
}
