import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'celum-dialog-header',
  templateUrl: './dialog-header.html',
  styleUrls: ['./dialog-header.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DialogHeader {
  @HostBinding('class.dialog-header') public cls = true;

  @Input() public headerSubtitle: string;
  @Input() public headerSubtitleParams: { [key: string]: string };
}
