import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumFormFieldDirective } from './celum-form-field-directive';
import { CelumInlineFormFieldButtons } from './celum-inline-form-field-buttons/celum-inline-form-field-buttons';
import { CelumInlineFormFieldDirective } from './celum-inline-form-field-directive';
import { CelumButtonModule } from '../button';
import { CelumIconModule } from '../icon';

@NgModule({
  imports: [CommonModule, MatInputModule, MatButtonModule, MatTooltipModule, CelumIconModule, CelumButtonModule],
  declarations: [CelumFormFieldDirective, CelumInlineFormFieldDirective, CelumInlineFormFieldButtons],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    }
  ],
  exports: [CelumFormFieldDirective, CelumInlineFormFieldDirective, MatInputModule, CelumIconModule, CelumInlineFormFieldButtons]
})
export class CelumInputsModule {}
