/**
 * Stores scroll position after opening overlays.
 */
export class ScrollPositionHelperService {
  private scroll = 0;

  public handleScrollPosition(): void {
    document.addEventListener(
      'scroll',
      () => {
        const html = document.querySelector('html');

        if (window.scrollY > 0 || html.scrollTop > 0) {
          this.scroll = window.scrollY || html.scrollTop;
        } else if (this.scroll > 10) {
          window.scrollTo(0, this.scroll);
          html.scrollTop = this.scroll;
        }
      },
      { passive: true }
    );
  }
}
