import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, throwError } from 'rxjs';

import { toObservable } from '@celum/core';

import { MessageDialogConfiguration } from './message-dialog-config';
import { MessageConfiguration } from '../../../message/message-configuration';
import { CelumDialog } from '../../model/celum-dialog';

@Component({
  selector: 'celum-message-dialog',
  templateUrl: './message-dialog.html',
  styleUrls: ['./message-dialog.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MessageDialog implements CelumDialog<MessageDialogConfiguration>, OnInit {
  @Input() public color: string;
  @Input() public config: Partial<MessageDialogConfiguration>;
  @Input() public valid = true;

  public messageConfig: MessageConfiguration;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: MessageDialogConfiguration,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.data) {
      this.configure(this.data);
    }
  }

  public configure(config: MessageDialogConfiguration): void {
    this.updateConfiguration(config);
  }

  public ngOnInit(): void {
    this.updateConfiguration(this.config);
  }

  public onCancelClick(): void {
    this.config.cancelBtnFunction?.();
  }

  public onConfirmClick(): void {
    if (this.config.okBtnFunction) {
      const currentConfig = { ...this.config };
      this.config.okButtonActionConfigOverrides && this.updateConfiguration({ ...this.config, ...this.config.okButtonActionConfigOverrides });
      toObservable(this.config.okBtnFunction())
        .pipe(
          catchError(error => {
            this.updateConfiguration(currentConfig);
            return throwError(() => error);
          })
        )
        .subscribe();
    }
  }

  private updateConfiguration(config: Partial<MessageDialogConfiguration>): void {
    this.config = config;
    this.messageConfig = MessageDialog.evaluateMessageConfig(config);
    this.setColor();
    this.changeDetectorRef.markForCheck();
  }

  private setColor(): void {
    // if the config specifically defines a color, overwrite it!
    if (this.config.color) {
      this.color = this.config.color;
    }
  }

  private static evaluateMessageConfig(config: Partial<MessageDialogConfiguration>): MessageConfiguration {
    let msgConfig =
      config.dialogType === 'info'
        ? MessageConfiguration.info(config.title)
        : config.dialogType === 'warning'
          ? MessageConfiguration.warn(config.title)
          : MessageConfiguration.error(config.title);

    if (config.icon) {
      msgConfig = msgConfig.withIcon(config.icon);
    }

    if (config.color) {
      msgConfig = msgConfig.withColor(config.color);
    }

    msgConfig = msgConfig.withMessages(config.messages);
    msgConfig.withMessageParams(config.messageParams);
    msgConfig.withTitleParams(config.titleParams);
    return msgConfig;
  }
}
