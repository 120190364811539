import { Pipe, PipeTransform } from '@angular/core';

type Rest<T extends any[]> = ((...t: T) => any) extends (_: any, ...tail: infer TT) => any ? TT : [];

@Pipe({
  name: 'wrapFn',
  pure: true
})
export class FnWrapperPipe implements PipeTransform {
  public transform<V, FN extends (input: V, ...args: any[]) => any, ARGS extends Rest<Parameters<FN>>>(value: V, fn: FN, ...args: ARGS): ReturnType<FN> {
    if (!fn) {
      return null;
    }

    return fn(value, ...args);
  }
}
