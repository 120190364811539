import ResizeObserver from 'resize-observer-polyfill';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export class ResizeObserverHelper {
  /* save which callback should be triggered if the given element got an resize entry */
  private static callbackPerElement = new WeakMap<Element, (entry: ResizeObserverEntry) => void>();
  private static resizeObserver: ResizeObserver;

  private resizeEvents$ = new Subject<ResizeObserverEntry>();

  constructor(
    private element: Element,
    private debounceTimeInMs: number,
    private stop$: Observable<void>
  ) {}

  public startListen(): Observable<ResizeObserverEntry> {
    // only create resize observer once when needed first/
    if (!ResizeObserverHelper.resizeObserver) {
      ResizeObserverHelper.resizeObserver = ResizeObserverHelper.createResizeObserver(entries => {
        // only emit the latest entry for each target element
        const entryByTarget = new Map<Element, ResizeObserverEntry>();
        entries.forEach(entry => entryByTarget.set(entry.target, entry));
        entryByTarget.forEach((entry, target) => ResizeObserverHelper.callbackPerElement.get(target)?.(entry));
      });
    }

    ResizeObserverHelper.callbackPerElement.set(this.element, entry => this.resizeEvents$.next(entry));
    ResizeObserverHelper.resizeObserver.observe(this.element);

    return this.resizeEvents$.pipe(debounceTime(this.debounceTimeInMs), takeUntil(this.stop$));
  }

  public cleanup(): void {
    ResizeObserverHelper.resizeObserver.unobserve(this.element);
    ResizeObserverHelper.callbackPerElement.delete(this.element);
  }

  /* separate method to make this mockable in tests */
  private static createResizeObserver(callback: ResizeObserverCallback): ResizeObserver {
    return new ResizeObserver(callback);
  }
}
