import { Registry } from '../registry';

/**
 * @deprecated
 */
export function CelumCustomizationType(name: string): ClassDecorator {
  return (target: any) => {
    Registry.registerCustomizerType(target, name);
    return target;
  };
}

/**
 * Register a customizer type.
 * @param target      the customizer type class
 * @param identifier  the type identifier for this customizer type
 */
export function registerCustomizationType(target: any, identifier: string): void {
  Registry.registerCustomizerType(target, identifier);
}
