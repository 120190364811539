import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PreviewItem } from './preview-item';

@NgModule({
  imports: [CommonModule],
  declarations: [PreviewItem],
  exports: [PreviewItem]
})
export class CelumPreviewItemModule {}
