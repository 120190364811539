<mat-select
  class="sorter_dropdown"
  hideSingleSelectionIndicator
  [class.sorter_dropdown--single-selection]="fields.length === 1"
  [panelClass]="['celum-select-panel', 'celum-sorter-panel']"
  [placeholder]="'SORTER.ORDER_BY' | translate"
  [value]="selected?.id"
  (selectionChange)="handleSelection($event.source.value)">
  <mat-select-trigger>
    <span class="sorter_dropdown-selected-value" spaceAwareTooltip [matTooltip]="selected?.label | language">{{ selected?.label | language }}</span>
  </mat-select-trigger>
  <mat-option
    *ngFor="let sortProperty of fields | sortLocalized: getSortOptionName"
    [class.sorter_option--disabled]="sortProperty.disabled"
    [attr.data-sort-option]="sortProperty?.id"
    [value]="sortProperty.id">
    <div class="sorter_dropdown-text-wrap">
      <div class="sorter_dropdown-additional-text">{{ 'SORTER.ORDER_BY' | translate }}</div>
      <div class="sorter_dropdown-text">{{ sortProperty.label | language }}</div>
    </div>
  </mat-option>
</mat-select>

<celum-icon
  class="sorter_direction"
  [class.sorter_direction--ascending]="mode === SortDirection.ASC"
  [class.sorter_direction--disabled]="disableDirection"
  [attr.data-sort-direction]="mode"
  [configuration]="iconConfig"
  [matTooltip]="'SORTER.SORT_ORDER' | translate"
  (click)="toggleSortDirection()"></celum-icon>
