import { ChangeDetectorRef, inject, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

import { DateUtil } from '../util/date/date-util';
import { DateService } from '../util/date.service';

/**
 * Format the given date to a string according to the current locale.
 *
 * Note: if the date at hand should be considered as UTC date, please use the 'formatDateValue' ({@link FormatDateValuePipe}) instead.
 */
@Pipe({
  name: 'formatDate',
  pure: true,
  standalone: false
})
export class FormatDatePipe implements PipeTransform {
  protected translate = inject(TranslateService);

  private changeDetector = inject(ChangeDetectorRef);

  constructor() {
    this.translate.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => this.changeDetector.markForCheck());
  }

  /**
   * Transforms a date to a formatted string.
   * @param date          The date to format.
   * @param currentLocale The locale to use for formatting.
   * @param showTime      Whether to include the time in the formatted string
   * @param format        The format to use for formatting.
   */
  public transform(date: string | number | Date, currentLocale?: string, showTime?: boolean, format = DateUtil.FORMATS.DATE.NUMERIC): string {
    if (!date) {
      return '';
    }

    return DateService.formatDateForGivenLocale(
      typeof date === 'number' ? date : (typeof date === 'string' ? new Date(date) : date).getTime(),
      currentLocale,
      format,
      showTime
    );
  }
}
