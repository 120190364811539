import { EntityTranslator } from './entity-translator';

export class TranslatorRegistry {
  private static translators: Map<string, EntityTranslator<any>> = new Map<string, EntityTranslator<any>>();

  public static register(translator: EntityTranslator<any>): void {
    // eslint-disable-next-line eqeqeq
    if (translator == null) {
      throw new Error(`TranslatorRegistry: Invalid value passed: null`);
    }

    const entityName = translator.translatesToEntityWithName();

    // eslint-disable-next-line eqeqeq
    if (entityName == null) {
      throw new Error(`TranslatorRegistry: Invalid translator passed. Could not determine class name for ${translator}`);
    }

    if (this.isString(entityName)) {
      this.registerInternally(entityName, translator);
    } else {
      entityName.forEach(name => {
        this.registerInternally(name, translator);
      });
    }
  }

  public static getTranslatorForEntity<ENTITY extends { _class: string }>(entityType: ENTITY): EntityTranslator<ENTITY> {
    // eslint-disable-next-line eqeqeq
    if (entityType == null) {
      throw new Error(`TranslatorRegistry: Invalid entityType passed: null`);
    }

    if (!entityType._class) {
      throw new Error(`TranslatorRegistry: Invalid entity passed. Could not determine class name for ${entityType}`);
    }

    return this.getTranslator(entityType._class);
  }

  public static getTranslator(entityName: string): EntityTranslator<any> {
    // eslint-disable-next-line eqeqeq
    if (entityName == null) {
      throw new Error(`TranslatorRegistry: Invalid method call. No name passed.`);
    }

    if (this.translators.has(entityName)) {
      return this.translators.get(entityName);
    } else {
      return null;
    }
  }

  public static isString(value: string | string[]): value is string {
    return typeof value === 'string';
  }

  private static registerInternally(name: string, translator: EntityTranslator<any>): void {
    if (!this.translators.has(name)) {
      this.translators.set(name, translator);
    } else {
      console.warn(`TranslatorRegistry: There is already a translator registered for class '${name}', ignore this second pass.`);
    }
  }
}
