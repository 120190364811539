import { ChangeDetectionStrategy, Component, HostBinding, input, ViewEncapsulation } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';
import { LocalizedValue } from '@celum/core';
import { LanguagePipe } from '@celum/ng2base';

/**
 * Basic context menu item providing the default styling of a context menu element.
 */
@Component({
  imports: [MatTooltip, MatMenuModule, CelumIconModule, LanguagePipe],
  selector: 'icon-menu-item',
  templateUrl: './icon-menu-item.html',
  styleUrls: ['./icon-menu-item.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  exportAs: 'iconMenuItem'
})
export class IconMenuItem {
  public disabled = input<boolean>();
  public icon = input<IconConfiguration>();
  public text = input<string | LocalizedValue>();
  public tooltipText = input<string | LocalizedValue>();

  @HostBinding('class')
  public cssClass = 'icon-menu-item';
}
