import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { DomHelper } from '@celum/ng2base';

@Component({
  selector: 'celum-dialog-content',
  templateUrl: './dialog-content.html',
  styleUrls: ['./dialog-content.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DialogContent implements OnInit, OnDestroy {
  @HostBinding('class.dialog-content') public cls = true;

  public ngOnInit(): void {
    DomHelper.disableDocumentScroll();
  }

  public ngOnDestroy(): void {
    DomHelper.enableDocumentScroll();
  }
}
