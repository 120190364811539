import { LocalizedValue, LocalizedValueMap } from '@celum/core';

export enum ColumnTypeId {
  DEFAULT = 'default',
  CUSTOM = 'custom',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'dateTime'
}

export class ColumnType {
  public static DEFAULT: ColumnType = new ColumnType(ColumnTypeId.DEFAULT, 200);
  public static DATE: ColumnType = new ColumnType(ColumnTypeId.DATE, 100);
  public static DATETIME: ColumnType = new ColumnType(ColumnTypeId.DATETIME, 200);
  public static BOOLEAN: ColumnType = new ColumnType(ColumnTypeId.BOOLEAN, 50);
  public static NUMBER: ColumnType = new ColumnType(ColumnTypeId.NUMBER, 50);

  /**
   * @param typeId    the type of the column
   * @param width     the width of the column, or the default width of the column if {@link resizable} is true
   * @param resizable whether the column should be resizable
   */
  constructor(
    public typeId: ColumnTypeId,
    public width: number,
    public resizable = true
  ) {}
}

/**
 * Define the appearance and possible actions of a column.
 *
 * Note: if you want to show the same "field" in multiple languages, you need to provide only one {@link ColumnDefinition}, simply define all possible
 * languages in the {@link availableLocales} property!
 */
export interface ColumnDefinition {
  /**
   * the unique id of the column
   */
  id: string;
  /**
   * the name of the column displayed in the header (can be simple string, message key or a {@link LocalizedValue})
   */
  label: string | LocalizedValue;
  /**
   * the type of the column which defines the appearance and width (including resizablity)
   */
  columnType: ColumnType;
  /**
   * whether it should be possible to sort the tables content by the content of this column
   */
  sortable: boolean;
  /**
   * whether the given label should be shown in the header
   */
  showHeader: boolean;
  /**
   * optionally define the locale variants available for this column. This means that this column can be displayed multiple times in the table for each
   * available locale in this map.
   */
  availableLocales?: LocalizedValueMap;
  /**
   * optionally define some classes which will be added to the column's cells
   */
  attachedClasses?: string[];
  /**
   * optionally define a text that is shown as tooltip for the column header
   */
  tooltip?: string;
}

export interface ActiveColumn {
  id: string;
  locale?: string;
  width?: number;
}

/**
 * Decorates a {@link ColumnDefinition} with an optional {@link locale} value. This value is only used for definitions that have
 * {@link ColumnDefinition.availableLocales availableLocales} in all other cases it will be always undefined.
 */
export interface ActiveColumnDefinition extends ColumnDefinition {
  /**
   * The locale value that should be used for this column in the table.
   */
  locale?: string;
}
