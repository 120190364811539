/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector, createSelectorFactory, MemoizedSelector, resultMemoize, Store } from '@ngrx/store';
import { Observable, OperatorFunction, switchMap } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

import { isArrayEqual, isTruthy } from '@celum/core';

import { Entity, EntityId } from '../entity/entity';

export interface EntityState {
  entitiesById: Record<string, Entity>;
  entitiesByType: Record<string, Set<string>>;
}

export const CELUM_ENTITY_SLICE_NAME = 'celum-entity';
export const selectEntityState = createFeatureSelector<EntityState>(CELUM_ENTITY_SLICE_NAME);

/** Select the entity with the given {@param id} from the store */
export const selectEntityById = <T extends Entity>(id: EntityId) => createSelector(selectEntityState, state => state.entitiesById[id] as T);

/** Select the first entity by {@param key} {@param value} pairs with a specific {@param typeKey} */
export const selectEntityByKeyValue = <T extends Entity>(key: keyof T, value: any, typeKey: string) =>
  createSelector(selectEntityState, state => {
    const entityIds = state.entitiesByType[typeKey] || new Set();
    return mapIdsToEntities<T>([...entityIds], state).find(entity => entity[key] === value);
  });

const simpleArrayMemoize = (fn: () => any) => resultMemoize(fn, isArrayEqual);

/** Select all entities with the given {@param ids} from the store. Non existent entities will not appear in the resulting array */
export const selectEntitiesById = <T extends Entity>(ids: EntityId[]) =>
  createSelectorFactory<any, T[]>(simpleArrayMemoize)(selectEntityState, (state: EntityState) => mapIdsToEntities(ids, state));

/** Select all entities of the given {@param typeKey} from the store. Non existent entities will not appear in the resulting array */
export const selectEntitiesByType = <T extends Entity>(typeKey: string) =>
  createSelectorFactory<any, T[]>(simpleArrayMemoize)(selectEntityState, (state: EntityState) => {
    const entityIds = state.entitiesByType[typeKey] || new Set();
    return mapIdsToEntities<T>([...entityIds], state);
  });

/** Helper to select an entity from the store based on another selector providing its id */
export const selectEntityByIdSelector = <T extends Entity>(selector: MemoizedSelector<object, EntityId>) => {
  return createSelector(selector, selectEntityState, (id, state) => state.entitiesById[id] as T);
};

/** Helper to select entities from the store based on another selector providing the ids. Non existent entities will not appear in the resulting array  */
export const selectEntitiesByIdSelector = <T extends Entity>(selector: MemoizedSelector<object, EntityId[]>) => {
  return createSelectorFactory<any, T[]>(simpleArrayMemoize)(selector, selectEntityState, mapIdsToEntities);
};

/** Helper rxjs operator to select an entity from the store based on an observable providing the id.
 *
 * @param store   the store to listen to
 * @param config  optionally provide configuration options (e.g. `once` for taking just the first emitted entity)
 */
export function toEntity<T extends Entity>(store: Store, config?: Partial<{ once: boolean }>): OperatorFunction<string, T> {
  return (id$: Observable<string>) => {
    const obs$ = id$.pipe(
      distinctUntilChanged(),
      switchMap(id => store.select(selectEntityById<T>(id)))
    );

    return config?.once ? obs$.pipe(isTruthy(), take(1)) : obs$;
  };
}

/** Helper rxjs operator to select entities from the store based on an observable providing the ids. */
export function toEntities<T extends Entity>(store: Store): OperatorFunction<string[], T[]> {
  return (ids$: Observable<string[]>) =>
    ids$.pipe(
      distinctUntilChanged((a, b) => isArrayEqual(a, b, { respectArrayOrder: true })),
      switchMap(ids => store.select(selectEntitiesById<T>(ids)))
    );
}

function mapIdsToEntities<T extends Entity>(ids: EntityId[], state: EntityState): T[] {
  return (ids || []).map(id => state.entitiesById[id]).filter(Boolean) as T[];
}
