import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { uploadReducer } from './store/upload-reducer';

export const storeModule = StoreModule.forFeature('upload', uploadReducer);

@NgModule({
  imports: [storeModule]
})
export class CelumUploadModule {}
