import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitized',
  pure: true,
  standalone: false
})
export class SanitizedPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any): SafeHtml {
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, value);
    return this.sanitizer.bypassSecurityTrustHtml(sanitized);
  }
}
