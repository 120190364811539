<mat-form-field celum-form-field [size]="formFieldSize" class="lookup-area_lookup" appearance="outline">
  <input
    matInput
    autocomplete="off"
    data-test-lookup-input-field
    [formControl]="valueControl"
    [placeholder]="placeholder | translate"
    (keydown)="onKeyDown($event)"
    (focusin)="onFocus(true)"
    (focusout)="onFocus(false)" />
  <celum-icon class="lookup-area_icon" *ngIf="valueControl?.value.length === 0 && iconPosition === 'left'" matPrefix [configuration]="searchIcon"></celum-icon>
  <celum-icon class="lookup-area_icon" *ngIf="valueControl?.value.length === 0 && iconPosition === 'right'" matSuffix [configuration]="searchIcon"></celum-icon>
  <celum-icon
    class="lookup-area_icon lookup-area_cancel-icon"
    *ngIf="valueControl?.value.length > 0"
    data-test-lookup-cancel-icon
    matSuffix
    [configuration]="cancelIcon"
    [matTooltip]="'COMMON.REMOVE' | translate"
    (click)="clear()"></celum-icon>
  <ng-content select="[right-content]" matSuffix></ng-content>
</mat-form-field>
