import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FloatLabelType } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skip, takeUntil } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';

const INPUT_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CelumAutocompleteArea),
  multi: true
};

/**
 * This is a component that is a wrapper around the matAutocomplete component (see https://material.angular.io/components/autocomplete/overview).
 *
 * As we have all required functionality in matAutocomplete - CelumAutocompleteArea just goes as a wrapper around matAutocomplete and decorate native view of
 * material component and encapsulate some elements which are needed for each use-case. There are input and icons so far, but you can also extend it with your
 * own content thanks to <ng-content>.
 *
 * The component also provides some API to have control over the data changes and events inside component if you need to call some features
 * manually or if your data from parent controller should react some how on changes and events inside CelumAutocompleteArea. \
 * Also the whole APIs of native matAutocomplete and matOption are available.
 */
@Component({
  selector: 'celum-autocomplete-area',
  templateUrl: './autocomplete-area.html',
  styleUrls: ['./autocomplete-area.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [INPUT_CONTROL_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CelumAutocompleteArea extends ReactiveComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
  /**
   * Optionally define a placeholder value for the input.
   */
  @Input() public placeholder: string;

  /**
   * Whether show arrow-down icon or not.
   */
  @Input() public withArrow = true;

  /**
   * Whether to hide panel when first focused or not
   */
  @Input() public hidePanelOnInitialFocus = false;

  /**
   * Default value for input form control.
   */
  @Input() public selected: any;

  /**
   * Default input value 524288 (https://www.w3schools.com/tags/att_input_maxlength.asp).
   */
  @Input() public maxValueLength = 524288;

  /**
   * Form control to attach it to autocomplete input.
   * If you need validation or your custom FC - define it in component which use this component
   * and provide it like in this example:
   * yourFormControl: FormControl = new FormControl('any val', [validationFn]);
   * in template: <celum-autocomplete-area [formControl]="yourFormControl"> ...
   */
  @Input() public formControl: UntypedFormControl = new UntypedFormControl();

  /**
   * Defines behavior of input placeholder.
   * (See also https://material.angular.io/components/form-field/overview)
   */
  @Input() public floatLabel: FloatLabelType;

  /**
   * Added asterisk if input is required.
   */
  @Input() public required = false;

  /**
   * Defines if input is readonly.
   */
  @Input() public readonly = false;

  /**
   * Emit event once icon (arrow down) was clicked.
   */
  @Output() public readonly onIconClicked = new EventEmitter<Event>();

  /**
   * Arrow down icon config.
   */
  public readonly arrowDown = IconConfiguration.small('arrow-down-xs');

  /**
   * Defines autocomplete attribute type for input element.
   */
  public readonly inputAutocompleteAttrType: string = 'off';

  /**
   * Expose resizing property to be able to react on resizing changes.
   */
  public readonly resizing$ = new Subject<Event>();

  /**
   * Reference to native MatAutocomplete which is being attached to input form control in view.
   */
  @ContentChild(MatAutocomplete, { static: false }) public autocomplete: MatAutocomplete;

  /**
   * Set up default class for host element.
   */
  @HostBinding('class.celum-autocomplete-area') public hostClass = true;

  // Needed only to allow as to use [formControl]
  public value = '';

  private initialFocus = true;

  /**
   * Autocomplete trigger allows us to have control over panel show/hide... etc.
   */
  @ViewChild('autoCompleteTrigger', {
    read: MatAutocompleteTrigger,
    static: false
  })
  private autoCompleteTrigger: MatAutocompleteTrigger;

  /**
   * Reference to mat input in order to apply focus
   */
  @ViewChild(MatInput, { static: false }) private matInput: MatInput;

  constructor(private platform: Platform) {
    super();
  }

  /**
   * Handling of window resizing event.
   * Will be removed once we migrate to Material 6. This is a fix for:
   * @link: https://jira.celum.company/browse/PVIP-5413
   * But it will be natively fixed by material2 team
   * @link: https://github.com/angular/material2/blob/master/CHANGELOG.md#600-rc2-2018-04-11
   *
   * @deprecated
   *
   */
  @HostListener('window:resize', ['$event'])
  public onOrientationChanged(event: Event): void {
    this.resizing$.next(event);
  }

  public ngOnInit(): void {
    // Recalculation of the panel width for android devices if orientation was changed
    this.resizing$
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe(() => (this.platform.ANDROID && this.autocomplete.isOpen ? this.openPanel() : null));

    // Set up default value if it was defined
    this.formControl.setValue(this.selected);
  }

  public ngAfterViewInit(): void {
    this.autocomplete.classList = 'celum-autocomplete-panel';

    // We should be sure that panel is visible when data value in form control is changing but we also should skip the first change
    // which is being emitted by first init-change
    this.formControl.valueChanges
      .pipe(
        skip(1),
        filter(value => !!value),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.openPanel());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Opens matAutocomplete panel and apply animations for panel
   */
  public openPanel(): void {
    // this check breaks things in Edge as the panel is not opened reliable and this prevents openings later on (clicking, changing value, etc.)
    // there will be an output event 'opened' in AngularMaterial 6.0.0 - so we can use that instead of using the unreliable animationFrame
    // if (!this.autocomplete.isOpen)
    this.autoCompleteTrigger.openPanel();
    this.matInput.focus();
  }

  public onInputFocus(): void {
    if (this.initialFocus && this.hidePanelOnInitialFocus) {
      this.initialFocus = false;
      return;
    }
    this.openPanel();
  }

  /**
   * Close matAutocomplete panel
   */
  public closePanel(): void {
    this.autoCompleteTrigger.closePanel();
  }

  /**
   * Emits event once icon (arrow down) was clicked
   */
  public iconClicked(e: Event): void {
    this.onIconClicked.emit(e);
  }

  public registerOnChange = () => ({});

  public registerOnTouched = () => ({});

  public writeValue(v: string): void {
    this.value = v;
  }
}
