<div
  #nativeScroller
  class="celum-scrollbar simple-list_content"
  [class.celum-scrollbar--dark]="darkMode"
  infiniteScroll
  [infiniteScrollDistance]="1.5"
  [infiniteScrollContainer]="nativeScroller"
  [infiniteScrollThrottle]="300"
  [alwaysCallback]="true"
  (click)="clickHandler?.onEmptySpaceClick($event)"
  (scrolled)="onScroll(true)"
  (scrolledUp)="onScroll(false)">
  <ng-template [ngTemplateOutlet]="scrollableContent"></ng-template>
</div>

<ng-template #listContent>
  <ng-content select="[top-item]"></ng-content>

  <!-- ng-container for template has to be nested, as it's not possible to use two structural template directives in one tag-->
  <!-- data-item-id has to be generically named for drag and drop reasons -->
  <ng-container *ngIf="(selectionHandler?.selection$ | async) || [] as selection">
    <div
      *ngFor="let item of items; trackBy: trackByFn"
      #listItem
      class="{{ customItemClass }}"
      [attr.data-item-id]="$any(item).id ?? ''"
      [class.selected]="selection | wrapFn: isSelected : item : compareFn"
      click-tap
      (onClick)="clickHandler?.onClick($event, item)"
      (onTap)="clickHandler?.onTap($event, item)"
      (contextmenu)="onContextMenu($event, item)"
      (onDoubleClick)="onDoubleClick($event, item)">
      <ng-container
        *ngTemplateOutlet="itemTemplate; context: { $implicit: item, list: this, selected: selection | wrapFn: isSelected : item : compareFn }"></ng-container>
    </div>
  </ng-container>

  <ng-content select="[bottom-item]"></ng-content>
</ng-template>

<ng-template #scrollableContent>
  <!-- .simple-list_dnd-container used as is it required by dom-autoscroller lib -->
  <div *ngIf="!!ddGroup" class="simple-list_dnd-container" dragula="{{ ddGroup }}">
    <ng-template [ngTemplateOutlet]="listContent"></ng-template>
  </div>

  <div *ngIf="!ddGroup" class="simple-list_dnd-container">
    <ng-template [ngTemplateOutlet]="listContent"></ng-template>
  </div>

  <list-loading-spinner *ngIf="hasMoreBottom" class="simple-list_spinner" [color]="'accent'"></list-loading-spinner>
</ng-template>
