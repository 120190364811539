import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { ServiceTabItem } from '../../model/service-tab-item/service-tab-item';

@Component({
  selector: 'systembar-service-tabs',
  templateUrl: './service-tabs-component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ServiceTabsComponent {
  @Input() public services: ServiceTabItem[];
}
