import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

export function validCharacters(invalidCharacters: string[]): ValidatorFn {
  // do not return function inline -> ngc doesn't like that
  const fn = (control: AbstractControl): { [key: string]: any } => {
    const value: string = control.value;
    // eslint-disable-next-line eqeqeq
    const foundInvalidCharacters: string[] = value != null ? value.split('').filter(c => invalidCharacters.indexOf(c) > -1) : [];

    return foundInvalidCharacters.length === 0
      ? null
      : {
          validCharacters: {
            valid: false,
            messageKey: 'VALIDATION.NO_INVALID_CHARACTERS',
            params: { invalidChars: foundInvalidCharacters }
          }
        };
  };

  return fn;
}

@Directive({
  selector: '[validCharacters][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: validCharacters,
      multi: true
    }
  ],
  standalone: false
})
export class ValidCharactersValidator {}
