import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

import { LocalizedValue } from '@celum/core';

import { SortDirection, SortField } from './sort-info';
import { ColorConstants } from '../base/color-constants';
import { IconConfiguration } from '../icon/icon-configuration';

@Component({
  selector: 'celum-sorter',
  templateUrl: './sorter.html',
  styleUrls: ['./sorter.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class Sorter {
  @Input() public selected: SortField;
  @Input() public mode: SortDirection = SortDirection.DESC;
  @Input() public fields: SortField[] = [];
  @Input() public disableDirection = false;

  @Output() public readonly fieldChanged = new EventEmitter<SortField>();
  @Output() public readonly modeChanged = new EventEmitter<SortDirection>();

  @HostBinding('class.sorter') public hostCls = true;

  public readonly iconConfig: IconConfiguration = IconConfiguration.small('order-desc-m').withColor(ColorConstants.BLUE_GRAY_900);
  protected readonly SortDirection = SortDirection;

  public toggleSortDirection(): void {
    this.mode = SortDirection.DESC === this.mode ? SortDirection.ASC : SortDirection.DESC;
    this.modeChanged.emit(this.mode);
  }

  public handleSelection(sortFieldId: string): void {
    this.selected = this.fields.find(field => field.id === sortFieldId);
    this.fieldChanged.emit(this.selected);
  }

  public getSortOptionName(sortInfo: SortField): string | LocalizedValue {
    return sortInfo.label;
  }
}
