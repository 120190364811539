import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { isArrayEqual, isTruthy } from '@celum/core';

import { selectEntitiesById, selectEntityById } from './entity-state';
import { Entity } from '../entity/entity';

export type RelationFunction<T> = (store: Store) => Observable<T>;

/**
 * Create a connection between a source entity via its foreign key to a target entity.
 * Example: source: asset -> foreignKey: creatorId -> target: user
 * @param id of the source entity
 * @param foreignKey on the source entity which points to the target entity
 */
export function relatedEntity<T extends Entity>(id: string, foreignKey: string): RelationFunction<T> {
  return (store: Store) => findRelatedEntities<T>(store, id, foreignKey, 'single').pipe(map(([entity]) => entity));
}

/**
 * Create a connection between a source entity via its foreign key to its target entities
 * Example: source: asset -> foreignKey: assignees -> target: [user, user]
 * @param id of the source entity
 * @param foreignKey on the source entity which points to the target entities
 */
export function relatedEntities<T extends Entity>(id: string, foreignKey: string): RelationFunction<T[]> {
  return (store: Store) => findRelatedEntities<T>(store, id, foreignKey, 'multiple');
}

function findRelatedEntities<T extends Entity>(store: Store, sourceId: string, foreignKey: string, quantity: 'single' | 'multiple'): Observable<T[]> {
  return store.select(selectEntityById(sourceId)).pipe(
    isTruthy(),
    map(source => (source as any)[foreignKey]),
    map(idOrIds => (quantity === 'single' ? [idOrIds] : idOrIds)),
    distinctUntilChanged(isArrayEqual),
    switchMap(ids => store.select(selectEntitiesById<T>(ids)))
  );
}
