import { inject, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export type PredefinedSecurityContexts = 'href';

export type SanitationOptions = SecurityContext[] | PredefinedSecurityContexts;

/**
 * A pipe that sanitizes a given input string to prevent security risks such as XSS attacks.
 * It uses Angular's DomSanitizer to sanitize the input based on specified security contexts.
 *
 * There is a predefined security context 'href' that is used to sanitize URLs used in href attributes.
 *
 * We wrote it to increase the out-of-the-box security provided by Angular and being able to apply additional sanitation for bound attributes.
 *
 * @example
 * In a template:
 * ```html
 * <a [href]="someUrl |  sanitize: 'href'"></div>
 * ```
 */
@Pipe({
  name: 'sanitize',
  pure: true,
  standalone: true
})
export class SanitizePipe implements PipeTransform {
  private sanitizer = inject(DomSanitizer);

  public transform(value: string, sanitationOptions: SanitationOptions = [SecurityContext.HTML]): string {
    const securityContexts: SecurityContext[] = typeof sanitationOptions === 'string' ? [SecurityContext.HTML] : sanitationOptions;

    let sanitizedValue = value;
    for (const context of securityContexts) {
      sanitizedValue = this.sanitizer.sanitize(context, sanitizedValue);
    }

    if (typeof sanitationOptions === 'string') {
      // Replace all &amp; with & again - this is the only known HTML sanitization that would break urls
      sanitizedValue = sanitizedValue.replace(/&amp;/g, '&');
    }

    return sanitizedValue;
  }
}
