import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutofocusDirective } from './autofocus.directive';
import { InViewportDirective } from './in-viewport-directive';
import { MatMenuOnHoverDirective } from './mat-menu-on-hover.directive';
import { OnDestroyDirective } from './on-destroy.directive';
import { RenderOnHoverDirective } from './render-on-hover.directive';
import { ResizeObserverDirective } from './resize-observer.directive';
import { SpaceAwareTooltipDirective } from './space-aware-tooltip.directive';

@NgModule({
  imports: [CommonModule, InViewportDirective],
  declarations: [RenderOnHoverDirective, AutofocusDirective, OnDestroyDirective, ResizeObserverDirective, SpaceAwareTooltipDirective, MatMenuOnHoverDirective],
  exports: [
    RenderOnHoverDirective,
    InViewportDirective,
    AutofocusDirective,
    OnDestroyDirective,
    ResizeObserverDirective,
    SpaceAwareTooltipDirective,
    MatMenuOnHoverDirective
  ]
})
export class CelumDirectivesModule {}
