import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { EmptyPage } from './components/empty-page';
import { CelumButtonModule } from '../button/index';
import { CelumIconModule } from '../icon/icon.module';

/**
 * ⚠ Requires dependency `@ngrx/store`.
 */
@NgModule({
  imports: [TranslateModule, CommonModule, CelumIconModule, CelumButtonModule],
  declarations: [EmptyPage],
  exports: [EmptyPage, TranslateModule, CelumButtonModule]
})
export class CelumEmptyPageModule {}
