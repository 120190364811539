<div class="systembar-help_help-wrapper" [matMenuTriggerFor]="helpMenu">
  <div class="systembar-help_help-logo">
    <celum-icon class="systembar-help_celum-icon" [configuration]="questionnaireIconConfiguration"></celum-icon>
  </div>
</div>
<mat-menu #helpMenu="matMenu" class="systembar-help_mat-menu" yPosition="above">
  <button mat-menu-item *ngFor="let item of contextMenuItems" (click)="item.onClick()" [attr.data-component-id]="item.dataComponentId">
    {{ item.text | translate }}
  </button>
</mat-menu>
