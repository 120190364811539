type DownloadOptions = {
  /** The filename of the downloaded file. */
  filename?: string;
};

export function startBrowserDownload(documentElement: any, url: string, options?: DownloadOptions): void {
  const downloadAnchor = documentElement.createElement('a');
  downloadAnchor.setAttribute('href', url);
  downloadAnchor.setAttribute('download', options?.filename ?? '');
  downloadAnchor.setAttribute('type', 'hidden');
  documentElement.body.appendChild(downloadAnchor);
  downloadAnchor.click();
  documentElement.body.removeChild(downloadAnchor);
}
