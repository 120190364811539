import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CelumDialog, CelumDialogConfiguration, IconConfiguration } from '@celum/common-components';
import {
  DataContext,
  ICON_SIZE,
  NoEntity,
  OperationContext,
  OperationDefinition,
  OperationGroup,
  OperationsManager,
  OperationsUtil,
  ZIndexHelper
} from '@celum/core';
import { DomHelper, ReactiveComponent, RouteParamService } from '@celum/ng2base';

import { MAGIC_MENU_ROUTE_PARAM } from '../button/model/magic-button-route-param';

@Component({
  selector: 'magic-button-page',
  templateUrl: './magic-button-page.html',
  styleUrls: ['./magic-button-page.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class MagicButtonPage extends ReactiveComponent implements OnDestroy, CelumDialog<MagicButtonPageConfiguration> {
  public cancelIconConfig: IconConfiguration;
  public color: string;
  public currentDataContext: DataContext;
  public generalOperationsList: OperationDefinition[] = [];
  public operations: OperationDefinition[];
  public selectionOperations: OperationDefinition[] = [];
  public title: string;

  private blurContainer: HTMLElement;
  private zIndexHelper: ZIndexHelper;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialogRef: MatDialogRef<MagicButtonPage>,
    private paramService: RouteParamService
  ) {
    super();

    // set the zIndex to a bit more than the default value (1000), to make sure the page is on top of everything
    // remember the current original value to reset to the original one on close
    this.zIndexHelper = new ZIndexHelper('.cdk-overlay-container', 1000);
  }

  public configure(config: MagicButtonPageConfiguration): void {
    if (!config) {
      return;
    }

    this.paramService.setTemporaryParam(MAGIC_MENU_ROUTE_PARAM, String(true));

    DomHelper.disableDocumentScroll();

    this.title = config.pageTitleText || 'MAGIC_BUTTON.ACTIONS';
    this.color = config.color;
    this.cancelIconConfig = IconConfiguration.xLarge('cancel-m', 'COMMON.CLOSE', 40).withColor(this.color + '');

    this.operations = config.operations;
    this.currentDataContext = config.currentDataContext;
    this.collectOperationGroupDefinitions();

    if (config.blurContainerSelector) {
      this.blurContainer = document.querySelector(config.blurContainerSelector);
      if (this.blurContainer) {
        this.blurContainer.style.filter = 'blur(5px)';
      }
    }

    fromEvent(window, 'popstate')
      .pipe(take(1))
      .subscribe(() => {
        if (this.blurContainer) {
          this.blurContainer.style.removeProperty('filter');
        }
      });
    this.changeDetector.markForCheck();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  @HostListener('document:keydown.escape', [])
  public onMagicButtonPageEsc(): void {
    this.closePage();
  }

  public iconConfig(operation: OperationDefinition): IconConfiguration {
    return IconConfiguration.large(operation.operation.getIcon(ICON_SIZE.l), '').withColor('#ffffff');
  }

  public operationClicked(operation: OperationDefinition): void {
    // restore the z-index before opening something else which might also want to modify it!
    this.restoreZIndex();
    operation.operation.execute(this.currentDataContext);
    this.closePage();
  }

  public closePage(): void {
    this.restoreZIndex();

    if (this.blurContainer) {
      this.blurContainer.style.removeProperty('filter');
    }
    DomHelper.enableDocumentScroll();
    this.dialogRef.close();

    // wait with route modifications for a little bit as this could infer with a navigation triggered by an operation
    setTimeout(() => this.paramService.setTemporaryParam(MAGIC_MENU_ROUTE_PARAM, null), 1000);
  }

  private restoreZIndex(): void {
    this.zIndexHelper?.destroy();
    this.zIndexHelper = null;
  }

  private collectOperationGroupDefinitions(): void {
    if (!this.currentDataContext) {
      // do nothing if there is no context currently...
      console.warn('MagicButtonPage: No context present, cannot show any operations!');
      return;
    }

    OperationsManager.getAvailableOperationsForEntityTypes(OperationContext.GENERAL, [NoEntity], this.currentDataContext)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (generalOperations: OperationDefinition[]) => this.collectOperations(generalOperations),
        error => {
          console.error('MagicButtonPage: error resolving operations!', error);
        }
      );
  }

  private collectOperations(generalOperations: OperationDefinition[]): void {
    if (generalOperations && generalOperations.length > 0) {
      const generalGroup = OperationsManager.getGroup('general');

      if (!generalGroup) {
        console.warn('MagicButtonPage: Found no "general" group! Nothing will be displayed...');
        return;
      }

      generalOperations.sort((op1, op2) => op1.groupInfo.getSortOrder() - op2.groupInfo.getSortOrder());

      this.generalOperationsList = generalOperations ?? [];
    }

    // do not show operations again that are shown already within the 'general' operation group
    const notGeneralOperations = this.operations.filter(opDef => {
      return !generalOperations.some(generalOp => generalOp.operation.getKey() === opDef.operation.getKey());
    });

    this.selectionOperations = OperationsUtil.sortOperationDefinitions(notGeneralOperations);

    this.changeDetector.markForCheck();
  }
}

export interface OperationGroupContainer {
  group: OperationGroup;
  operations: OperationDefinition[];
  groupPos: number;
}

export class MagicButtonPageConfiguration extends CelumDialogConfiguration {
  constructor(
    color: string,
    public operations: OperationDefinition[],
    public currentDataContext: DataContext,
    public blurContainerSelector?: string,
    public pageTitleText?: string
  ) {
    super('magic-button-page-configuration');
    this.color = color;
  }
}
