import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap, timer } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';

import { NotificationClientConfig } from '../model/notification-client-config.model';

export type NotificationsCenterState = {
  clientConfiguration: NotificationClientConfig;
  count: number; // The count of unread notifications received from BE
  baselineCount: number; // The last count of unread notifications at the time of opening the panel or refreshing
  localCountChanges: number; // The count of local changes (marking as read/unread)
  error: HttpErrorResponse | null;
};

export const NotificationsCenterStore = signalStore(
  withState<NotificationsCenterState>({ count: 0, baselineCount: 0, localCountChanges: 0, clientConfiguration: null, error: null }),
  withMethods((state, httpClient = inject(HttpClient)) => ({
    fetchNotificationCount: rxMethod<void>(
      switchMap(() =>
        httpClient
          .get<number>(`${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/notifications/count?channel=inapp&state=unread`)
          .pipe(
            tap(count => {
              patchState(state, { count });
            })
          )
      )
    ),
    loadNotificationClientConfigs: rxMethod<void>(
      pipe(
        switchMap(() => {
          const url = `${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/configurations/clients`;
          return httpClient.get<NotificationClientConfig[]>(url).pipe(
            tapResponse(
              // this will not take only [0] once we'll have more than just Work notifications
              configs => patchState(state, { clientConfiguration: configs[0] }),
              (error: HttpErrorResponse) => patchState(state, { error })
            )
          );
        })
      )
    ),
    updateCount: (delta: number) => {
      const currentCount = state.count();
      const currentlocalCountChanges = state.localCountChanges();
      patchState(state, { localCountChanges: currentlocalCountChanges + delta, count: currentCount + delta });
    },
    setCount: (count: number) => {
      patchState(state, { count, baselineCount: count, localCountChanges: 0 });
    },
    storeCompareCount: () => {
      patchState(state, { baselineCount: state.count(), localCountChanges: 0 });
    }
  })),
  withHooks({
    onInit(store) {
      timer(0, CelumPropertiesProvider.properties.systemBar.notification.updateInterval)
        .pipe(takeUntilDestroyed())
        .subscribe(() => store.fetchNotificationCount());

      store.loadNotificationClientConfigs();
    }
  }),
  withComputed(({ count, baselineCount, localCountChanges }) => ({
    refreshCount: computed(() => count() - (baselineCount() + localCountChanges()))
  }))
);
