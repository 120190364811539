import { createFeatureSelector, createSelector, createSelectorFactory, resultMemoize } from '@ngrx/store'; // for compiler

import { GraphEntity, isArrayEqual } from '@celum/core';
import { getEntitiesById, getEntitiesByType } from '@celum/ng2base';

export interface InnerTreeState {
  selectedNode: string;
  rootNodes: string[];
  expandedNodes: string[];
  nrOfRootNodes: number;
  rootNodeSelected: boolean;
}

export interface TreeState {
  trees: { [key: string]: InnerTreeState };
  nodesByParent: Map<string, string[]>;
}

const selectTreeState = createFeatureSelector<TreeState>('tree');

export const selectRootNodeIds = (treeId: string) =>
  createSelector(selectTreeState, state => (state.trees[treeId] ? state.trees[treeId].rootNodes : undefined));

export const getNodesByParent = createSelector(selectTreeState, state => state.nodesByParent || new Map());

const simpleArrayMemoize = (fn: () => any) => resultMemoize(fn, isArrayEqual);
export const getRootNodes = (treeId: string) =>
  createSelectorFactory<any, GraphEntity[]>(simpleArrayMemoize)(
    selectRootNodeIds(treeId),
    getEntitiesById,
    (rootNodeIds: string[], entities: { [key: string]: GraphEntity }) => {
      return rootNodeIds ? rootNodeIds.map(nodeId => entities[nodeId]).filter(node => !!node) : undefined;
    }
  );

export const getNodes = (typeKey: string) =>
  createSelectorFactory<any, GraphEntity[]>(simpleArrayMemoize)(
    getEntitiesByType,
    getEntitiesById,
    (entitiesByType: { [key: string]: Set<string> }, entitiesById: { [key: string]: GraphEntity }) => {
      return entitiesByType[typeKey]
        ? Array.from(entitiesByType[typeKey])
            .map(id => entitiesById[id])
            .filter(node => !!node)
        : [];
    }
  );

export const getRootNodeSelected = (treeId: string) =>
  createSelector(selectTreeState, state => (state.trees[treeId] ? state.trees[treeId].rootNodeSelected : undefined));

export const getSelectedNodeId = (treeId: string) =>
  createSelector(selectTreeState, state => (state.trees[treeId] ? state.trees[treeId].selectedNode : undefined));

export const getExpandedNodeIds = (treeId: string) => createSelector(selectTreeState, state => (state.trees[treeId] ? state.trees[treeId].expandedNodes : []));

export const getNrOfRootNodes = (treeId: string) => createSelector(selectTreeState, state => (state.trees[treeId] ? state.trees[treeId].nrOfRootNodes : -1));
