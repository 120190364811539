import { Directive, ElementRef, HostListener, Optional } from '@angular/core';

import { ReactiveComponent } from '@celum/ng2base';

import { AbstractList } from '../components/abstract-list';
import { CelumList } from '../components/celum-list/celum-list';
import { CelumSimpleList } from '../components/simple-list/simple-list';
import { CelumVirtualList } from '../components/virtual-list/virtual-list';

@Directive({
  standalone: true,
  selector: '[clear-selection-on-document-click]'
})
export class ClearSelectionOnDocumentClickDirective extends ReactiveComponent {
  constructor(
    @Optional() private simpleList: CelumSimpleList,
    @Optional() private celumList: CelumList<any>,
    @Optional() private virtualList: CelumVirtualList,
    private elementRef: ElementRef
  ) {
    super();
  }

  private get list(): AbstractList<any> {
    return this.celumList ?? this.simpleList ?? this.virtualList;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (!this.list) {
      console.warn('ClearSelectionOnDocumentClickDirective: No matching list found. Directive will not work as expected.');
      return;
    }

    if (!this.elementRef?.nativeElement?.contains(targetElement) && this.list.selectionHandler?.getCurrentSelection().length > 0) {
      this.list.selectionHandler?.clearSelection(false);
    }
  }
}
