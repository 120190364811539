<div
  class="celum-avatar_visual-wrap"
  [matTooltip]="configuration.tooltip | translate: configuration.tooltipTranslateParams"
  [style.opacity]="configuration.opacity">
  <span
    *ngIf="!imageAvailable"
    class="celum-avatar_image celum-avatar_image--none"
    [style.lineHeight.rem]="avatarSize"
    [style.font-size.rem]="avatarPlaceholderFontSize / 10 - 0.2"
    [style.font-weight]="avatarPlaceholderFontSize < 10 ? 600 : 500"
    [style.background-color]="configuration?.backgroundColor"
    [style.border-color]="configuration?.borderColor"
    [style.color]="configuration?.textColor"
    [style.width.rem]="avatarSize"
    [style.height.rem]="avatarSize"
    [style.min-width.rem]="avatarSize"
    title="{{ (configuration?.useHtmlTitleAttribute ? configuration?.title : '') | translate }}">
    <span>{{ configuration?.avatarTextPlaceholder ? configuration.avatarTextPlaceholder : avatarTextPlaceholder }}</span>
    <celum-icon *ngIf="configuration?.icon" class="celum-avatar_icon" [configuration]="configuration?.icon" [clickable]="false"></celum-icon>
    <span
      *ngIf="configuration?.showOverlay"
      class="celum-avatar_overlay"
      [style.background-color]="configuration?.overlayColor"
      (click)="onOverlayClicked($event)">
      <celum-icon [configuration]="configuration?.overlayIcon"></celum-icon>
    </span>
  </span>
  <span
    *ngIf="imageAvailable"
    class="celum-avatar_image"
    data-private
    [style.backgroundImage]="'url(' + configuration?.image + ')'"
    [style.border-color]="configuration?.borderColor"
    [style.height.rem]="avatarSize"
    [style.lineHeight.rem]="avatarSize"
    [style.min-width.rem]="avatarSize"
    [style.width.rem]="avatarSize"
    title="{{ configuration?.title | translate }}">
    &nbsp;
    <span
      *ngIf="configuration?.showOverlay"
      class="celum-avatar_overlay"
      [style.background-color]="configuration?.overlayColor"
      (click)="onOverlayClicked($event)">
      <celum-icon [configuration]="configuration?.overlayIcon"></celum-icon>
    </span>
  </span>
  <div class="celum-avatar_top-labels" *ngIf="(topLabels$ | async)?.length > 0">
    <celum-icon *ngFor="let label of topLabels$ | async" [configuration]="label"></celum-icon>
  </div>
  <div class="celum-avatar_bottom-labels" *ngIf="(bottomLabels$ | async)?.length > 0">
    <celum-icon *ngFor="let label of bottomLabels$ | async" [configuration]="label"></celum-icon>
  </div>
</div>
<div
  *ngIf="configuration?.displayName"
  class="celum-avatar_name celum-avatar_name--{{ avatarSizeClassName }}"
  [class.celum-avatar_name--inactive]="configuration?.inactive"
  data-private
  spaceAwareTooltip
  [matTooltip]="userName | translate">
  {{ userName | translate }}
</div>
