import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'loading-mask',
  templateUrl: './loading-mask.html',
  styleUrls: ['./loading-mask.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LoadingMask {
  @Input() public background: SafeStyle;
  @Input() public opacity: number;
  @Input() public diameter = 44;
  @Input() public strokeWidth = 4;
}
