import { Component, effect, ElementRef, inject, input, OnInit, output, Renderer2 } from '@angular/core';

import { NotificationState, NotificationWithUsers } from '@celum/shared/domain';

import { NotificationMappingInfo } from '../model/notification-mapping-info.model';

interface NotificationElement extends HTMLElement {
  state: NotificationState;
}

@Component({
  selector: 'clm-notification-item',
  template: ''
})
export class NotificationItemComponent implements OnInit {
  public notification = input<NotificationWithUsers>();
  public mappingInfos = input<NotificationMappingInfo[]>();

  public readonly notificationStateChange = output<{ id: string; newState: NotificationState }>();

  private renderer = inject(Renderer2);
  private hostElement = inject(ElementRef);
  // reference to the created element for state updates
  private notificationElement: NotificationElement;

  constructor() {
    effect(() => {
      const state = this.notification()?.state;
      if (this.notificationElement) {
        this.notificationElement.state = state;
      }
    });
  }

  public ngOnInit(): void {
    this.createAndAppendElement();
  }

  private getElementName(notification: NotificationWithUsers): string | null {
    const mappingInfo = this.mappingInfos().find(mapping => mapping.notificationType === notification.type);
    if (mappingInfo) {
      return mappingInfo.elementName;
    }

    throw new Error(`No mapping found for notification type ${notification.type}`);
  }

  private createAndAppendElement(): void {
    const element = this.renderer.createElement(this.getElementName(this.notification()));
    element.data = this.notification().data;
    element.affectedUsersMap = this.notification().affectedUsersMap;
    element.type = this.notification().type;
    element.state = this.notification().state;
    element.sender = this.notification().sender;
    element.createdOn = this.notification().createdOn;
    element.id = this.notification().id;
    element.addEventListener('notificationStateChange', (event: CustomEvent) => {
      this.notificationStateChange.emit(event.detail);
    });
    this.renderer.appendChild(this.hostElement.nativeElement, element);

    this.notificationElement = element;
  }
}
