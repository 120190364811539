import { ApiConfiguration, B2C_SERVICE_TOKEN_REQUEST_DTO, ServiceTokenRequestDto } from '@celum/authentication';
import { PortalsProperties } from '@celum/portals/domain';

export const PORTAL_CLOUD_TOKEN_QUERY_PARAM_NAME = 'cloudToken';
export const PORTAL_CLOUD_TOKEN_HEADER_PARAM_NAME = 'X-Cloud-Token';

/**
 * These endpoints require the b2c token (= id token) for authentication.
 */
const API_URLS_FOR_B2C_TOKEN = [
  // overrides for cloud functions - metadata worker
  `${PortalsProperties.properties.apiUrl}/configuration`, // currently used in portals only (experience fetches the configuration via a call to the experience backend)
  `${PortalsProperties.properties.apiUrl}/token`, // both experience and portals
  // overrides for cloud functions - binary worker
  `${PortalsProperties.properties.binariesUrl}/ct`, // content templates
  `${PortalsProperties.properties.binariesUrl}/dt`, // design themes
  `${PortalsProperties.properties.binariesUrl}/pl` // libraries
];

/**
 * These endpoints (workers, container-apps) require the service specific cloud token (= id token) for authentication.
 *
 * ️ ⚠️ Attention: Some of those endpoints have more specific variants that require the B2C token. E.g. 'PortalsProperties.properties.apiUrl' requires
 * the cloud token, but 'PortalsProperties.properties.apiUrl/portals' the b2c token.
 */
const API_URLS_FOR_CLOUD_TOKEN = [
  // cloud functions
  PortalsProperties.properties.apiUrl, // metadata worker, e.g. /search/<portalId>, /search-variants/<portalId>, /portals/<portalId>/assets/<assetId>
  PortalsProperties.properties.binariesUrl, // binaries worker
  // container apps
  PortalsProperties.properties.azureContainerUrl, // zip creator
  PortalsProperties.properties.contentTemplateExportUrl // imgly server
];

export function createB2CTokenApiConfigForPortals(tenantId: string): ApiConfiguration {
  return {
    apiUrls: API_URLS_FOR_B2C_TOKEN,
    serviceTokenRequestDto: B2C_SERVICE_TOKEN_REQUEST_DTO,
    tenantIdHeader: tenantId
  };
}

export function createCloudTokenApiConfigForPortals(tenantId: string): ApiConfiguration {
  return {
    apiUrls: API_URLS_FOR_CLOUD_TOKEN,
    serviceTokenRequestDto: createCloudTokenRequestDto(tenantId),
    tenantIdHeader: tenantId,
    tokenQueryParamName: PORTAL_CLOUD_TOKEN_QUERY_PARAM_NAME,
    tokenHeaderParamName: PORTAL_CLOUD_TOKEN_HEADER_PARAM_NAME,
    tokenEndpoint: `${PortalsProperties.properties.apiUrl}/token`
  };
}

function createCloudTokenRequestDto(tenantId: string): ServiceTokenRequestDto {
  return {
    clientId: 'cloud',
    orgId: tenantId
  };
}
