import { inject, Injectable } from '@angular/core';

import { ExperienceLicense, LicenseType, UserService } from '@celum/authentication';
import { PortalsProperties } from '@celum/portals/domain';

@Injectable({ providedIn: 'root' })
export class PortalFeatureAccessService {
  private userService = inject(UserService);

  public hasContentTemplateAccess(): boolean {
    if (!PortalsProperties.properties.contentTemplateAccessCheckRequired) {
      return true;
    }

    const accountAccess = this.userService.getAccountAccessForCurrentTenant();
    return (UserService.getValidLicenseByType(accountAccess, LicenseType.EXPERIENCE) as ExperienceLicense).contentTemplatesFeature;
  }
}
