<div *ngIf="configuration.bubble" class="snackbar_bubble">{{ configuration.bubble }}</div>

<div
  *ngIf="configuration.icon"
  class="snackbar_icon-wrapper"
  [class.snackbar_icon-wrapper--with-color]="configuration.iconBackgroundColor"
  [style.background-color]="configuration.iconBackgroundColor">
  <celum-icon
    class="snackbar_status-icon"
    [class.snackbar_status-icon--expandable]="configuration.expandable"
    [configuration]="configuration.icon"
    (click)="toggle()"></celum-icon>
</div>

<div class="snackbar_toggle-anchor" [class.snackbar_toggle-anchor--expandable]="configuration.expandable" (click)="toggle()">
  <div class="snackbar_header-text-wrapper" [class.snackbar_header-text-wrapper--with-toggle]="configuration.showToggleBeforeActions">
    <span
      class="snackbar_header-text-wrapper_title"
      data-test-snackbar-title
      [class.snackbar_header-text-wrapper_title--with-description]="description"
      [class.snackbar_header-text-wrapper_title--expandable]="configuration.expandable"
      [title]="header | translate | stripHTML"
      [innerHTML]="htmlHeader"></span>

    <span
      class="snackbar_header-text-wrapper_description"
      data-test-snackbar-description
      [class.snackbar_header-text-wrapper_description--expandable]="configuration.expandable"
      [title]="description | translate | stripHTML"
      [innerHTML]="htmlDescription"></span>
  </div>
  <ng-container *ngIf="configuration.showToggleBeforeActions" [ngTemplateOutlet]="expandToggleIcon"></ng-container>
</div>

<div class="snackbar_actions" data-test-snackbar-actions>
  <span
    *ngFor="let action of currentActions"
    class="snackbar_action-text"
    [attr.data-test-action]="action.name | translate"
    (click)="action.execute(configuration.identifier)">
    {{ action.name | translate | uppercase }}
  </span>
  <button
    mat-flat-button
    celum-button
    *ngFor="let button of configuration.buttons"
    class="snackbar_action-button"
    [attr.data-test-action]="button?.identifier || (button.text | translate)"
    [class.snackbar_action-button--disabled]="button.disabled"
    [class.snackbar_action-button--loading]="button.loading"
    size="small"
    color="primary"
    [ngStyle]="{ backgroundColor: button.buttonColor }"
    (click)="button.action.execute(configuration.identifier)">
    <celum-icon *ngIf="button.icon" [class.loading]="button.loading" [configuration]="button.icon"></celum-icon>
    {{ button.text | translate | uppercase }}
  </button>
</div>

<ng-container *ngIf="!configuration.showToggleBeforeActions" [ngTemplateOutlet]="expandToggleIcon"></ng-container>

<celum-icon
  *ngIf="configuration.dismissible"
  data-test-dismiss-snackbar
  class="snackbar_dismiss-icon"
  [configuration]="dismissIcon"
  (click)="dismiss()"></celum-icon>

<ng-template #expandToggleIcon>
  <celum-icon
    *ngIf="configuration.expandable"
    class="snackbar_toggle-icon"
    [class.snackbar_toggle-icon--collapsed]="!configuration.expanded"
    [configuration]="toggleIcon"
    (click)="toggle()"></celum-icon>
</ng-template>
