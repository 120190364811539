import { LocalizedValue } from '@celum/core';

import { ColorConstants } from '../base/color-constants';
import { IconConfiguration } from '../icon/icon-configuration';

export class MessageConfiguration {
  private constructor(
    public messages: (string | LocalizedValue)[],
    public messageParams: object,
    public title: string | LocalizedValue,
    public titleParams: object,
    public color: string,
    public icon: IconConfiguration
  ) {}

  public withMessages(messages: (string | LocalizedValue)[]): MessageConfiguration {
    this.messages = messages;
    return this;
  }

  public withMessageParams(params: object): MessageConfiguration {
    this.messageParams = params;
    return this;
  }

  public withTitleParams(params: object): MessageConfiguration {
    this.titleParams = params;
    return this;
  }

  public withColor(color: string): MessageConfiguration {
    this.color = color;
    this.icon.color = color;
    return this;
  }

  public withIcon(icon: IconConfiguration): MessageConfiguration {
    this.icon = icon;
    return this;
  }

  public static info(title: string | LocalizedValue, message?: string): MessageConfiguration {
    return new MessageConfiguration(
      message ? [message] : [],
      {},
      title,
      {},
      ColorConstants.PRIMARY,
      MessageConfiguration.toIconConfig('attention-l', ColorConstants.PRIMARY)
    );
  }

  public static warn(title: string | LocalizedValue, message?: string): MessageConfiguration {
    return new MessageConfiguration(
      message ? [message] : [],
      {},
      title,
      {},
      ColorConstants.WARNING,
      MessageConfiguration.toIconConfig('warning', ColorConstants.WARNING)
    );
  }

  public static error(title: string | LocalizedValue, message?: string): MessageConfiguration {
    return new MessageConfiguration(
      message ? [message] : [],
      {},
      title,
      {},
      ColorConstants.ERROR,
      MessageConfiguration.toIconConfig('error-l', ColorConstants.ERROR)
    );
  }

  private static toIconConfig(icon: string, color: string): IconConfiguration {
    return IconConfiguration.large(icon).withIconSize(64).withColor(color);
  }
}
