export class ColorConstants {
  public static SYSTEM_BLACK = '#263238';
  public static SYSTEM_WHITE = '#fff';

  public static SYSTEM_BLACK_56 = 'rgba(38, 50, 56, 0.56)';
  public static SYSTEM_BLACK_75 = 'rgba(38, 50, 56, 0.75)';

  public static SYSTEM_LIGHTGRAY_40 = 'rgba(218,218,218, 0.4)';

  public static SYSTEM_RED = '#ff3f3f';
  public static SYSTEM_BLUE = '#0093b8';
  public static SYSTEM_GREEN = '#37ba63';
  public static SYSTEM_ALERT = '#ff7d09';
  public static SYSTEM_VIOLET = '#794be8';

  public static HOVER_ITEM_BACKGROUND_COLOR = '#dadada';

  public static SYSTEM_GRAY_DISABLED_BG = '#d8dbea';
  public static SYSTEM_GRAY_DISABLED_DARKER = '#bdbdbd';

  // NEW GENERAL COLOR DEFINITIONS
  // branded nova color for celumium - todo: Check how to do branding without adapting this color (can be used from workroom in the future as well
  public static PRIMARY = 'var(--colorPrimary)';
  public static SECONDARY = '#56C4CF';
  public static STANDARD_BLACK = '#263238';
  public static SUCCESS = '#37ba63';
  public static WARNING = '#ff7d09';
  public static ERROR = '#ff3f3f';
  public static ACCENT = '#90a4ae';
  public static BLUE_GRAY_900 = '#263238';
  public static BLUE_GRAY_600 = '#546e7a';
  public static BLUE_GRAY_400 = '#78909c';
  public static BLUE_GRAY_200 = '#b0bec5';
  public static BLUE_GRAY_500 = '#607d8b';
  public static BLUE_GRAY_300 = '#90a4ae';
  public static BLUE_GRAY_700 = '#455a64';
  public static BLUE_GRAY_800 = '#37474f';
  public static BLUE_GRAY_100 = '#cfd8dc';
  public static BLUE_GRAY_050 = '#eceff1';

  private static systemBlackRgba = 'rgba(38, 50, 56, ';

  /**
   * Get the system black as rgba with the defined opacity value
   * @param opacity value (1-100)
   */
  public static systemBlack(opacity: number): string {
    return `${ColorConstants.systemBlackRgba}${opacity / 100})`;
  }
}
