import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CelumIconModule } from '@celum/common-components';

import { CelumCollapseButtonComponent } from './collapse-button.component';

@NgModule({
  declarations: [CelumCollapseButtonComponent],
  imports: [CommonModule, CelumIconModule],
  exports: [CelumCollapseButtonComponent]
})
export class CelumCollapseButtonModule {}
