<div class="systembar-avatar_avatar-wrapper" [matMenuTriggerFor]="avatarMenu" matTooltip="{{ user?.email }}" data-test-open-user-profile>
  <mat-menu class="systembar-avatar_mat-menu" #avatarMenu="matMenu" yPosition="above">
    <div *ngIf="user" class="systembar-avatar_user-wrapper" data-test-user-header-info>
      <div class="systembar-avatar_avatar">
        <ng-content select="[big-avatar]"></ng-content>
      </div>
      <p class="systembar-avatar_user-full-name">{{ user.firstName }} {{ user.lastName }}</p>
      <div class="systembar-avatar_user-email">{{ user.email }}</div>
    </div>

    <!-- without div, with all css properties in mat-select causing on first menu open scrollbars visible, next opens work as expected -->
    <div class="systembar-avatar_select-wrapper" (click)="selectClicked($event)" *ngIf="languages">
      <div class="systembar-avatar_menu-group-title systembar-avatar_menu-group-language-title">{{ 'LANGUAGE_PICKER.LANGUAGES' | translate }}</div>
      <mat-form-field celum-form-field data-test-language-dropdown [attr.data-component-id]="languages.dataComponentId">
        <mat-select (click)="selectClicked($event)" hideSingleSelectionIndicator [value]="languages.activeLanguageKey">
          <mat-option
            *ngFor="let lang of languages.availableLanguages | keyvalue"
            class="systembar-avatar_option"
            [attr.data-test-language-option]="lang.key"
            [value]="lang.key"
            (click)="languages.onClick(lang.key)">
            {{ lang.value | language: lang.key | uppercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="systembar-avatar_menu-group-title systembar-avatar_menu-group-settings-title" *ngIf="avatar?.items?.length > 0">
      {{ 'COMMON.SETTINGS' | translate }}
    </div>
    <button
      mat-menu-item
      *ngFor="let item of avatar?.items"
      [disabled]="item.disabled"
      (click)="item.onClick()"
      [attr.data-component-id]="item.dataComponentId">
      {{ item.text | translate }}
    </button>
    <div class="systembar-avatar_mat-divider-wrapper">
      <mat-divider class="systembar-avatar_mat-divider"></mat-divider>
    </div>
    <button mat-menu-item (click)="avatar?.logoutItem?.onClick()" [attr.data-component-id]="avatar?.logoutItem?.dataComponentId">
      <div class="systembar-avatar_logout-wrapper">
        <celum-icon [configuration]="logOutIconConfiguration"></celum-icon>
        <span class="systembar-avatar_logout-text">{{ avatar?.logoutItem?.text | translate }}</span>
      </div>
    </button>
  </mat-menu>

  <div mat-icon-button class="systembar-avatar_avatar-button">
    <ng-content select="[small-avatar]"></ng-content>
  </div>
</div>
