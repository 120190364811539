import { Router } from '@angular/router';

export class NavigationUtil {
  public static openDialog(router: Router, dialogUrl: string): void {
    router.navigate([{ outlets: { dialog: dialogUrl } }], { queryParamsHandling: 'preserve' });
  }

  public static isDialogOutletActive(router: Router): boolean {
    return router.url.indexOf('(dialog:') >= 0;
  }
}
