import { EntityComponentCustomization, Scope } from '@celum/core';

import { ListItem } from '../../list-basics';

export abstract class OneCardItemCustomization<ENTITY> implements EntityComponentCustomization<ENTITY, ListItem> {
  public static type = 'OneCardItemCustomization';

  public abstract getScope(): Scope;

  public abstract chooseOne(extensions: ListItem[], entity: ENTITY): ListItem;

  public abstract getIdentifier(): string;

  public getTypeIdentifier(): string {
    return OneCardItemCustomization.type;
  }
}
