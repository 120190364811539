import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';

import { CelumButtonModule, CelumIconModule, CelumLoadingMaskModule } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';

import { CelumFullscreenDialogHeaderComponent } from './fullscreen-dialog-header/fullscreen-dialog-header.component';
import { CelumFullscreenDialogComponent } from './fullscreen-dialog.component';

@NgModule({
  declarations: [CelumFullscreenDialogComponent, CelumFullscreenDialogHeaderComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    TranslateModule,

    CelumButtonModule,
    CelumIconModule,
    CelumLoadingMaskModule,
    CelumPipesModule
  ],
  exports: [CelumFullscreenDialogComponent, CelumFullscreenDialogHeaderComponent]
})
export class CelumFullscreenDialogModule {}
