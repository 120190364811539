export class OperationGroup {
  /**
   * @param key         unique key for the operation
   * @param icon        the icon to use for this group
   * @param messageKey  the message key for the display text of this group
   * @param groupType   the type of this group
   */
  constructor(
    public key: string,
    public icon: string,
    public messageKey: string,
    public groupType: GroupType = GroupType.SECTION
  ) {}

  /**
   * Unique key for the operation.
   * @deprecated please directly access the property
   */
  public getKey(): string {
    return this.key;
  }

  /**
   * The icon to use when displaying the group.
   * @deprecated please directly access the property
   */
  public getIcon(): string {
    return this.icon;
  }

  /**
   * The message key for the display text of this group.
   * @deprecated please directly access the property
   */
  public getMessageKey(): string {
    return this.messageKey;
  }
}

export enum GroupType {
  SECTION,
  SUBMENU
}
