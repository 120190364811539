import { DestroyRef, ElementRef } from '@angular/core';
import { fromEvent, isObservable, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { addScript } from '@celum/core';

export class DomHelper {
  // todo use DOCUMENT token instead of plain document as soon as we found out how to inject it into test cases (just managed to inject services but not tokens)

  public static stopKeyboardEvents(element: HTMLElement, keycodes: any[]): void {
    // Cleaning of subscription is taken care of by browser/rxjs
    fromEvent<KeyboardEvent>(element, 'keydown')
      .pipe(filter(event => keycodes.indexOf(event.keyCode) > -1))
      .subscribe(event => event.stopPropagation());
  }

  public static disableDocumentScroll(): void {
    const html = document.querySelector('html');
    html.classList.add('scroll-disabled');
  }

  public static enableDocumentScroll(): void {
    const html = document.querySelector('html');
    html.classList.remove('scroll-disabled');
  }

  public static disableDocumentScrollForScrollContainer(element: HTMLElement): any {
    const disableScroll = this.getDisableScroll(element);
    document.addEventListener('wheel', disableScroll);
    document.addEventListener('touchmove', disableScroll);
    return disableScroll;
  }

  public static enableDocumentScrollForScrollContainer(disableScrollMethod: any): void {
    if (disableScrollMethod) {
      document.removeEventListener('wheel', disableScrollMethod, false);
      document.removeEventListener('touchmove', disableScrollMethod, false);
    }
  }

  public static smoothScrollToTop(): void {
    document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Sets tabindex on given element and focuses element.
   * Use in ngOnInit()
   * This causes that all global events (like key-events) will now be called on the element instead of document
   * @param elementRef which should gain focus.
   * @param tabIndex which should be used for the element, defaults to 0
   */
  public static autofocus(elementRef: ElementRef, tabIndex = 0): void {
    const nativeElement: HTMLElement = elementRef && elementRef.nativeElement;
    if (!nativeElement) {
      console.warn('autofocus cannot be done on non-existent element!');
      return;
    }

    elementRef.nativeElement.setAttribute('tabindex', String(tabIndex));
    setTimeout(() => elementRef.nativeElement.focus()); // use timeout as when called in onInit it would yield ExpressionChangedAfterItHasBeenCheckedError
  }

  /**
   * Blurs the currently active element.
   */
  public static blurActiveElement(): void {
    const activeElement = document.activeElement as HTMLElement;
    activeElement?.blur();
  }

  /**
   * Takes an HTML element and returns a stream which emits whenever the size of the element changes.
   * The underlying mechanism uses the native ResizeObserver
   * It is recommended to use the ResizeObserverDirective instead where possible for easier integration into your component.
   *
   * Important: The resize observer callback is not patched by zone and is therefore not triggering change detection.
   * So in cases where there is no other entity triggering a cd-cycle, you need to run your code in the zone by using zone.run(() => ...)
   * https://github.com/angular/angular/issues/45105
   *
   * @param element for which the listener should be registered
   * @param stop$ detaches the listener on emit
   */
  public static registerResizeObserver(element: HTMLElement, stop$: Observable<void> | DestroyRef): Observable<ResizeObserverEntry> {
    const resize$ = new Subject<ResizeObserverEntry>();
    const observer = new ResizeObserver(entries => {
      // we call observe once per observer so the entries will always be from the same element - therefore we are only interested in the most recent one (last)
      const entry = entries[entries.length - 1];
      resize$.next(entry);
    });
    observer.observe(element);

    if (isObservable(stop$)) {
      stop$.subscribe(() => {
        observer.disconnect();
        resize$.complete();
      });
    } else {
      stop$.onDestroy(() => {
        observer.disconnect();
        resize$.complete();
      });
    }
    return resize$.asObservable();
  }

  public static addScript(src: string): HTMLScriptElement {
    return addScript(src);
  }

  public static addStyleSheetLink(styleSheetPath: string): void {
    const link: HTMLLinkElement = document.createElement('link');
    link.rel = 'stylesheet';
    document.head.insertBefore(link, null);
    link.setAttribute('href', styleSheetPath);
  }

  public static escapeHtml(value: string, nl2br: boolean): string {
    if (!value) {
      return value;
    }
    const escaped = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    if (nl2br) {
      return escaped.replace(/(\r\n|\n|\r)/gm, '<br/>');
    }
    return escaped;
  }

  private static getDisableScroll(scrollEl: HTMLElement): any {
    return (event: any) => {
      if (event && scrollEl) {
        const TOLERANCE = 1;
        const scrollingDown = event.deltaY >= 0;
        const scrollingUp = !scrollingDown;
        const reachedBottom = scrollEl.scrollTop + TOLERANCE >= scrollEl.scrollHeight - scrollEl.clientHeight;
        const reachedTop = scrollEl.scrollTop - TOLERANCE <= 0;

        if ((reachedBottom && scrollingDown) || (reachedTop && scrollingUp)) {
          event.preventDefault();
        }
      }
    };
  }
}
