<clm-notifications-header
  [count]="notificationCount()"
  [refreshCount]="notificationRefreshCount()"
  (markAllAsRead)="onMarkAllAsRead()"
  (refresh)="onRefresh()"
  (showOnlyUnread)="onShowOnlyUnread($event)"></clm-notifications-header>

@if (vm().initialLoading) {
  <div class="notifications-list_spinner-wrapper">
    <mat-spinner class="notifications-list_spinner" [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
  </div>
} @else {
  <div class="celum-scrollbar notifications-list_list-wrapper" hasOverflow>
    @for (item of vm().notifications; track item.id) {
      <clm-notification-item
        [mappingInfos]="vm().notificationMappingInfos"
        [notification]="item"
        (notificationStateChange)="onNotificationStateChange($event)"></clm-notification-item>
    }

    @if (!vm().loading && !vm().loadingBatch && vm().paginationInfo?.hasBottom) {
      <div inViewport (visible)="$event && notificationListService.loadNextBatch()"></div>
    }

    @if (vm().paginationInfo?.hasBottom) {
      <mat-spinner class="notifications-list_list-wrapper-spinner" [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
    }
  </div>

  @if (!vm().loading && !vm().notifications.length) {
    <empty-page class="notifications-list_fallback" [config]="emptyPage()"></empty-page>
  }
}
