import { inject, Injectable, Type } from '@angular/core';
import { Store } from '@ngrx/store';

import { UUIDGenerator } from '@celum/core';

import { SimpleSnackbar } from './components/simple-snackbar/simple-snackbar';
import { SnackbarConfiguration } from './components/snackbar/snackbar-configurations';
import { RemoveSnackbar, ShowSnackbar } from './model/snackbar-action';
import { SnackbarComponent } from './model/snackbar-component';

export type SnackbarOptions = {
  /** The unique identifier associated with the snackbar, if none is given a UUID will be generated. */
  id?: string;
  /** Whether the snackbar should be placed on top of the others or at the bottom. Default: false. */
  placeOnTop?: boolean;
};

/**
 * Helper service to show snackbars without the need to dispatch actions on the global store.
 * This eliminates the need to inject the Store in every component that wants to show a snackbar and simplifies test assertions.
 */
@Injectable({ providedIn: 'root' })
export class SnackbarService {
  private store = inject(Store);

  /**
   * Shows a snackbar of type {@link componentOrElementTag} with the given {@link config}.
   * @returns The unique identifier of the snackbar, either passed in the options or generated.
   */
  public show<C>(componentOrElementTag: Type<SnackbarComponent<C>> | string, config: C, options?: SnackbarOptions): string {
    const id = options?.id ?? UUIDGenerator.generateId();
    this.store.dispatch(new ShowSnackbar(id, componentOrElementTag, config, options?.placeOnTop ?? false));
    return id;
  }

  /**
   * Shows a simple snackbar.
   * This is a shortcut for `show(SimpleSnackbar, config, options)`.
   * @returns The unique identifier of the snackbar, either passed in the options or generated.
   */
  public showSimple(config: SnackbarConfiguration, options?: SnackbarOptions): string {
    return this.show(SimpleSnackbar, config, options);
  }

  public remove(id: string): void {
    this.store.dispatch(new RemoveSnackbar(id));
  }
}
