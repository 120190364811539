import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { EMPTY, map, Observable, switchMap, take, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationState, NotificationWithUsers } from '@celum/shared/domain';
import { PagedListState, Result, SimplePagedListService } from '@celum/shared/util';

import { NotificationClientConfig } from '../model/notification-client-config.model';
import { NotificationItemsConfig } from '../model/notification-items-config.model';
import { NotificationMappingInfo } from '../model/notification-mapping-info.model';
import { NotificationsCenterStore } from '../notifications-center/notifications-center.service';
import { NotificationComponentsLoaderService } from '../service/notification-components-loader.service';
import { NotificationService } from '../service/notification.service';

export type NotificationListState = {
  clientConfig: NotificationClientConfig;
  notificationMappingInfos: NotificationMappingInfo[];
  componentLoaded: boolean;
  error: HttpErrorResponse | null;
  showOnlyUnread: boolean;
};

export type NotificationsViewModel = {
  notifications: NotificationWithUsers[];
  initialLoading: boolean;
} & NotificationListState &
  PagedListState<NotificationWithUsers>;

@Injectable()
export class NotificationListService extends SimplePagedListService<NotificationWithUsers, NotificationListState> {
  public vm$ = this.createViewModel();

  protected notificationComponentsLoaderService = inject(NotificationComponentsLoaderService);
  private notificationsCenterStore = inject(NotificationsCenterStore);
  private notificationService = inject(NotificationService);
  constructor() {
    super(
      {
        serverCall: (offset: number, limit: number) => this.loadNotifications(offset, limit),
        batchSize: 25
      },
      { clientConfig: null, notificationMappingInfos: null, componentLoaded: false, error: null, showOnlyUnread: false }
    );
  }

  public init(clientConfig: NotificationClientConfig): void {
    this.patchState({ clientConfig });
    this.loadComponents(clientConfig);
    this.load();
  }

  public updateShowOnlyUnread(value: boolean): void {
    this.patchState({ showOnlyUnread: value });
    this.load();
  }

  public markAllAsRead = this.effect<void>(trigger$ =>
    trigger$.pipe(
      switchMap(() => {
        const { showOnlyUnread, data: notifications } = this.get();
        const unreadNotificationsCount = this.notificationsCenterStore.count();
        this.markAllAsReadInternal(showOnlyUnread);

        return this.notificationService.markAllAsRead().pipe(
          catchError(() => {
            this.patchState({ data: notifications });
            this.notificationsCenterStore.setCount(unreadNotificationsCount);
            return EMPTY;
          })
        );
      })
    )
  );

  public updateNotificationState = this.effect<{ notificationId: string; newState: NotificationState }>(payload$ =>
    payload$.pipe(
      switchMap(({ notificationId, newState }) => {
        const { showOnlyUnread, data: currentNotifications, offset, paginationInfo } = this.get();
        const notificationIndex = currentNotifications.findIndex(notification => notification.id === notificationId);
        if (currentNotifications[notificationIndex].state === newState) {
          return EMPTY;
        }

        const originalNotification = currentNotifications[notificationIndex];
        const updatedNotifications = [...currentNotifications];
        const shouldRemoveNotification = showOnlyUnread && newState === NotificationState.READ;

        if (shouldRemoveNotification) {
          updatedNotifications.splice(notificationIndex, 1);
          this.patchState({
            data: updatedNotifications,
            offset: offset - 1,
            paginationInfo: { ...paginationInfo, totalElementCount: paginationInfo.totalElementCount - 1 }
          });
        } else {
          updatedNotifications[notificationIndex] = { ...originalNotification, state: newState };
          this.patchState({ data: updatedNotifications });
        }

        // optimistically update header count
        const delta = newState === NotificationState.READ ? -1 : 1;
        this.notificationsCenterStore.updateCount(delta);

        return this.notificationService.markAsRead(notificationId, newState).pipe(
          tapResponse(
            backendUpdatedNotification => {
              if (!shouldRemoveNotification) {
                updatedNotifications[notificationIndex] = backendUpdatedNotification;
                this.patchState({ data: [...updatedNotifications] });
              }
            },
            error => {
              console.error('Failed to update notification state:', error);
              updatedNotifications[notificationIndex] = originalNotification;
              this.patchState({ data: [...updatedNotifications], offset, paginationInfo });
              this.notificationsCenterStore.updateCount(-delta);
            }
          )
        );
      })
    )
  );

  private markAllAsReadInternal(showOnlyUnread: boolean): void {
    if (showOnlyUnread) {
      this.patchState({ data: [] });
    } else {
      const updatedNotifications = this.get().data.map(notification => ({ ...notification, state: NotificationState.READ }));
      this.patchState({ data: updatedNotifications });
    }

    this.notificationsCenterStore.setCount(0);
  }

  private loadNotifications(offset: number, limit: number): Observable<Result<NotificationWithUsers>> {
    const showOnlyUnread = this.get().showOnlyUnread;

    return this.notificationService.fetchNotifications(offset, limit, showOnlyUnread).pipe(
      map(({ notifications, paginationInfo }) => ({
        data: notifications,
        paginationInfo: {
          hasBottom: paginationInfo.elementsFollow,
          hasTop: false,
          totalElementCount: paginationInfo.totalElementCount
        }
      })),
      catchError((error: HttpErrorResponse) => {
        this.patchState({ error });

        return EMPTY;
      }),
      take(1)
    );
  }

  private loadComponents = this.effect((clientConfig$: Observable<NotificationClientConfig>) =>
    clientConfig$.pipe(
      switchMap(clientConfig => this.notificationComponentsLoaderService.loadNotificationComponents(clientConfig)),
      catchError(error => {
        this.patchState({ error, componentLoaded: true, data: [] });
        return EMPTY;
      }),
      tap((itemsConfig: NotificationItemsConfig) => this.patchState({ componentLoaded: true, notificationMappingInfos: itemsConfig.mappings }))
    )
  );

  private createViewModel(): Observable<NotificationsViewModel> {
    return this.select(state => ({
      ...state,
      initialLoading: state.error ? false : (!state.data?.length && state.loading) || !state.componentLoaded,
      notifications: state.error ? [] : state.data
    }));
  }
}
