import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';

import { isEmpty } from '@celum/core';

/**
 * Get a specific parameter from a route or its children, starting from root.
 *
 * @param param the name of the parameter
 * @param router the router
 */
export function getRouteParamRecursiveForRoot(param: string, router: Router, path?: string): string {
  return getRouteParamRecursiveForRoute(param, router.routerState.root.snapshot, path);
}

/**
 * Get a specific parameter from a route or its children.
 *
 * @param param the name of the parameter
 * @param route the route
 */
export function getRouteParamRecursiveForRoute(param: string, route: ActivatedRouteSnapshot, path?: string): string {
  return getAllRouteParamsRecursiveForRoute(route, path)?.[param];
}

/**
 * Collect all params from a route or its children.
 *
 * @param route the route
 */
export function getAllRouteParamsRecursiveForRoute(route: ActivatedRouteSnapshot, path?: string): Params {
  return route?.firstChild ? { ...getRouteParams(route, path), ...getAllRouteParamsRecursiveForRoute(route.firstChild, path) } : getRouteParams(route, path);
}

function getRouteParams(route: ActivatedRouteSnapshot, path: string): Params {
  return (isEmpty(path) || route?.routeConfig?.path === path) && route?.params;
}
