import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

import { AvatarConfiguration } from '../../avatar/avatar-configuration';

@Component({
  selector: 'celum-avatar-list-menu',
  templateUrl: './avatar-list-menu.html',
  styleUrls: ['./avatar-list-menu.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class AvatarListMenu {
  @HostBinding('class.celum-avatar-list-menu') public readonly hostCls: boolean = true;

  @Input() public avatarConfigs: AvatarConfiguration[] = [];
  @Input() public paginationInfo: { elementsFollow: boolean; loading: boolean };
  @Output() public readonly loadMore = new EventEmitter<void>();
}
