import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { PortalFeatureAccessService } from '@celum/portals/api';

export const contentTemplateDesignerAccessGuard: CanActivateFn = () => {
  const portalFeatures = inject(PortalFeatureAccessService);
  const router = inject(Router);

  return portalFeatures.hasContentTemplateAccess() || router.parseUrl('/dashboard/content-templates');
};
