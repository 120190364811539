/**
 * Identifies type of a given object
 */

/**
 * Each Entity has to have an EntityType. It allows to determine the concrete type of an object which is useful if a method can deal with different types
 * of Entities. Furthermore EntityTypes can be useful for defining filters, validations and more.
 */
export interface EntityType {
  /**
   * @returns A unique identifier for an type
   */
  id: string;

  inheritsFrom: Set<EntityType>;
}

/**
 * Utility: allows handling all entities, regardless of type. Also allows handling non-Entity objects.
 * @see NoEntityType
 */
export class AnyEntityType implements EntityType {
  public id = 'any';

  public inheritsFrom = new Set<EntityType>();
}

/**
 * Utility: allows handling all entities, regardless of type.
 */
export class IsAnEntityType implements EntityType {
  public id = 'isA';

  public inheritsFrom = new Set<EntityType>();
}

/**
 * Utility: allows handling all non-Entity objects.
 */
export class NoEntityType implements EntityType {
  public id = 'none';

  public inheritsFrom = new Set<EntityType>();
}

export const AnyEntity: AnyEntityType = new AnyEntityType();
export const NoEntity: NoEntityType = new NoEntityType();
export const IsEntity: IsAnEntityType = new IsAnEntityType();
