import { NgForOf } from '@angular/common';
import { Component, HostBinding, input, ViewEncapsulation } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { SimpleContextMenuItem } from '@celum/internal-components';
import { CelumDirectivesModule, CelumPipesModule } from '@celum/ng2base';

@Component({
  selector: 'celum-systembar-formats',
  templateUrl: './systembar-formats.component.html',
  styleUrl: './systembar-formats.component.scss',
  imports: [NgForOf, MatMenuModule, MatTooltipModule, CelumDirectivesModule, CelumIconModule, CelumPipesModule],
  // Needed to style the context menu items
  encapsulation: ViewEncapsulation.None
})
export class SystembarFormatsComponent {
  @HostBinding('class.celum-systembar-formats') public hostCls = true;

  public contextMenuItems = input<SimpleContextMenuItem[]>();

  protected iconConfiguration = new IconConfiguration('settings-outlined').withIconSize(34).withColor(ColorConstants.SYSTEM_WHITE);

  protected onIconClick(event: MouseEvent) {
    if (this.contextMenuItems().length === 1) {
      this.contextMenuItems()[0].onClick();
      event.stopPropagation();
    }
  }
}
