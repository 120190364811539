import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { ColorConstants, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'celum-fullscreen-dialog-header',
  templateUrl: './fullscreen-dialog-header.component.html',
  styleUrls: ['./fullscreen-dialog-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class CelumFullscreenDialogHeaderComponent implements OnInit {
  @HostBinding('class.celum-fullscreen-dialog-header') public hostCls = true;

  public closeIcon: IconConfiguration = IconConfiguration.xLarge('cancel-l', '', 30);

  @Input()
  @HostBinding('class.celum-fullscreen-dialog-header--light')
  public light: boolean;
  @Input() public showCloseButton = true;
  @Input() public title: string;

  @Output() public readonly onClose: EventEmitter<any> = new EventEmitter<any>();

  public closeClick(): void {
    this.onClose.emit(null);
  }

  public ngOnInit(): void {
    this.closeIcon.color = this.light ? ColorConstants.SYSTEM_BLACK : 'white';
  }
}
