import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CheckboxItem } from './components/checkbox-item';
import { CelumIconModule } from '../icon/icon.module';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule, MatCheckboxModule, CelumIconModule],
  declarations: [CheckboxItem],
  exports: [CheckboxItem, TranslateModule, MatCheckboxModule, MatTooltipModule]
})
export class CelumCheckboxItemModule {}
