// we cant use CelumPropertiesProvider as this would introduce a dependency on @celum/core
declare let Celum: { properties: { appProperties: { portals: CelumPortalsProperties } } };

export interface CelumPortalsProperties {
  url: string;
  apiUrl: string;
  binariesUrl: string;
  azureContainerUrl: string;
  shareInitializationTimeSeconds: number;
  shareHostName: string;
  saccProxyUrl: string;
  disableDefaultModeForImglyEditor: boolean;
  usercentrics: {
    id: string;
    scriptSrc: string;
  };
  /**
   * Actually not relevant for portals, but since it's used only in the widget, it needs to be defined here.
   * We are use "getComposedRanges" for fixing Safari inline edit in Shadow DOM, but this is still experimental.
   * We can remove this, once the API is out of draft/experimental state.
   * https://developer.mozilla.org/en-US/docs/Web/API/Selection/getComposedRanges#browser_compatibility
   */
  disableSafariQuillInlineEdit?: boolean;
  contentTemplateAccessCheckRequired?: boolean;
  contentTemplateExportUrl?: string;
  portalApprovalFeatureEnabled?: boolean;
  contentTemplateMaxSizeInPixels: number;
  timeToLiveForCopiedSectionInMinutes?: number;
}

export class PortalsProperties {
  static get properties(): CelumPortalsProperties {
    const testingFallback = {
      url: 'http://localhost:8080',
      apiUrl: `test-api`,
      binariesUrl: 'test-binaries',
      azureContainerUrl: 'azure-container',
      shareInitializationTimeSeconds: 60,
      shareHostName: 'shareHostName',
      usercentrics: { id: '', scriptSrc: '' },
      saccProxyUrl: 'sacc-proxy-url',
      disableDefaultModeForImglyEditor: false,
      portalApprovalFeatureEnabled: true,
      contentTemplateExportUrl: 'export-url',
      contentTemplateMaxSizeInPixels: 11000,
      contentTemplateAccessCheckRequired: true
    };

    try {
      return Celum?.properties.appProperties?.portals ?? testingFallback;
    } catch (err) {
      return testingFallback;
    }
  }
}
