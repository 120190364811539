import { EntityTranslator, EntityType, GraphEntity, TranslatorRegistry } from '@celum/core';

export class UiProperty<T> extends GraphEntity {
  public static readonly TYPE_KEY: string = 'uiProperty';

  public entityType = UiPropertyType.instance();

  constructor(
    id: string,
    public property: string,
    public value: T
  ) {
    super(id, UiProperty.TYPE_KEY);

    this.attributes.add('property');
    this.attributes.add('value');
  }
}

export class UiPropertyType implements EntityType {
  private static _instance: UiPropertyType = undefined;

  public id = UiProperty.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): UiPropertyType {
    return this._instance || (this._instance = new this());
  }
}

export class UiPropertyGraphEntityTranslator extends EntityTranslator<UiProperty<any>> {
  public translateToEntity(json: any): UiProperty<any> {
    return new UiProperty<string>(json.id, json.property, json.value);
  }

  public translatesToEntityWithName(): string {
    return 'graph:' + UiProperty.TYPE_KEY;
  }

  public getEntityType(): UiPropertyType {
    return UiPropertyType.instance();
  }
}

TranslatorRegistry.register(new UiPropertyGraphEntityTranslator());
