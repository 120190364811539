<div class="magic-button-page" [style.background-color]="color">
  <div class="magic-button-page_operations-wrapper">
    <div class="magic-button-page_header">
      <span>{{ title | translate }}</span>
      <div class="magic-button-page_cancel-button" (click)="closePage()">
        <celum-icon [configuration]="cancelIconConfig"></celum-icon>
      </div>
    </div>

    <div class="magic-button-page_operations-container" [class.magic-button-page_operations-container--hidden]="selectionOperations.length === 0">
      <celum-magic-button-entry
        *ngFor="let item of selectionOperations"
        class="magic-button-page_operation"
        [currentDataContext]="currentDataContext"
        [operation]="item.operation"
        (buttonClicked)="operationClicked(item)"></celum-magic-button-entry>
    </div>

    <div class="magic-button-page_operations-container" *ngIf="generalOperationsList.length !== 0">
      <celum-magic-button-entry
        *ngFor="let item of generalOperationsList"
        class="magic-button-page_operation"
        [currentDataContext]="currentDataContext"
        [operation]="item.operation"
        (buttonClicked)="operationClicked(item)"></celum-magic-button-entry>
    </div>
  </div>
</div>
