import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { ServiceTabItem } from '../../model/service-tab-item/service-tab-item';
import { SubServiceTabItem } from '../../model/sub-service-tab-item/sub-service-tab-item';

@Component({
  selector: 'systembar-service-tab-item',
  templateUrl: './service-tab-item-component.html',
  styleUrls: ['./service-tab-item-component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ServiceTabItemComponent implements OnInit, OnDestroy {
  @Input() public service: ServiceTabItem;

  @HostBinding('class.systembar-service-tab-item') public hostCls = true;

  private subscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.subscription = this.service.onSelectedValueChanged.subscribe(() => this.changeDetector.markForCheck());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public clicked(): void {
    this.service.subServices?.forEach(subService => (subService.selected = false));

    // in case there are defined context menu items adapt behaviour to:
    // 1 item - propagate click on this single item since no context menu should appear in this case
    // more items - don't propagate click at all as it will be propagated once specific context menu item is clicked
    if (this.service.contextMenuItems.length > 0) {
      if (this.service.contextMenuItems.length === 1) {
        this.service.contextMenuItems[0].onClick();
      }

      return;
    }

    this.service.selected = this.service.active;

    if (this.service.onClick) {
      this.service.onClick();
    }

    this.changeDetector.markForCheck();
  }

  public subServiceClicked(emitter: SubServiceTabItem): void {
    this.service.subServices.forEach(subService => (subService.selected = subService === emitter));
    this.service.selected = false;
    this.changeDetector.markForCheck();
  }
}
