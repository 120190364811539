import { NgModule } from '@angular/core';

import { LanguagePipe } from './language.pipe';

/**
 * Exposes the LanguagePipe.
 * @deprecated Import the `LanguagePipe` directly, it is a standalone pipe.
 */
@NgModule({
  imports: [LanguagePipe],
  exports: [LanguagePipe]
})
export class TranslationModule {}
