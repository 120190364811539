import { HttpContext, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AUTH_CONTEXT, AuthInterceptor } from '@celum/authentication';
import { ExperienceProperties } from '@celum/experience/domain';
import { ExperienceTokenService } from '@celum/experience/shared';

export class ExperienceAuthInterceptor extends AuthInterceptor {
  private tokenService = inject(ExperienceTokenService);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.clearCloudTokenCacheOnUpdatesOfPortalEntity(req);

    req = this.attachAuthContextIfNecessary(req);

    return super.intercept(req, next);
  }

  /**
   * To ensure that the cloud token cache reflects the latest state, it must be invalidated when a portal is updated.
   * This is necessary because the token contains all attached libraries.
   *
   * @param req the request to check
   */
  private clearCloudTokenCacheOnUpdatesOfPortalEntity(req: HttpRequest<any>): void {
    if (req.url.startsWith(`${ExperienceProperties.properties.apiUrl}/portals`) && req.method === 'PATCH') {
      this.tokenService.clearCloudTokenCache();
    }
  }

  /**
   * For requests to an endpoint protected by the cloud token, an appropriate authentication context must be attached.
   */
  private attachAuthContextIfNecessary(req: HttpRequest<any>): HttpRequest<any> {
    const authContext = this.tokenService.getAuthContext(req.url);

    if (authContext) {
      const context = new HttpContext().set(AUTH_CONTEXT, authContext);
      req = req.clone({ context });
    }

    return req;
  }
}
