import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, signal, ViewEncapsulation } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CelumEmptyPageModule, CelumLoadingMaskModule, EmptyPage, EmptyPageConfig } from '@celum/common-components';
import { IconDirective } from '@celum/shared/util';

import { NotificationsCenterStore } from './notifications-center.service';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { CountStringPipe } from '../pipes/count-string.pipe';

@Component({
  selector: 'systembar-notifications',
  templateUrl: './notifications-center.component.html',
  styleUrl: './notifications-center.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  //  Needed for the overlay to show up correctly
  encapsulation: ViewEncapsulation.None,
  imports: [
    OverlayModule,
    CommonModule,
    MatBadgeModule,
    MatIconModule,
    TranslateModule,
    CelumLoadingMaskModule,
    IconDirective,
    NotificationListComponent,
    CountStringPipe,
    CelumEmptyPageModule
  ],
  providers: [NotificationsCenterStore]
})
export class NotificationsCenterComponent {
  @HostBinding('class.systembar-notifications') public hostCls = true;

  protected notificationsCenterStore = inject(NotificationsCenterStore);
  protected isPanelOpen = signal<boolean>(false);
  protected readonly errorEmptyPageConfig: EmptyPageConfig = EmptyPage.noActionConfig(
    'notification-load-error',
    'notification-load-error',
    'SHARED.SYSTEM_BAR.NOTIFICATIONS.ERROR_FALLBACK_TEXT',
    'normal',
    148
  );

  protected openPanel(): void {
    this.isPanelOpen.set(!this.isPanelOpen());
    this.notificationsCenterStore.storeCompareCount();
  }
}
