import { Directionality } from '@angular/cdk/bidi';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { ZIndexHelper } from '@celum/core';

import { CelumWizardStepConfiguration } from './wizard.interface';

@Component({
  selector: 'celum-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CdkStepper,
      useExisting: CelumWizardComponent
    }
  ],
  standalone: false
})
export class CelumWizardComponent extends CdkStepper implements OnDestroy {
  @HostBinding('class') public readonly cls: string = 'celum-wizard';

  @Input() public headerText: string;
  @Input() public light: boolean;
  @Input() public showCloseButton = false;
  @Input() public stepsConfiguration: CelumWizardStepConfiguration[];

  @Output() public readonly onClose = new EventEmitter<void>();

  private zIndexHelper: ZIndexHelper;

  constructor(@Optional() dir: Directionality, changeDetectorRef: ChangeDetectorRef, elementRef?: ElementRef<HTMLElement>) {
    super(dir, changeDetectorRef, elementRef);

    // set the zIndex to a bit more than the default value (1000), to make sure the wizard is on top of everything
    // remember the current original value to reset to the original one on close
    this.zIndexHelper = new ZIndexHelper('.cdk-overlay-container', 9999);
  }

  public ngOnDestroy(): void {
    this.zIndexHelper.destroy();
    super.ngOnDestroy();
  }

  public close(): void {
    this.onClose.emit();
  }

  public indexChanged(index: number): void {
    this.selectedIndex = index;
  }

  public isSelected(step: CdkStep): boolean {
    return this.selected === step;
  }
}
