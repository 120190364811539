import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

import { setCssVariable } from '@celum/core';

import { ColorConstants } from '../../base/color-constants';
import { IconConfiguration } from '../../icon/icon-configuration';

@Component({
  selector: 'message-box',
  templateUrl: './message-box.html',
  styleUrls: ['./message-box.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MessageBox implements OnChanges {
  private static readonly MESSAGE_BOX_COLOR_VARIABLE = '--messageBoxColor';

  @HostBinding('class')
  /** Define the type of the message box (defines colors). Default is `info`. */
  @Input()
  public type: 'custom' | 'info' | 'warn' | 'error' | 'success' = 'info';
  /** Optionally define a custom icon to show. By default, the icon is set based on the {@link type} defined. */
  @Input() public icon: string;
  /** Define the text to show in the message box. */
  @Input() public text: string;
  /** Optionally define a color that will be used if {@link type} is set to custom. Can either be a hex or rgb value (no rgba) - also works if set via css variable. */
  @Input() public customColor: string;
  /** Optionally define whether the icon and text will be centered inside the message box. Default is `false`. */
  @Input() public centered = false;

  public internalIcon: IconConfiguration;

  private color: string;

  constructor(private el: ElementRef) {}

  public ngOnChanges({ type, icon, text, customColor }: SimpleChanges): void {
    if (type || icon || text || customColor) {
      this.evaluateColor();
      this.evaluateIcon();
    }
  }

  private evaluateColor(): void {
    this.color =
      this.type === 'warn'
        ? ColorConstants.WARNING
        : this.type === 'error'
          ? ColorConstants.ERROR
          : this.type === 'success'
            ? ColorConstants.SUCCESS
            : this.type === 'custom' && this.customColor
              ? this.customColor
              : ColorConstants.PRIMARY;

    setCssVariable(MessageBox.MESSAGE_BOX_COLOR_VARIABLE, this.color, this.el?.nativeElement);
  }

  private evaluateIcon(): void {
    const icon = this.icon
      ? this.icon
      : this.type === 'warn'
        ? 'attention-m'
        : this.type === 'error'
          ? 'error-m'
          : this.type === 'success'
            ? 'check-circle-m'
            : 'info-icon';

    this.internalIcon = IconConfiguration.medium(icon).withColor(this.color);
  }
}
