import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { ICON_SIZE } from '@celum/core';

@Component({
  selector: 'selection-trigger',
  imports: [CommonModule, CelumIconModule],
  templateUrl: './selection-trigger.component.html',
  styleUrls: ['./selection-trigger.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SelectionTriggerComponent implements OnInit {
  @Input() public size: 'medium' | 'large' = 'large';
  @Input() public multiSelectionMode: boolean;
  @Input() public selected: boolean;

  @HostBinding('attr.data-cy') public attrDataCy = 'selection-trigger';

  protected iconSelected: IconConfiguration;
  protected iconDeselected: IconConfiguration;

  @HostBinding('class')
  protected get hostCls(): string {
    return `selection-trigger--${this.size}`;
  }

  private get iconSize(): number {
    return this.size === 'large' ? ICON_SIZE.l : ICON_SIZE.m;
  }

  public ngOnInit(): void {
    this.iconSelected = IconConfiguration.large('selection-trigger-l').withIconSize(this.iconSize).withColor(ColorConstants.BLUE_GRAY_900);
    this.iconDeselected = IconConfiguration.large('selection-trigger-l').withIconSize(this.iconSize).withColor(ColorConstants.BLUE_GRAY_300);
  }
}
