import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'celum-progress-circle',
  templateUrl: './progress-circle.html',
  styleUrls: ['./progress-circle.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CelumProgressCircle {
  @HostBinding('class.progress-circle') public hostCls = true;

  // Can be primary(blue) accent(grey), warn(red)
  @Input() public color: ThemePalette = 'primary';
  @Input() public mode: ProgressSpinnerMode;
  @Input() public value = 0;
}
