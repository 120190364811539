import { AccountUser } from './account-user.entity';

export interface Notification {
  typeKey: 'Notification';
  id: string;
  type: string;
  state: NotificationState;
  createdOn: number;
  senderId: string;
  receiverId: string;
  data: {
    [key: string]: any;
    affectedUserIds: string[];
    url: string;
  };
}

export enum NotificationState {
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export interface NotificationWithUsers extends Notification {
  affectedUsersMap: Map<string, AccountUser>;
  sender: AccountUser;
}
