import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CelumButtonDirective } from './components/celum-button-directive';
import { CelumRoundButtonDirective } from './components/celum-round-button-directive';
import { CelumSquareButtonDirective } from './components/celum-square-button-directive';

@NgModule({
  imports: [
    // basic angular modules
    CommonModule,
    // needed material modules
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  declarations: [CelumButtonDirective, CelumSquareButtonDirective, CelumRoundButtonDirective],
  exports: [CelumButtonDirective, CelumSquareButtonDirective, CelumRoundButtonDirective, MatProgressSpinnerModule, MatButtonModule]
})
export class CelumButtonModule {}
