<celum-dialog class="standard-dialog" [hideCloseAllBtn]="hideCloseAllBtn" (onCloseAll)="closeAll($event)">
  <celum-dialog-header *ngIf="headerText">
    {{ headerText | translate }}
  </celum-dialog-header>
  <celum-dialog-error *ngIf="errors && errors.length > 0">
    <div *ngFor="let error of errors">{{ error }}</div>
  </celum-dialog-error>
  <celum-dialog-content>
    <ng-content></ng-content>
    {{ message | translate }}
  </celum-dialog-content>
  <celum-dialog-footer
    *ngIf="buttons.length > 0"
    [buttons]="buttons"
    [cancelBtnText]="cancelBtnText"
    [color]="color"
    [confirmBtnText]="confirmBtnText"
    [disableOnEnterConfirmation]="disableOnEnterConfirmation"
    [loading]="loading"
    [valid]="valid"
    (onCancel)="cancel($event)"
    (onConfirm)="confirm($event)"></celum-dialog-footer>
</celum-dialog>
