import { defer, isObservable, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

/**
 * Calls an external method safely in regards of thrown errors inside or outside of observables.
 * @param call which should be executed
 * @param callOnError function that will be executed in case an error happened in the {@link call}
 */
export function callExternalMethod<T>(call: () => T | Observable<T>, callOnError: T | Observable<T> | ((err: any) => T | Observable<T>)): Observable<T> {
  return defer(() => of(call())).pipe(
    switchMap(result => (isObservable(result) ? result : of(result))),
    catchError(error => {
      const errorCall = isAFunction(callOnError) ? callOnError(error) : callOnError;
      return isObservable(errorCall) ? errorCall : of(errorCall);
    })
  );
}

function isAFunction(thing: unknown): thing is (...args: any) => any {
  return typeof thing === 'function';
}
