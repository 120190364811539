import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'celum-dialog-error',
  templateUrl: './dialog-error.html',
  styleUrls: ['./dialog-error.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class DialogError {
  @HostBinding('class.dialog-error') public cls = true;
}
