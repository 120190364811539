import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import fastdom from 'fastdom';

import { Descriptor, ImageBackgroundSize, PreviewState, PreviewUtil } from '@celum/core';

import { FileCategory } from './preview-item.model';

@Component({
  selector: 'preview-item',
  templateUrl: './preview-item.html',
  styleUrls: ['./preview-item.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PreviewItem implements OnChanges {
  @Input() public previewUrl: string;
  @Input() public fileExtension: string;
  @Input() public fileCategory: FileCategory;
  @Input() public placeholderIcon: string;

  // custom function to override the "smart" background-size detection. The first argument contains information about the preview, the second is the evaluation
  // that was done by our defined rules
  @Input() public customBackgroundSizeFn: (preview: PreviewState, smartEvaluation: ImageBackgroundSize) => ImageBackgroundSize;

  @Output() public readonly previewTypeChanged = new EventEmitter<PreviewType>();

  public imageSrc: string;
  public sanitizedImageSrc: SafeHtml;
  public previewBackgroundSize: string;
  public shownPreview: PreviewType;

  private internalPreviewElement: ElementRef;

  private imageWidth: number;
  private imageHeight: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {}

  @ViewChild('preview', { static: false })
  protected set previewElement(previewElement: ElementRef) {
    this.internalPreviewElement = previewElement;
    this.evaluatePreview();
  }

  public ngOnChanges({ previewUrl, fileExtension, fileCategory, customBackgroundSizeFn }: SimpleChanges): void {
    if (previewUrl || fileCategory || fileExtension || customBackgroundSizeFn) {
      this.evaluatePreview();
    }
  }

  public onPreviewLoadError(): void {
    if (this.shownPreview === PreviewType.PREVIEW) {
      if (this.fileExtension) {
        this.setPreview(PreviewItem.getFiletypeIconOf(this.fileExtension), PreviewType.FILETYPE);
      } else {
        this.setPreview(this.getPlaceholder(), PreviewType.PLACEHOLDER);
      }
    } else if (this.shownPreview === PreviewType.FILETYPE) {
      this.setPreview(this.getPlaceholder(), PreviewType.PLACEHOLDER);
    }
  }

  protected onPreviewLoadSuccess(event: Event): void {
    if (this.shownPreview !== PreviewType.PREVIEW) {
      return;
    }

    const img = event.currentTarget as HTMLImageElement;

    this.imageWidth = img.naturalWidth;
    this.imageHeight = img.naturalHeight;

    this.setPreview(this.previewUrl, PreviewType.PREVIEW);
  }

  private setPreview(url: string, type: PreviewType): void {
    this.imageSrc = url;
    this.sanitizedImageSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
    this.shownPreview = type;
    this.previewTypeChanged.emit(type);

    // as evaluatePreviewSize is accessing offset heights of element, it would cause forced reflows = slow
    fastdom.measure(() => {
      this.previewBackgroundSize = this.evaluatePreviewSize(this.previewUrl, type, this.fileCategory);
      this.changeDetectorRef.markForCheck();
    });
  }

  private evaluatePreview(): void {
    // always show preview if available

    if (this.previewUrl) {
      this.preloadImageBeforeShowingPreview(PreviewType.PREVIEW);
    } else if (this.fileExtension) {
      // only show fallback (fileType-icon, placeholder), if rendition is already done
      this.setPreview(PreviewItem.getFiletypeIconOf(this.fileExtension), PreviewType.FILETYPE);
    } else if (this.fileCategory || this.fileCategory === FileCategory.UNKNOWN) {
      // unknown is 0 and therefore falsy so add separate check
      this.setPreview(this.getPlaceholder(), PreviewType.PLACEHOLDER);
    } else {
      this.sanitizedImageSrc = null; // throw away old image
    }
  }

  private preloadImageBeforeShowingPreview(type: PreviewType): void {
    this.shownPreview = type;

    // preload image only if it has not yet been loaded
    if (this.imageSrc !== this.previewUrl) {
      this.imageSrc = this.previewUrl;
      return;
    }

    this.setPreview(this.previewUrl, type);
  }

  private evaluatePreviewSize(previewUrl: string, type: PreviewType, fileCategory: FileCategory): string {
    const parent: HTMLElement = this.internalPreviewElement?.nativeElement;

    if (!parent) {
      return undefined;
    }

    const descriptorMap: FileCategoryToDescriptorMap = {
      [FileCategory.VIDEO]: Descriptor.VIDEO,
      [FileCategory.IMAGE]: Descriptor.IMAGE,
      [FileCategory.TEXT]: Descriptor.DOCUMENT,
      [FileCategory.DOCUMENT]: Descriptor.DOCUMENT,
      [FileCategory.UNKNOWN]: undefined,
      [FileCategory.AUDIO]: undefined,
      [FileCategory.MODEL3D]: undefined,
      [FileCategory.PLACEHOLDER]: undefined
    };

    const descriptor = descriptorMap[fileCategory] || Descriptor.IMAGE;

    let previewState;

    if (previewUrl) {
      previewState = new PreviewState(type !== PreviewType.PREVIEW, parent.offsetWidth, parent.offsetHeight, this.imageWidth, this.imageHeight, descriptor);
    } else {
      previewState = new PreviewState(type !== PreviewType.PREVIEW, parent.offsetWidth, parent.offsetHeight, undefined, undefined, descriptor);
    }

    const evaluatedBackgroundSize = PreviewUtil.evaluatePreviewBackgroundSize(previewState);
    return this.customBackgroundSizeFn?.(previewState, evaluatedBackgroundSize) || evaluatedBackgroundSize;
  }

  private getPlaceholder(): string {
    return this.placeholderIcon ? `icons/${this.placeholderIcon}.svg` : PreviewItem.getFiletypeIconOf('placeholder');
  }

  private static getFiletypeIconOf(extension: string): string {
    return `images/filetypes/filetype-${extension}-l.svg`;
  }
}

export enum PreviewType {
  PREVIEW = 0,
  FILETYPE = 1,
  PLACEHOLDER = 2
}

type FileCategoryToDescriptorMap = {
  [key in FileCategory]: Descriptor;
};
