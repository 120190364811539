import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IconConfiguration } from '@celum/common-components';
import { DataContext, ICON_SIZE, Operation } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { DisabledOperationService } from '../services/disabled-operation.service';

interface ViewModel {
  disabled: boolean;
  disabledText?: string;
  tooltip: boolean;
}

@Component({
  selector: 'celum-magic-button-entry',
  templateUrl: './magic-button-entry.html',
  styleUrls: ['./magic-button-entry.less'],
  providers: [DisabledOperationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class MagicButtonEntry extends ReactiveComponent implements OnChanges {
  @Input() public operation: Operation;
  @Input() public currentDataContext: DataContext;

  @Output() public readonly buttonClicked = new EventEmitter<void>();

  @HostBinding('class.magic-button-entry') public hostCls = true;
  @HostBinding('class.magic-button-entry--disabled') public disableClass = false;

  public iconConfig: IconConfiguration;
  public viewModel$: BehaviorSubject<ViewModel> = new BehaviorSubject({ disabled: false, tooltip: false } as ViewModel);

  constructor(
    private service: DisabledOperationService,
    private el: ElementRef,
    private translateService: TranslateService
  ) {
    super();

    this.service.disabledMsg$.pipe(takeUntil(this.unsubscribe$)).subscribe(disabledText => {
      this.disableClass = this.service.isDisabled;

      this.viewModel$.next({ ...this.viewModel$.value, disabledText, disabled: this.disableClass });
    });

    // if the language changes the length of the operation name changes as well, therefore also re-check whether the text is still fitting/not fitting anymore
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.checkForTextOverflow();
    });
  }

  public ngOnChanges({ operation }: SimpleChanges): void {
    if (operation?.currentValue) {
      this.iconConfig = IconConfiguration.large(this.operation.getIcon(ICON_SIZE.l), '').withColor('#ffffff');

      if (operation.currentValue.getKey() !== operation.previousValue?.getKey()) {
        this.checkForTextOverflow();
      }
    }

    if (this.currentDataContext && this.operation) {
      this.service.update(this.operation, this.currentDataContext);
    }
  }

  public onClick(): void {
    if (this.service.isDisabled) {
      return;
    }

    this.buttonClicked.emit();
  }

  private checkForTextOverflow(): void {
    // if the operation changes, wait for the next tick to check whether the operation name fits the available space, if not, show a tooltip!
    // do this only once as the available space does not change (neither on change of window size, nor on zoom change)
    requestAnimationFrame(() => {
      const nameElement = this.el.nativeElement.querySelector('.operation_name');

      const shouldShowTooltip = nameElement.clientWidth < nameElement.scrollWidth || nameElement.clientHeight < nameElement.scrollHeight;
      const showingTooltip = this.viewModel$.value.tooltip;

      if (shouldShowTooltip !== showingTooltip) {
        this.viewModel$.next({ ...this.viewModel$.value, tooltip: shouldShowTooltip });
      }
    });
  }
}
