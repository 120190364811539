import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule } from '@celum/common-components';
import { CelumPipesModule, TranslationModule } from '@celum/ng2base';

import { MagicButton } from './button/components/magic-button';
import { MagicButtonComponent } from './button/components/magic-button-component';
import { MagicButtonEntry } from './button/components/magic-button-entry';
import { MagicButtonService } from './button/services/magic-button.service';
import { MagicButtonEffects } from './button/state/magic-button-effects';
import { magicButtonReducer } from './button/state/magic-button-reducers';
import { MagicButtonPage } from './page/magic-button-page';

export const storeModule = StoreModule.forFeature('magicButton', magicButtonReducer);

export const effectsModule = EffectsModule.forFeature([MagicButtonEffects]);

/**
 * ⚠ Requires dependencies `@ngrx/store` and `@ngrx/effects`.
 */
@NgModule({
  imports: [TranslateModule, CommonModule, MatDialogModule, MatTooltipModule, storeModule, effectsModule, CelumIconModule, CelumPipesModule, TranslationModule],
  providers: [MagicButtonService],
  declarations: [MagicButtonComponent, MagicButtonPage, MagicButton, MagicButtonEntry],
  exports: [MagicButtonComponent, MagicButtonPage, MagicButton, TranslateModule]
})
export class CelumMagicButtonModule {}
