// eslint-disable-next-line max-classes-per-file
import { Customization } from './customization/customization';
import { Extension } from './extension/extension';
import { Scope } from './scope';

export abstract class RegistryEntry<C extends Extension | Customization> {
  public identifier: string;
  public implementations: Array<C> = [];
  public implementationMap: Map<string, C> = new Map<string, C>();

  public addImplementation(extension: C): void {
    if (!this.implementationMap.has(extension.getIdentifier())) {
      this.implementationMap.set(extension.getIdentifier(), extension);
      this.implementations.push(extension);
    }
  }
}

export class ExtensionEntry extends RegistryEntry<Extension> {}

export class CustomizationEntry extends RegistryEntry<Customization> {}

/**
 * This class collects and manages all extensions and customizations which are available for the application.
 */
class Registry {
  /**
   * Collection of all extension types
   */
  public static presentationModifiers: Map<string, ExtensionEntry> = new Map<string, ExtensionEntry>();

  /**
   * Collection of all customization types
   */
  public static presentationCustomizers: Map<string, CustomizationEntry> = new Map<string, CustomizationEntry>();

  private static waitingExtenders: Extension[] = [];
  private static waitingCustomizers: Customization[] = [];

  /**
   * Register {@link CelumExtensionType} - should not be called directly!
   */
  public static registerExtenderType(clazz: any, identifier: string): void {
    if (!Registry.presentationModifiers.has(identifier)) {
      const entry = new ExtensionEntry();
      entry.identifier = identifier;
      Registry.presentationModifiers.set(identifier, entry);

      const extenders = this.waitingExtenders;
      this.waitingExtenders = [];

      extenders.forEach(extender => {
        Registry.registerExtender(extender, true);
      });
    } else {
      // cannot be an error as every extension contains common-components right now :/ and therefore all extender types again...
      console.debug('Registry: duplicate class registration for ' + identifier);
    }
  }

  /**
   * Register {@link CelumCustomizationType} - should not be called directly!
   */
  public static registerCustomizerType(clazz: any, identifier: string): void {
    if (!Registry.presentationCustomizers.has(identifier)) {
      const entry = new CustomizationEntry();
      entry.identifier = identifier;
      Registry.presentationCustomizers.set(identifier, entry);

      const customizations = this.waitingCustomizers;
      this.waitingCustomizers = [];

      customizations.forEach(customizer => {
        Registry.registerCustomizer(customizer, true);
      });
    } else {
      // cannot be an error as every extension contains common-components right now :/ and therefore all customizer types again...
      console.debug('Registry: duplicate customizer registration for ' + identifier);
    }
  }

  /**
   * Register {@link Extension} - should not be called directly!
   */
  public static registerExtender(modifier: Extension, silent = false): void {
    const entry: ExtensionEntry = Registry.findExtender(modifier);

    if (entry) {
      entry.addImplementation(modifier);
    } else {
      if (!silent) {
        console.debug(
          `Registry: Cannot register extender ${modifier?.getIdentifier()}, found no base extension for it - add to waiting list. ` +
            `May work as soon as lazy loaded component providing the extension is present!`
        );
      }
      this.waitingExtenders.push(modifier);
    }
  }

  /**
   * Register {@link Customization} - should not be called directly!
   */
  public static registerCustomizer(modifier: Customization, silent = false): void {
    const entry: CustomizationEntry = Registry.findCustomizer(modifier);

    if (entry) {
      entry.addImplementation(modifier);
    } else {
      if (!silent) {
        console.debug(
          `Registry: Could not find customizer for definition for ${modifier?.getIdentifier()} - add to waiting list. ` +
            `May work as soon as lazy loaded component providing the customization endpoint is present!`
        );
      }
      this.waitingCustomizers.push(modifier);
    }
  }

  /**
   * Finds all registered extensions for a given class and a defined filter
   * @param extensionTypeIdentifier the type identifier of the extension type to get the extensions for
   * @param filter A filter
   * @param anyCriteriaMatch default false
   * @param anyEntityTypeMatch default false
   */
  public static getExtenders<E extends Extension>(
    extensionTypeIdentifier: string,
    filter: Scope,
    anyEntityTypeMatch = false,
    anyCriteriaMatch = false
  ): Array<E> {
    if (!Registry.presentationModifiers.has(extensionTypeIdentifier)) {
      return [];
    }

    const modifiers: ExtensionEntry = Registry.presentationModifiers.get(extensionTypeIdentifier);
    const matches: Array<E> = [];

    for (const mod of modifiers.implementations) {
      if (filter.within(mod.getScope(), anyEntityTypeMatch, anyCriteriaMatch)) {
        matches.push(mod as E);
      }
    }

    return matches;
  }

  /**
   * Finds the first registered customization for a given class and a defined filter
   * @param customizationTypeIdentifier the type identifier of the customization type to get the customizations for
   * @param filter A filter
   * @param anyCriteriaMatch whether any criteria should match
   * @param anyEntityTypeMatch whether any entity type should match
   */
  public static getCustomizer<C extends Customization>(
    customizationTypeIdentifier: string,
    filter: Scope,
    anyEntityTypeMatch = false,
    anyCriteriaMatch = false
  ): C {
    if (!Registry.presentationCustomizers.has(customizationTypeIdentifier)) {
      return null;
    }

    const modifiers: ExtensionEntry = Registry.presentationCustomizers.get(customizationTypeIdentifier);
    const matches: Array<Customization> = [];

    for (const mod of modifiers.implementations) {
      if (filter.within(mod.getScope(), anyEntityTypeMatch, anyCriteriaMatch)) {
        matches.push(mod);
      }
    }

    return matches.length > 0 ? (matches[0] as C) : null;
  }

  /**
   * Finds all registered customizations for a given class and a defined filter.
   * @param customizationTypeIdentifier the type identifier of the customization type to get the customizations for
   * @param filter A filter
   * @param anyCriteriaMatch whether any criteria should match
   * @param anyEntityTypeMatch whether any entity type should match
   */
  public static getAllCustomizer<C extends Customization>(
    customizationTypeIdentifier: string,
    filter: Scope,
    anyEntityTypeMatch = false,
    anyCriteriaMatch = false
  ): C[] {
    if (!Registry.presentationCustomizers.has(customizationTypeIdentifier)) {
      return null;
    }

    const modifiers: ExtensionEntry = Registry.presentationCustomizers.get(customizationTypeIdentifier);
    const matches: Array<Customization> = [];

    for (const mod of modifiers.implementations) {
      if (filter.within(mod.getScope(), anyEntityTypeMatch, anyCriteriaMatch)) {
        matches.push(mod);
      }
    }

    return matches as C[];
  }

  // ----------------- helpers

  private static findExtender(extender: Extension): ExtensionEntry {
    if (Registry.presentationModifiers.has(extender.getTypeIdentifier())) {
      return Registry.presentationModifiers.get(extender.getTypeIdentifier());
    } else {
      return null;
    }
  }

  private static findCustomizer(customizer: Customization): CustomizationEntry {
    if (Registry.presentationCustomizers.has(customizer.getTypeIdentifier())) {
      return Registry.presentationCustomizers.get(customizer.getTypeIdentifier());
    } else {
      return null;
    }
  }
}

/**
 * Why is this necessary?
 *
 * Webpack creates a scope for each bundle it creates. All code you create only exists (and is accessible) inside of this scope. This also applies to classes
 * and their static properties/functions. For example, consider extensions for Nova. If you have extensions loaded that do NOT share their dependencies with
 * Nova, they will provide their own version of all classes and functions. These classes and functions only exist inside the scope of the extension (or the
 * extensions if some of them share dependencies). Even static classes. This proxy allows to make sure that static classes are actually behaving like you would
 * expect it (and as it would be if it wouldn't be for webpack) regardless of how many different webpack bundles are loaded on the side.
 */
let reg = Registry;

if (globalThis) {
  if (!(globalThis as any).CelumRegistry) {
    (globalThis as any).CelumRegistry = Registry;
  } else {
    reg = (globalThis as any).CelumRegistry;
  }
}

export { reg as Registry };
