import { CdkStep } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

import { CelumWizardStepConfiguration } from '../wizard.interface';

@Component({
  selector: 'celum-stepper-navigation',
  templateUrl: './stepper-navigation.component.html',
  styleUrls: ['./stepper-navigation.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CelumWizardStepperNavigationComponent {
  @HostBinding('class.celum-stepper-navigation') public hostCls = true;

  @Input() public selectedIndex: number;
  @Input() public selectedStep: CdkStep;
  @Input() public steps: CdkStep[];
  @Input() public stepsConfiguration: CelumWizardStepConfiguration[];

  @Output() public readonly indexChanged = new EventEmitter<number>();

  public isDisabled(idx: number, steps: CdkStep[]): boolean {
    // next steps are active if all their previous steps are completed or optional
    return steps.slice(0, idx).some(step => !step.optional && !step.completed);
  }

  public selectStepByIndex(index: number): void {
    if (this.selectedIndex === index) {
      return;
    }
    this.selectedIndex = index;
    this.indexChanged.emit(index);
  }

  public getIconConfig(icon: string): IconConfiguration {
    return IconConfiguration.small(icon).withColor('currentColor');
  }

  public trackByFn(index: number): number {
    return index;
  }
}
