import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// VERSION property that is set by webpack, so that for each build we will have a specified translate bundle which can be cached.
// NOTE: currently only used for portals app!
// declare it here, so that others do not necessarily need to include the typings.d.ts file...
declare const VERSION: string;

/**
 * Custom Translate Loader that loads the translations from the server.
 * @param http                    HttpClient to use for the http calls
 * @param translationIdentifiers  Identifiers of the translation files to load (e.g. ['common', 'shared'],
 *                                add an empty string to the array for base translation (e.g. ['', 'common', 'shared'])
 * @param useWebpackVersion       If true, the webpack version will be used as cache buster for the translations
 * @param prefix                  Prefix for the translation urls
 * @param suffix                  Suffix for the translation urls
 */
export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private translationIdentifiers: string[] = [''],
    private useWebpackVersion = false,
    private prefix = '',
    private suffix = '.json'
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<any> {
    const additionalTranslationIdentifiers = this.translationIdentifiers.map(ident => (ident ? ident + '/' : ''));
    const allTranslationIdentifiers = [...additionalTranslationIdentifiers];

    const translationUrls = allTranslationIdentifiers.map(
      ident => `${this.prefix}/translations/${ident}${lang}${this.suffix}?t=${this.useWebpackVersion ? VERSION : Date.now()}`
    );
    const translationHttpCalls = translationUrls.map(url => this.http.get(url));

    return combineLatest(translationHttpCalls).pipe(
      catchError(err => {
        console.error(`CustomTranslationLoader: Failed to load translations with error:`, err);
        return of([{}]);
      }),
      map((translationBundle: object[]) => Object.assign({}, ...translationBundle))
    );
  }
}
