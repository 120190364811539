import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { IconConfiguration } from '../icon-configuration';

@Component({
  selector: 'icon-content',
  templateUrl: './icon-content.html',
  styleUrls: ['./icon-content.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class IconContent implements OnChanges {
  @Input() public configuration: IconConfiguration;
  @Input() public iconStyle: any;

  protected iconName: string;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(): void {
    if (!this.configuration?.icon) {
      return;
    }

    let iconUrl = this.configuration.icon;
    this.iconName = this.configuration.icon;

    if (!iconUrl.includes('/')) {
      // if there is no slash in the icon name, add default path as url
      iconUrl = `icons/${this.configuration.icon}.svg`;
    } else {
      // split by ":", if there are is more than 1 part, remove everything except the last one (this essentially means that we do not support icons with
      // "namespaces" - except if they are in the default path of the app...)
      const parts = this.configuration.icon.split(':');
      this.iconName = parts.length > 1 ? parts[parts.length - 1] : this.configuration.icon;
    }

    const sanitizedIconPath = this.sanitizer.bypassSecurityTrustResourceUrl(iconUrl);

    this.iconRegistry.getNamedSvgIcon(this.iconName).subscribe({ error: () => this.iconRegistry.addSvgIcon(this.iconName, sanitizedIconPath) });
  }
}
