import { EntityComponentClassExtension, Scope } from '@celum/core';

import { ListItem } from '../../list-basics';

export abstract class CardItemExtension<ENTITY> implements EntityComponentClassExtension<ENTITY> {
  public static type = 'CardItemExtension';

  public abstract getScope(): Scope;

  public abstract getComponentClass(entity: ENTITY): ListItem;

  public abstract getIdentifier(): string;

  public getTypeIdentifier(): string {
    return CardItemExtension.type;
  }
}
