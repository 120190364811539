import { Directive, ElementRef, EventEmitter, HostListener, Input, Optional, Output } from '@angular/core';

import { DataUtil } from '@celum/core';

import { AbstractList } from '../components/abstract-list';
import { CelumList } from '../components/celum-list/celum-list';
import { CelumSimpleList } from '../components/simple-list/simple-list';
import { CelumVirtualList } from '../components/virtual-list/virtual-list';
import { ListSelectionHandler } from '../services/list-selection-handler.service';

@Directive({
  selector: '[celum-list-select-all]',
  standalone: true
})
export class ListSelectAllDirective {
  @Input('celum-list-select-all') public target: string | HTMLElement;
  @Input() public selectionHandler: ListSelectionHandler<any>;

  @Output() public readonly selectAll = new EventEmitter<void>();

  constructor(
    private el: ElementRef,
    @Optional() private celumList?: CelumList<any>,
    @Optional() private simpleList?: CelumSimpleList,
    @Optional() private virtualList?: CelumVirtualList
  ) {}

  private get list(): AbstractList<any> {
    return this.celumList ?? this.simpleList ?? this.virtualList;
  }

  @HostListener('document:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (event.code !== 'KeyA' || !(event.ctrlKey || event.metaKey)) {
      return;
    }

    const targetDefined = !DataUtil.isEmpty(this.target);

    if (targetDefined || this.el?.nativeElement) {
      const element = targetDefined && typeof this.target === 'string' ? document.querySelector(this.target) : this.target || this.el.nativeElement;
      if (document.activeElement !== element) {
        return;
      }
    }

    event.preventDefault(); // avoid artifacts like selected text, etc.

    this.selectAll.emit();

    this.list?.selectionHandler?.selectAll();
    this.selectionHandler?.selectAll();
  }
}
