<div
  [ngClass]="
    service.active
      ? 'systembar-service-tab-item_active-item-wrapper'
      : service.interactable
        ? 'systembar-service-tab-item_not-active-item-wrapper-with-hover'
        : 'systembar-service-tab-item_not-active-item-wrapper'
  "
  [attr.data-component-id]="service.dataComponentId"
  [class.systembar-service-tab-item--disabled]="service.disabled"
  [matTooltip]="service.disabled ? ('NOVA.SYSTEM_BAR.DISABLED_SWITCH_TO_OTHER_DOMAIN' | translate: { url: service.applicationUrl }) : ''"
  (click)="clicked()">
  <!-- context menu only for contextMenuItems > 1 -->
  <div
    class="systembar-service-tab-item_hover-effect"
    [class.systembar-service-tab-item--disabled]="service.disabled"
    *ngIf="service.contextMenuItems.length > 1"
    [matMenuTriggerFor]="serviceContextMenu">
    <ng-container *ngTemplateOutlet="serviceTabItem"></ng-container>
  </div>

  <!-- no context menu for contextMenuItems <= 1 -->
  <div
    [ngClass]="service.interactable ? 'systembar-service-tab-item_hover-effect' : 'systembar-service-tab-item_flex-wrapper'"
    [class.systembar-service-tab-item--disabled]="service.disabled"
    *ngIf="service.contextMenuItems.length <= 1">
    <ng-container *ngTemplateOutlet="serviceTabItem"></ng-container>
  </div>
</div>

<ng-template #serviceTabItem>
  <div [ngClass]="service.selected ? 'systembar-service-tab-item_active-indicator' : 'systembar-service-tab-item_indicator'"></div>
  <div class="systembar-service-tab-item_info-wrapper">
    <celum-icon
      [ngClass]="!service.interactable || service.disabled ? 'systembar-service-tab-item_celum-icon' : 'systembar-service-tab-item_celum-icon-with-pointer'"
      [configuration]="service.iconConfiguration"></celum-icon>
    <span class="systembar-service-tab-item_icon-text">{{ service.text }}</span>
  </div>
</ng-template>

<ng-container *ngFor="let subService of service.subServices">
  <systembar-sub-service-tab-item [subService]="subService" (onClick)="subServiceClicked($event)"></systembar-sub-service-tab-item>
</ng-container>

<mat-menu class="celum-scrollbar" [class]="'service-tab-item_menu'" #serviceContextMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let contextMenuItem of service.contextMenuItems"
    class="service-tab-item_menu-item"
    spaceAwareTooltip
    (click)="contextMenuItem.onClick()"
    [attr.data-component-id]="contextMenuItem.dataComponentId"
    [matTooltip]="contextMenuItem.text | stripUrlProtocol">
    {{ contextMenuItem.text | stripUrlProtocol }}
  </button>
</mat-menu>
