import { Component, HostBinding, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

import { IconConfiguration } from '@celum/common-components';

export type InfoNoteStatus = 'info' | 'warn' | 'error' | 'success';

export type LabelOption = { option: string; label: string; infoIconTooltip?: string };

export type Option = string | LabelOption;
export type Options = Option[];
export type SelectBoxValue = Options | Option;

@Component({
  selector: 'celum-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.less'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SelectBoxComponent implements OnChanges {
  @HostBinding('class.celum-select-box') public cls = true;
  @HostBinding('class.celum-select-box--loading')
  @Input()
  public loading = false;

  @Input() public size: 'xs' | 'small' | 'medium' | 'large' = 'medium';
  @Input() public control: FormControl<SelectBoxValue>;
  @Input() public options: Options = [];
  @Input() public disabled = false;
  @Input() public infoNote = '';
  @Input() public infoNoteStatus: InfoNoteStatus;
  @Input() public panelClass = '';
  @Input() public placeholder = '';
  @Input() public multiple = false;
  @Input() public atLeastOne = false;
  @Input() public atLeastOneTooltip: string;
  /** Only supported if {@link multiple} is 'true'. */
  @Input() public stickyPlaceHolder = false;
  @Input() public customLabel: string;
  @Input() public customLabelOptions: object;

  @Input() public arrowDownIcon: IconConfiguration = IconConfiguration.small('arrow-down-xs', '').withIconSize(11);
  @Input() public infoIcon: IconConfiguration = IconConfiguration.small('info-icon', '').withIconSize(12);

  public ngOnChanges({ disabled, loading }: SimpleChanges): void {
    if (disabled) {
      disabled.currentValue ? this.control.disable() : this.control.enable();
    }
    if (loading) {
      loading.currentValue ? this.control.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
    }
  }

  protected trackByFn(index: number, option: Option): string | number {
    return SelectBoxComponent.getOptionValueAsString(option) || index;
  }

  protected isDisabled(option: Option, selectedOptions: SelectBoxValue, isAtLeastOne: boolean): boolean {
    return SelectBoxComponent.isOptionDisabled(option, selectedOptions, isAtLeastOne);
  }

  protected getTooltip(option: Option, selectedOptions: SelectBoxValue, tooltipText: string, isAtLeastOne: boolean): string {
    if (!tooltipText) {
      return null;
    }
    return SelectBoxComponent.isOptionDisabled(option, selectedOptions, isAtLeastOne) ? tooltipText : null;
  }

  protected getOptionLabel(option: string | LabelOption): string {
    return typeof option === 'object' ? option.label : option;
  }

  protected getOptionValue(option: string | LabelOption): string {
    return SelectBoxComponent.getOptionValueAsString(option);
  }

  protected getInfoTooltip(option: string | LabelOption): string {
    return typeof option === 'string' ? null : option.infoIconTooltip;
  }

  protected getOption(selectedOption: SelectBoxValue, options: Options): string | LabelOption {
    const firstOption: string | LabelOption = Array.isArray(selectedOption) ? selectedOption[0] : selectedOption;
    return options.find(option => SelectBoxComponent.getOptionValueAsString(option) === firstOption);
  }

  private static isOptionDisabled(option: string | LabelOption, selectedOptions: SelectBoxValue, isAtLeastOne: boolean): boolean {
    if (Array.isArray(selectedOptions) && isAtLeastOne) {
      const optionValue = SelectBoxComponent.getOptionValueAsString(option);
      return selectedOptions.length === 1 && selectedOptions.includes(optionValue);
    }
    return false;
  }

  private static getOptionValueAsString(option: string | LabelOption): string {
    return typeof option === 'object' ? option.option : option;
  }
}
