import { Registry } from '../registry';

/**
 * @deprecated
 */
export function CelumExtensionType(name: string): ClassDecorator {
  return (target: any) => {
    Registry.registerExtenderType(target, name);

    return target;
  };
}

/**
 * Register an extension type.
 * @param target      the extension type class
 * @param identifier  the type identifier for this extension type
 */
export function registerExtensionType(target: any, identifier: string): void {
  Registry.registerExtenderType(target, identifier);
}
