import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';

import { ResizeObserverHelper } from './resize-observer-helper';
import { ReactiveComponent } from '../util/reactive-component';

/**
 * A directive which registers its element with a resizeObserver and emits a `resized` event each time the size of the element changes.
 * There is only one instance of a resize observer that manages multiple elements
 */
@Directive({
  selector: '[resizeObserver]',
  standalone: false
})
export class ResizeObserverDirective extends ReactiveComponent implements OnDestroy {
  /** Specify the debounce time of the resize events as there will be a lot events during manual resizing or animations */
  @Input() public resizeDebounce = 100;

  /** Emits if the element changes in size with respect to the set resizeDebounce */
  @Output() public readonly resized: EventEmitter<ResizeObserverEntry> = new EventEmitter();

  private resizeObserverHelper: ResizeObserverHelper;

  constructor(
    element: ElementRef,
    private zone: NgZone
  ) {
    super();

    this.resizeObserverHelper = new ResizeObserverHelper(element.nativeElement, this.resizeDebounce, this.unsubscribe$);

    this.resizeObserverHelper.startListen().subscribe(entry => {
      // The resize observer callback is not patched by zone.js and is therefore not triggering change detection. Therefore we bring it manually into the zone
      // https://github.com/angular/angular/issues/45105
      this.zone.run(() => this.resized.emit(entry));
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.resizeObserverHelper.cleanup();
  }
}
