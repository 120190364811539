/**
 * Helps to determine the "relevance" of objects to each other, eg. a component which uses an extension point and extension/customization implementations.
 */

import { Context } from '../core/common/context';
import { AnyEntity, EntityType } from '../core/model/entity-type';
import { DataUtil } from '../core/util/data-util';

export class Scope {
  /**
   * Defines which types are affected. Note that there are helpers like [[EntityType.AnyEntity]]
   */
  protected entityTypes: EntityType[];

  /**
   * Defines in which surrounding context items are affected.
   */
  protected contexts: Context[] = [Context.GLOBAL];

  /*
   * additional properties, e.g. for view type filtering and so on.
   */
  protected criteria: Map<string, string | number | boolean> = new Map<string, string | number | boolean>();

  public getEntityTypes(): EntityType[] {
    return this.entityTypes;
  }

  public getContexts(): Context[] {
    return this.contexts;
  }

  public getCriteria(): Map<string, any> {
    return this.criteria;
  }

  public setCriteria(key: string, value: any): Scope {
    this.criteria.set(key, value);
    return this;
  }

  public setEntityType(type: EntityType): void {
    this.entityTypes = [type];
  }

  public setEntityTypes(types: EntityType[]): void {
    this.entityTypes = types;
  }

  /**
   * Checks if THIS matches criteria of the given scope.
   * @param restriction The scope THIS should be checked against.
   * @param anyTypeMatch If true some types of THIS must match, if false all types must match
   * @param anyCriteriaMatch If true some criteria defined for THIS must match, if false, all must match
   */
  public within(restriction: Scope, anyTypeMatch: boolean, anyCriteriaMatch: boolean): boolean {
    return (
      (restriction.getEntityTypes()[0].id === AnyEntity.id || this.matchesType(restriction, anyTypeMatch)) &&
      (restriction.getContexts()[0].getId() === Context.GLOBAL.getId() || this.matchesContext(restriction)) &&
      this.matchesCriteria(restriction, anyCriteriaMatch)
    );
  }

  private matchesType(restriction: Scope, anyTypeMatch: boolean): boolean {
    const matches = (type: EntityType) => {
      return (
        restriction.getEntityTypes().findIndex(rtype => {
          if (rtype.id === type.id) {
            return true;
          } else {
            return Scope.entityTypeInheritsFrom(type, rtype);
          }
        }) >= 0
      );
    };

    if (anyTypeMatch) {
      return this.entityTypes.some(type => matches(type));
    } else {
      return this.entityTypes.every(type => matches(type));
    }
  }

  private matchesContext(restriction: Scope): boolean {
    // todo improve logic... multiple contexts, check "inheritance" - global vs specific area
    // see OperationContext
    return this.contexts[0].getId() === restriction.getContexts()[0].getId();
  }

  private matchesCriteria(restriction: Scope, anyCriteriaMatch: boolean): boolean {
    // if both are empty -> match
    if (DataUtil.isEmpty(this.criteria) && DataUtil.isEmpty(restriction.criteria)) {
      return true;
    }

    // if one of them is empty -> no match
    if (this.oneIsEmpty(restriction)) {
      return false;
    }

    // if everything must match and size is different -> no match
    if (!anyCriteriaMatch && this.criteria.size !== restriction.criteria.size) {
      return false;
    }

    // if both are set, compare properties
    return this.hasCriteriaMatch(restriction, anyCriteriaMatch);
  }

  private hasCriteriaMatch(restriction: Scope, anyCriteriaMatch: boolean): boolean {
    // matches indicates that at least one property is NOT equal (for anymatch=false)
    let matches = true;
    // found indicates that at least one property IS equal (for anyMatch=true)
    let found = false;

    this.criteria.forEach((value, key) => {
      // we know map size is equal, therefore we WILL detect differently named properties...
      if (restriction.criteria.has(key)) {
        if (value !== restriction.criteria.get(key)) {
          matches = false;
        } else {
          found = true;
        }
      } else {
        matches = false;
      }
    });

    // -> match
    return anyCriteriaMatch ? found : matches;
  }

  private oneIsEmpty(restriction: Scope): boolean {
    return (
      (DataUtil.isEmpty(this.criteria) && !DataUtil.isEmpty(restriction.criteria)) ||
      (!DataUtil.isEmpty(this.criteria) && DataUtil.isEmpty(restriction.criteria))
    );
  }

  public static clone(scope: Scope): Scope {
    const newScope = new Scope();
    newScope.entityTypes = scope.entityTypes;
    newScope.contexts = scope.contexts;
    newScope.criteria = scope.criteria;
    return newScope;
  }

  /**
   * Utility: Create a Scope which is valid for the whole application.
   * @param entityType The affected type
   */
  public static global<TYPE extends EntityType>(entityType: TYPE): Scope {
    const filter: Scope = new Scope();
    filter.entityTypes = [entityType];
    return filter;
  }

  /**
   * Utility: Create a filter which is valid for a given surrounding context.
   * @param entityType The affected type
   * @param context The surrounding context, e.g. key of an app
   */
  public static forContext(entityType: EntityType, context: Context): Scope {
    const filter: Scope = new Scope();
    filter.entityTypes = [entityType];
    filter.contexts = [context];
    return filter;
  }

  /**
   * Utility: Create a filter which is valid for a given surrounding contexts.
   * @param entityType The affected type
   * @param contexts The surrounding contexts, e.g. key of an app
   */
  public static forContexts(entityType: EntityType, contexts: Context[]): Scope {
    const filter: Scope = new Scope();
    filter.entityTypes = [entityType];
    filter.contexts = contexts;
    return filter;
  }

  /**
   * Utility: Create a Scope which is valid for the whole application.
   * @param entityTypes The affected types
   */
  public static forEntityTypes<TYPE extends EntityType>(entityTypes: TYPE[]): Scope {
    const filter: Scope = new Scope();
    filter.entityTypes = entityTypes;
    return filter;
  }

  public static entityTypeInheritsFrom(childType: EntityType, parentType: EntityType): boolean {
    return Array.from(childType.inheritsFrom).some(type => type.id === parentType.id);
  }
}
