import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslatePipe } from '@ngx-translate/core';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';

import { CountStringPipe } from '../pipes/count-string.pipe';

@Component({
  selector: 'clm-notifications-header',
  imports: [CommonModule, MatDivider, MatSlideToggle, MatTooltipModule, TranslatePipe, CelumIconModule, CountStringPipe],
  templateUrl: './notification-list-header.component.html',
  styleUrl: './notification-list-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListHeaderComponent {
  public count = input<number>();
  public refreshCount = input<number>();

  public markAllAsRead = output<void>();
  public showOnlyUnread = output<boolean>();
  public refresh = output<void>();

  protected changeListMenuIcon = IconConfiguration.small(`refresh-s`).withColor(ColorConstants.SYSTEM_WHITE);
}
