import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { defaultIfEmpty, EMPTY, MonoTypeOperatorFunction, NEVER, pipe, takeUntil } from 'rxjs';
import { catchError } from 'rxjs/operators';

// takeUntilDestroyed does not work reliably: https://github.com/angular/angular/issues/54527
// this is a workaround copied from one of the comments from the above linked issue: https://github.com/angular/angular/issues/54527#issuecomment-2098254508
export function safeTakeUntilDestroyed<T>(destroyRef?: DestroyRef): MonoTypeOperatorFunction<T> {
  return pipe(
    takeUntil(
      NEVER.pipe(
        takeUntilDestroyed(destroyRef),
        catchError(() => EMPTY),
        defaultIfEmpty(null)
      )
    )
  );
}
