import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countString',
  standalone: true
})
export class CountStringPipe implements PipeTransform {
  public transform(count: number): string {
    if (!count || count === 0) {
      return null;
    }

    return count > 99 ? '99+' : count.toString();
  }
}
