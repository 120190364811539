import { Directive, ElementRef, forwardRef, HostListener, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const ROUNDED_FORM_CONTROL_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RoundedFormControl),
  multi: true
};

/**
 * Directive that can be applied to number form controls.
 * It will always show a rounded display value but still work with the exact value under the hood
 */
@Directive({
  selector: '[rounded-control]',
  providers: [ROUNDED_FORM_CONTROL_ACCESSOR],
  standalone: false
})
export class RoundedFormControl implements ControlValueAccessor {
  private onChange: (value: any) => void;

  constructor(public el: ElementRef) {}

  @HostListener('input')
  public inputChanged(): void {
    this.onChange(this.el.nativeElement.value);
  }

  /** this is called once -> we need to call the given function every time that the user manually changed the input */
  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  /** this is called every time that the form control value changes programmatically -> we need to update the input field */
  public writeValue(value: any): void {
    this.el.nativeElement.value = +(value ?? 0).toFixed(0);
  }

  public registerOnTouched(fn: any): void {
    // nothing to do
  }
}
