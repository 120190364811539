import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumIconModule } from '@celum/common-components';
import { CelumPipesModule, TranslationModule } from '@celum/ng2base';

import { CelumMagicButtonModule } from '../magic-button';
import { CelumMenuItem } from './components/celum-menu-item/celum-menu-item';
import { IconMenuItem } from './components/icon-menu-item/icon-menu-item';
import { OperationMenu } from './components/operation-menu/operation-menu';
import { OperationMenuItem } from './components/operation-menu-item/operation-menu-item';
import { OperationSubMenu } from './components/operation-sub-menu/operation-sub-menu';

/**
 * ⚠ Requires dependencies `@ngrx/store` and `@ngrx/effects`.
 */
@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatTooltipModule,
    OverlayModule,
    TranslationModule,
    CelumIconModule,
    CelumMagicButtonModule,
    CelumPipesModule,
    IconMenuItem
  ],
  declarations: [CelumMenuItem, OperationMenu, OperationMenuItem, OperationSubMenu],
  exports: [CelumMenuItem, IconMenuItem, MatMenuModule, CelumIconModule, OverlayModule, OperationMenu, OperationMenuItem, OperationSubMenu]
})
export class CelumMenuModule {}
