import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';

import {
  appendTokenToUrl,
  AuthContext,
  getAuthToken,
  NamedAuthToken,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceAccessTokenProvider
} from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';
import { getRouteParamRecursiveForRoot, isCloudTokenUrl } from '@celum/portals/api';

/**
 * Service to get and append tokens for Experience.
 *
 * We have to provide different tokens for different use cases. See {@link API_URLS_FOR_CLOUD_TOKEN}, {@link SPECIFIC_API_URLS_FOR_CLOUD_TOKEN}
 * and {@link API_URLS_FOR_B2C_TOKEN} for more about which endpoints require which tokens.
 *
 * In the case of the cloud token for portals, the payload is affected by library ids used in asset galleries, content templates ids used in widgets, etc.
 *
 * For Experience, it's sufficient to request a fresh token in case libraries that are attached to the portal are changed. Content Templates used in
 * widgets are not validated (yet) in Experience and therefore do not require a new token after changes.
 *
 * Example payload of the cloud token for portals:
 *
 * ```{
 *   "iss": "experience-cloud-feature",
 *   "sub": "c087cb3a-ed0c-4d3b-91fa-c6b857e64e2b",
 *   "iat": 1741943624,
 *   "exp": 1741943924,
 *   "portalId": "73cbd29e-b763-4f8a-9da9-3cc6ebd0d8ed",
 *   "orgId": "77aabbc3-5a7d-43bc-87a4-1f6e120e8a50",
 *   "target": "INTERNAL",
 *   "assetGalleryLibraryIds": [
 *     "01956664-46db-7d65-ab1d-425bd6c41634"
 *   ],
 *   "contentTemplateIds": [
 *     "ad059038-6328-4f0a-9467-d2e422ff22a6",
 *     "ad059038-6328-4f0a-9467-d2e422ff22a6",
 *     "ad059038-6328-4f0a-9467-d2e422ff22a6"
 *   ]
 * }
 */
@Injectable({ providedIn: 'root' })
export class ExperienceTokenService {
  private serviceAccessTokenProvider = inject(ServiceAccessTokenProvider);
  private interceptorConfig = inject(SERVICE_TOKEN_INTERCEPTOR_CONFIG);
  private router = inject(Router);

  /**
   * Clear the cloud token cache. A new one will be requested on the next request that needs a cloud token.
   *
   * Whenever the payload of the cloud token is affected, the cache should be cleared so that a new one is requested.
   */
  public clearCloudTokenCache(): void {
    this.serviceAccessTokenProvider.clearTokenCache('cloud');
  }

  /**
   * Gets the token for the experience service.
   *
   * @param url the url for which a token is requested
   * @param tokenLeadTimeInMilliseconds the duration in milliseconds before token expiration before it is refreshed
   */
  public getToken(
    url: string = CelumPropertiesProvider.properties.appProperties.portals.binariesUrl,
    tokenLeadTimeInMilliseconds?: number
  ): Observable<NamedAuthToken> {
    const authContext = this.getAuthContext(url);
    return getAuthToken(url, this.interceptorConfig, this.serviceAccessTokenProvider, { tokenLeadTimeInMilliseconds, authContext });
  }

  /**
   * Appends the token to the given url.
   *
   * @param url the url to append the token to
   */
  public appendToken(url: string): Observable<string> {
    return this.getToken(url).pipe(map(token => appendTokenToUrl(url, token)));
  }

  public getAuthContext(url: string): AuthContext {
    if (!isCloudTokenUrl(this.interceptorConfig, url)) {
      return null;
    }

    const portalId = getRouteParamRecursiveForRoot('portalSlug', this.router);
    const contentTemplateId = getRouteParamRecursiveForRoot('id', this.router, 'content-template/:id');

    if (!portalId && !contentTemplateId) {
      return null;
    }

    return portalId ? { portalId } : { contentTemplateId };
  }
}
