export class ExecutableAction<PARAM1 = string> {
  constructor(
    public callback: (identifier?: PARAM1) => void,
    public name?: string
  ) {}

  public execute(param1?: PARAM1): void {
    this.callback && this.callback(param1);
  }
}
