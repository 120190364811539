export class ZIndexHelper {
  private readonly originalZIndex: string;

  constructor(
    private querySelector: string,
    zIndex: number
  ) {
    const overlayContainer = document.querySelector(querySelector) as HTMLElement;

    if (!overlayContainer) {
      console.warn('ZIndexHelper: Could not find overlay container. Ignoring zIndex setting.');
      return;
    }

    this.originalZIndex = overlayContainer.style.zIndex;
    overlayContainer.style.zIndex = zIndex.toString();
  }

  public destroy(): void {
    const htmlElement = document.querySelector(this.querySelector) as HTMLElement;
    if (htmlElement) {
      htmlElement.style.zIndex = this.originalZIndex;
    }
  }
}
