<span class="celum-fullscreen-dialog-header_title">{{ title | truncate }}</span>

<div class="celum-fullscreen-dialog-header_actions">
  <ng-content></ng-content>

  <ng-container *ngIf="showCloseButton">
    <mat-divider class="celum-fullscreen-dialog-header_actions_divider" [vertical]="true"></mat-divider>

    <button
      mat-button
      celum-square-button
      class="celum-fullscreen-dialog-header_actions_close"
      data-cy="celum-fullscreen-dialog-close"
      [disableRipple]="true"
      (click)="closeClick()">
      <celum-icon [clickable]="true" [configuration]="closeIcon"></celum-icon>
    </button>
  </ng-container>
</div>
