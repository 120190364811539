<celum-list-infinite-scroll-wrapper
  class="celum-list card"
  [fromRoot]="fromRoot"
  (onContextMenu)="onContextMenu($event)"
  (onEmptySpaceClick)="clickHandler?.onEmptySpaceClick($event)"
  (onScroll)="onScroll($event)">
  <ng-content select="[top-list-item]"></ng-content>

  <ng-container *ngFor="let item of items; trackBy: trackByFn">
    <div
      *ngIf="(selectionHandler ? selectionHandler.selection$ : of([])) | async as selection"
      class="celum-list_item {{ customItemClass }} card"
      [attr.data-test-list-item-name]="item | wrapFn: getItemDataAttributeNameFn"
      long-press
      (longPress)="itemLongPress.emit(item)"
      click-tap
      (onClick)="clickHandler?.onClick($event, item)"
      (onTap)="clickHandler?.onTap($event, item)"
      (onDoubleClick)="onDoubleClick($event, item)"
      cdkDropList
      [cdkDropListDisabled]="!dragAndDropService"
      (cdkDropListDropped)="dragAndDropService?.drop($event)"
      [class.selected]="selection | wrapFn: isSelected : item"
      [style.background]="item | wrapFn: getItemBackground"
      (contextmenu)="onContextMenu($event, item)">
      <div
        #listItem
        [attr.data-item-id]="item.id"
        cdkDrag
        (cdkDragStarted)="draggingStarted($event, item)"
        (cdkDragEnded)="dragAndDropService?.dragReleased()"
        (cdkDragMoved)="dragAndDropService?.dragMove($event)"
        celum-on-destroy
        (destroyed)="dragAndDropService?.dragReleased()">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item, list: this, selected: selection | wrapFn: isSelected : item }"></ng-container>

        <ng-container *ngIf="!!dragAndDropService">
          <div *cdkDragPreview>
            <ng-container
              *ngTemplateOutlet="
                dragAndDropService?.getTemplateForPreview();
                context: { dropInfo$: dragAndDropService.dropInfo$, selected: dragAndDropService.draggedElements }
              "></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div *ngIf="hasMoreBottom" class="celum-list_spinner">
    <ng-content select="[loading-spinner]"></ng-content>
  </div>
</celum-list-infinite-scroll-wrapper>
