import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LogLevel } from '@azure/msal-common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideTranslateService, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { enableMapSet } from 'immer';

import {
  ApiConfiguration,
  AuthInterceptorConfig,
  CelumAuthModule,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceTokenInterceptorConfig,
  ServiceTokenRequestDto,
  tenantHrefFactory,
  TenantService
} from '@celum/authentication';
import { CelumSnackbarModule } from '@celum/common-components';
import { ExperienceProperties } from '@celum/experience/domain';
import { provideReuseStrategy } from '@celum/experience/shared';
import { CelumSnackbarListModule, SnackbarList } from '@celum/internal-components';
import { LibrariesProperties } from '@celum/libraries/domain';
import { CelumEntityModule, CustomTranslateLoader, paginationTranslatorFactory, providePaginationTranslator } from '@celum/ng2base';
import { createB2CTokenApiConfigForPortals, createCloudTokenApiConfigForPortals, EXP_TRANSLATE_SERVICE, PortalsProperties } from '@celum/portals/api';
import { provideApplicationInsights } from '@celum/shared/domain';
import { SystembarComponent } from '@celum/shared/ui';
import { AppErrorHandler } from '@celum/shared/util';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { ExperienceAuthInterceptor } from './experience-auth.interceptor';

enableMapSet();

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([]),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    MatDialogModule,

    CelumAuthModule.forRoot({
      logLevel: LogLevel.Error
    }),
    CelumEntityModule,
    CelumSnackbarListModule,
    CelumSnackbarModule,
    SystembarComponent
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient]
      }
    }),
    {
      provide: EXP_TRANSLATE_SERVICE,
      useClass: TranslateService
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { disableTooltipInteractivity: true, showDelay: 500 }
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExperienceAuthInterceptor,
      multi: true
    },
    {
      provide: SERVICE_TOKEN_INTERCEPTOR_CONFIG,
      useFactory: serviceTokenInterceptorConfigFactory,
      deps: [TenantService]
    },
    {
      provide: APP_BASE_HREF,
      deps: [TenantService],
      useFactory: tenantHrefFactory
    },
    provideApplicationInsights(),
    providePaginationTranslator(paginationTranslatorFactory('paginationInformation', { hasBottomKey: 'elementsFollow' })),
    provideReuseStrategy(['content-template/:id/designer']) // do not re-use the content template designer if the param (aka id) changes
  ],
  bootstrap: [AppComponent, SnackbarList]
})
export class AppModule {}

function serviceTokenInterceptorConfigFactory(tenantService: TenantService): ServiceTokenInterceptorConfig<AuthInterceptorConfig> {
  const getConfiguration = () => {
    const tenantId = tenantService.getCurrentTenantId();

    const sporServiceTokenRequest: ServiceTokenRequestDto = {
      clientId: 'spor',
      orgId: tenantId
    };

    const apiConfigurationForB2CToken = createB2CTokenApiConfigForPortals(tenantId);
    apiConfigurationForB2CToken.apiUrls.push(ExperienceProperties.properties.apiUrl); // our main experience backend api
    apiConfigurationForB2CToken.apiUrls.push(`${PortalsProperties.properties.apiUrl}/portals-search`); // currently used in experience only (for fetching the portals on the dashboard)

    const apiConfigurations: ApiConfiguration[] = [
      {
        apiUrls: [ExperienceProperties.properties.apiUrl + '/statistics'],
        serviceTokenRequestDto: sporServiceTokenRequest,
        tenantIdHeader: tenantId
      },
      apiConfigurationForB2CToken,
      createCloudTokenApiConfigForPortals(tenantId),
      {
        apiUrls: [LibrariesProperties.properties.apiUrl],
        serviceTokenRequestDto: {
          clientId: 'slib',
          orgId: tenantId
        },
        tenantIdHeader: tenantId
      }
    ];
    return {
      apiConfigurations
    };
  };

  return {
    getInterceptorConfiguration: () => getConfiguration()
  };
}
