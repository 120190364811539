<celum-icon
  class="celum-systembar-formats_icon"
  [configuration]="iconConfiguration"
  [matMenuTriggerFor]="contextMenuItems()?.length > 1 && serviceContextMenu"
  (click)="onIconClick($event)"></celum-icon>

<mat-menu #serviceContextMenu="matMenu" class="celum-scrollbar celum-systembar-formats_menu" yPosition="above">
  <button
    *ngFor="let contextMenuItem of contextMenuItems()"
    class="celum-systembar-formats_menu-item"
    mat-menu-item
    spaceAwareTooltip
    [attr.data-component-id]="contextMenuItem.dataComponentId"
    [matTooltip]="contextMenuItem.text | stripUrlProtocol"
    (click)="contextMenuItem.onClick()">
    {{ contextMenuItem.text | stripUrlProtocol }}
  </button>
</mat-menu>
