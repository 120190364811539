import { Context } from '../../../core/common/context';
import { EntityType } from '../../../core/model/entity-type';
import { Scope } from '../../../extensions/scope';

export class CustomizerScope extends Scope {
  private static OPERATION_KEY_PREFIX = 'operation_';

  constructor() {
    super();
  }

  public static forOperation(entityType: EntityType, context: Context, operationKey: string): CustomizerScope {
    const scope: CustomizerScope = new CustomizerScope();
    scope.entityTypes = [entityType];
    scope.contexts = [context];
    scope.criteria.set(CustomizerScope.OPERATION_KEY_PREFIX + operationKey, true);
    return scope;
  }

  public static forOperations(entityType: EntityType[], context: Context[], operationKeys: string[]): CustomizerScope {
    const scope: CustomizerScope = new CustomizerScope();
    scope.entityTypes = entityType;
    scope.contexts = context;
    operationKeys.forEach(operation => {
      scope.criteria.set(CustomizerScope.OPERATION_KEY_PREFIX + operation, true);
    });
    return scope;
  }
}
