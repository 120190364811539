<div class="notification-list-header_title">
  <span>{{ 'SHARED.SYSTEM_BAR.NOTIFICATIONS.TITLE' | translate }}</span>
  @if (count() > 0) {
    <span
      class="notification-list-header_indicator"
      [class.notification-list-header_indicator--expanded]="refreshCount() > 0"
      [matTooltip]="'SHARED.SYSTEM_BAR.NOTIFICATIONS.REFRESH_TOOLTIP.' + (refreshCount() === 1 ? 'SINGULAR' : 'PLURAL') | translate: { count: refreshCount() }"
      [matTooltipDisabled]="refreshCount() < 1"
      (click)="refresh.emit()">
      {{ count() | countString }}
      @if (refreshCount() > 0) {
        <celum-icon [configuration]="changeListMenuIcon"></celum-icon>
      }
    </span>
  }
</div>

<div class="notification-list-header_filter">
  <mat-slide-toggle class="notification-list-header_show-only-unread" [labelPosition]="'before'" (change)="showOnlyUnread.emit($event.checked)">
    {{ 'SHARED.SYSTEM_BAR.NOTIFICATIONS.SHOW_ONLY_UNREAD' | translate }}
  </mat-slide-toggle>
</div>

<mat-divider class="notification-list-header_divider"></mat-divider>

<div class="notification-list-header_mark-all-as-read" [class.notification-list-header_mark-all-as-read--disabled]="!count()">
  <span (click)="markAllAsRead.emit()">{{ 'SHARED.SYSTEM_BAR.NOTIFICATIONS.MARK_ALL_AS_READ' | translate }}</span>
</div>
