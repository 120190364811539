<div
  class="systembar-notifications_notifications-wrapper"
  [class.systembar-notifications_notifications-wrapper--active]="isPanelOpen()"
  #panelTrigger="cdkOverlayOrigin"
  cdkOverlayOrigin
  (click)="openPanel()">
  <div class="systembar-notifications_notifications-logo">
    <mat-icon #notificationIcon [icon]="'notification-outlined'" [iconSizeRem]="2.4" [matBadge]="notificationsCenterStore.count() | countString"></mat-icon>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOpen]="isPanelOpen()"
  [cdkConnectedOverlayOrigin]="panelTrigger"
  [cdkConnectedOverlayPanelClass]="'systembar-notifications_notifications-panel'"
  (detach)="isPanelOpen.set(false)"
  (overlayOutsideClick)="isPanelOpen.set(false)">
  @if (notificationsCenterStore.error()) {
    <empty-page class="systembar-notifications_empty-page" [config]="errorEmptyPageConfig"></empty-page>
  } @else if (notificationsCenterStore.clientConfiguration()) {
    <clm-notification-list
      [notificationConfigs]="notificationsCenterStore.clientConfiguration()"
      [notificationCount]="notificationsCenterStore.count()"
      [notificationRefreshCount]="notificationsCenterStore.refreshCount()"></clm-notification-list>
  } @else {
    <loading-mask></loading-mask>
  }
</ng-template>
