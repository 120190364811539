import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';

import { ReactiveComponent } from '@celum/ng2base';

import { ClickHandlerService } from '../services/click-handler.service';
import { ListSelectionHandler } from '../services/list-selection-handler.service';

@Directive()
export abstract class AbstractList<E> extends ReactiveComponent implements OnChanges, AfterViewInit {
  @Input() public items: E[];
  @Input() public fetchingNextBatch = false;
  // define whether the list has actually more elements than shown atm (applies to elements at the END of the list)
  @Input() public hasMoreBottom = false;
  // define whether the list has actually more elements than shown atm (applies to elements at the TOP of the list)
  @Input() public hasMoreTop = false;

  @Input() public clearSelectionOnEmptySpaceClick = true;
  // @Input() public sortInfo: SortValue;
  @Input() public selectionHandler: ListSelectionHandler<E>;
  /** Stop event propagation after clicking on an element (this might be useful if this element is used in a context menu - prevents closing of the menu on clicking! */
  @Input() public stopClickEventPropagation = false;

  // for (infinite) scrolling
  @Input() public scrollContainer: any = null; // either HTML element or CSS selector - if skipped, document will be used (-> therefore nothing used at all!)

  @Output() public readonly itemDoubleClick = new EventEmitter<E>();
  @Output() public readonly openContextMenu = new EventEmitter<MouseEvent>();
  // emits when list is scrolled to the top (false) or the bottom (true)
  @Output() public readonly requestNextPage = new EventEmitter<boolean>();

  @ViewChildren('listItem', { read: ElementRef }) protected listItems: QueryList<ElementRef>;

  protected clickHandler: ClickHandlerService<E>;

  protected constructor(protected changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    changes.items && this.selectionHandler?.setItems(this.items);
  }

  public ngAfterViewInit(): void {
    if (this.selectionHandler) {
      this.selectionHandler.setConnectedList(this);
      this.clickHandler =
        this.clickHandler ??
        new ClickHandlerService<E>(this.selectionHandler, this.stopClickEventPropagation, this.clearSelectionOnEmptySpaceClick, this.itemDoubleClick);
    }
  }

  public scrollIntoView(ids: string[]): void {
    if (!this.listItems) {
      return;
    }

    // if there are multiple ones... just take the first one
    const item = this.listItems.find(listItem => {
      const itemId = (listItem.nativeElement as HTMLElement).attributes.getNamedItem('data-item-id').value;
      return ids.includes(itemId);
    });

    item?.nativeElement.scrollIntoView();
  }

  protected onDoubleClick(event: Event, item: E): void {
    event.stopPropagation();
    this.itemDoubleClick.emit(item);
  }

  protected onContextMenu(event: MouseEvent, item?: E): void {
    this.updateSelection(item);

    event.stopPropagation();
    event.preventDefault();
    this.openContextMenu.emit(event);
  }

  protected onScroll(bottom: boolean): void {
    if (!this.fetchingNextBatch) {
      this.requestNextPage.emit(bottom);
    }
  }

  private updateSelection(item: E): void {
    if (!this.selectionHandler) {
      return;
    }

    if (item) {
      // do not update selection if a right click was made on an item which is currently part of multiselection
      const clickedItemIsFromMultiSelection = this.selectionHandler.isActiveMultiSelection() && this.selectionHandler.isInSelection(item);

      if (!clickedItemIsFromMultiSelection) {
        this.selectionHandler.setSelection([item]);
      }
    } else {
      this.selectionHandler.clearSelection();
    }
  }
}
