import { CelumPropertiesProvider } from '@celum/core';

/**
 * Use this function to conditionally load azure application insights if its instrumented.
 * This has to be done before bootstrapping your application.
 * Example:
 * ```
 * import { prepareApplicationInsights } from '@celum/shared/shared';
 * prepareApplicationInsights()
 *    .then(() => platformBrowserDynamic().bootstrapModule(AppModule))
 *    .catch(err => console.error(err));
 *
 * The reason why it has to be done before bootstrapping is because there is no other way of providing a service lazily otherwise.
 * We can't use APP_INITIALIZER because it depends on the ErrorHandler which already injects the TrackingService.
 */
export async function prepareApplicationInsights(): Promise<any> {
  if (!CelumPropertiesProvider.properties.applicationInsights?.instrumentationKey) {
    return Promise.resolve();
  }

  const module = await import('@celum/shared/util/tracking');
  const service = module.ApplicationInsightsService;
  return ((window as any).applicationInsights = service);
}
