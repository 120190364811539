import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';

import { CelumWizardStepperNavigationComponent } from './stepper-navigation/stepper-navigation.component';
import { CelumWizardActionsComponent } from './wizard-actions/wizard-actions.component';
import { CelumWizardComponent } from './wizard.component';
import { CelumCollapseButtonModule } from '../collapse-button/collapse-button.module';
import { CelumFullscreenDialogModule } from '../fullscreen-dialog/fullscreen-dialog.module';

@NgModule({
  declarations: [CelumWizardComponent, CelumWizardStepperNavigationComponent],
  imports: [
    CommonModule,
    CdkStepperModule,
    MatButtonModule,
    MatDividerModule,
    MatSidenavModule,
    MatTooltipModule,
    TranslateModule,

    CelumCollapseButtonModule,
    CelumIconModule,
    CelumPipesModule,

    CelumFullscreenDialogModule,
    CelumWizardActionsComponent
  ],
  exports: [CelumWizardComponent, CelumWizardActionsComponent]
})
export class CelumWizardModule {}
