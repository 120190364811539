<mat-sidenav-container>
  <mat-sidenav #drawer mode="side" opened [disableClose]="true">
    <div
      *ngFor="let stepConfiguration of stepsConfiguration; let i = index; trackBy: trackByFn"
      class="celum-stepper-navigation_step"
      [attr.data-cy]="'step-' + i"
      [class.celum-stepper-navigation_step--disabled]="i | wrapFn: isDisabled : steps"
      [class.celum-stepper-navigation_step--selected]="selectedIndex === i"
      (click)="selectStepByIndex(i)">
      <span [class.celum-stepper-navigation_step_icon--selected]="selectedIndex === i" class="celum-stepper-navigation_step_icon">
        <celum-icon [configuration]="stepConfiguration.icon | wrapFn: getIconConfig"></celum-icon>
      </span>
      <span [innerHTML]="stepConfiguration.label | translate | sanitized"></span>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <celum-collapse-button
      class="celum-stepper-navigation_toggle-button"
      data-collapse-sidebar-button
      [attr.data-test-sidebar-open]="drawer.opened"
      [collapsed]="!drawer.opened"
      [matTooltip]="(drawer.opened ? 'WIZARD.CLOSE_PANEL' : 'WIZARD.OPEN_PANEL') | translate"
      (changed)="drawer.toggle()"></celum-collapse-button>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
