import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { first } from 'rxjs';

import { AVATAR_SIZE, CelumAvatarModule } from '@celum/common-components';
import { CelumSystembarModule, SimpleContextMenuItem } from '@celum/internal-components';

import { SystembarFormatsComponent } from './systembar-formats/systembar-formats.component';
import { SystembarService } from './systembar.service';
import { NotificationsCenterComponent } from '../notifications/notifications-center/notifications-center.component';
import { UserAvatarComponent } from '../people/user-avatar/user-avatar.component';

export interface SystembarConfiguration {
  /**
   * Function to provide additional {@link SimpleContextMenuItem}s for the systembar. The default items are passed as parameter and can be manipulated
   * (e.g. replaced, extended, etc.) by the function.
   *
   * @param defaultItems - default items of the systembar
   */
  itemProvider?: (defaultItems: SimpleContextMenuItem[]) => SimpleContextMenuItem[];

  /**
   * Function to provide additional {@link SimpleContextMenuItem}s for the systembar's help context menu. The default items are passed as parameter
   * and can be manipulated (e.g. replaced, extended, etc.) by the function.
   *
   * @param defaultItems - default items of the systembar's help context menu
   */
  helpItemProvider?: (defaultItems: SimpleContextMenuItem[]) => SimpleContextMenuItem[];

  /**
   * Function which is called when the tenant is switched
   */
  onTenantSwitch?: () => void;
}

@Component({
  imports: [CommonModule, CelumAvatarModule, CelumSystembarModule, NotificationsCenterComponent, SystembarFormatsComponent, UserAvatarComponent],
  selector: 'shared-systembar',
  templateUrl: './systembar.component.html',
  styleUrls: ['./systembar.component.scss'],
  providers: [
    SystembarService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SystembarComponent implements OnChanges {
  @Input() public configuration: SystembarConfiguration;
  @Output() public readonly loadingReady = new EventEmitter<boolean>();

  protected AVATAR_SIZE = AVATAR_SIZE;

  constructor(protected service: SystembarService) {
    service.loadingReady.pipe(first()).subscribe(() => {
      this.loadingReady.emit(true);
    });
  }

  public ngOnChanges({ configuration }: SimpleChanges): void {
    if (configuration) {
      this.service.init(this.configuration);
    }
  }
}
