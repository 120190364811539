import { of } from 'rxjs';

import { AVATAR_SIZE, AvatarConfigBuilder } from '@celum/common-components';
import {
  Asset,
  AssetFilter,
  AssetMetadataField,
  LibraryDto,
  LibraryFormat,
  LibraryStatus,
  LibrarySyncDirection,
  LibrarySyncTrigger
} from '@celum/libraries/domain';

import { Library, LIBRARY } from '../src/lib/library.entity';
import { USAGE_STATISTICS } from '../src/lib/usage-statistics.entity';

export class LibraryTestData {
  public static mockAssetData: Asset[] = [
    {
      filePreviewUrl: of('https://test-broken-link.jpg'),
      fileExtension: 'jpg',
      fileName: 'File name',
      canDownloadOriginal: true
    },
    {
      filePreviewUrl: of('https://i.picsum.photos/id/127/500/300.jpg?hmac=ZU7zfLSN8SPdo4-0IhbVf7sMBNf29S9WDiAp5GEnUHc'),
      fileExtension: 'png',
      fileName: 'File name',
      canDownloadOriginal: true
    },
    {
      filePreviewUrl: of('https://i.picsum.photos/id/610/500/300.jpg?hmac=6UwnxuGfKaJfGOw4JCw3kQVtL8_1Fh8PLvyjtiiOWSo'),
      fileExtension: 'png',
      fileName: 'File name',
      canDownloadOriginal: true
    },
    {
      filePreviewUrl: of('https://test-broken-link.png'),
      fileExtension: 'png',
      fileName: 'File name',
      canDownloadOriginal: true
    },
    {
      filePreviewUrl: of('https://i.picsum.photos/id/1057/500/300.jpg?hmac=xLJZSUbGTc2_qSO733hAiFaXiyGRa9sljJUJqFMeC5E'),
      fileExtension: 'png',
      fileName: 'File name',
      canDownloadOriginal: true
    },
    {
      filePreviewUrl: of('https://i.picsum.photos/id/280/500/300.jpg?hmac=WW6HbIlx7m8UG5NBIxkV4Ntc7dLfK6_tYfM16VKUCKc'),
      fileExtension: 'png',
      fileName: 'File name',
      canDownloadOriginal: true
    }
  ];

  public static library(id: string, overrides?: Partial<Library>): Library {
    const library: Library = {
      id,
      typeKey: LIBRARY,
      name: `${id} Winter Title Winter Event Winter Title Winter`,
      hidden: false,
      readonly: true,
      status: LibraryStatus.ACTIVE,
      modifiedAt: new Date(),
      createdAt: new Date(),
      syncDefinition: {
        syncDirection: LibrarySyncDirection.UP,
        syncSource: {
          downSyncFilter: {
            id: '1',
            name:
              'Winter Event Winter Event Winter Event Winter Event Winter EventWinter Event Winter Event Winter' +
              ' Event Winter Event Winter EventWinter Event Winter Event Winter Event Winter Event Winter Event'
          },
          assetRepositoryId: '',
          infoFieldMapping: [] as any[]
        },
        syncTrigger: LibrarySyncTrigger.AUTOMATIC
      },
      avatars: [new AvatarConfigBuilder().withImage('images/libraries-avatar.png').withTitle('John Doe').withSize(AVATAR_SIZE.m).build()],
      createdById: '1',
      createdBy: null,
      formats: [],
      error: null,
      usageStatistics: () => of({ id, typeKey: USAGE_STATISTICS, active: 1, inactive: 1, published: 1 })
    };

    return { ...library, ...overrides };
  }

  public static libraryDto(id: string, overrides?: Partial<LibraryDto>): LibraryDto {
    return {
      id,
      name: `${id} Winter Title Winter Event Winter Title Winter`,
      hidden: false,
      readonly: true,
      status: LibraryStatus.ACTIVE,
      modifiedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      syncDefinition: {
        syncDirection: LibrarySyncDirection.UP,
        syncSource: {
          downSyncFilter: {
            externalId: '1',
            name:
              'Winter Event Winter Event Winter Event Winter Event Winter EventWinter Event Winter Event Winter' +
              ' Event Winter Event Winter EventWinter Event Winter Event Winter Event Winter Event Winter Event'
          },
          assetRepositoryId: '',
          infoFieldMapping: [] as any[]
        },
        syncTrigger: LibrarySyncTrigger.AUTOMATIC
      },
      avatars: [new AvatarConfigBuilder().withImage('images/libraries-avatar.png').withTitle('John Doe').withSize(AVATAR_SIZE.m).build()],
      createdBy: '0',
      formats: [],
      error: null,
      ...overrides
    };
  }

  public static libraryDtos(nrOfLibraries: number, startAtId = 1, overrides?: Partial<LibraryDto>): LibraryDto[] {
    return Array.from(Array(nrOfLibraries))
      .map((_, idx) => LibraryTestData.libraryDto(String(idx + startAtId), overrides))
      .sort((a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime());
  }

  public static libraries(nrOfLibraries: number, startAtId = 1, overrides?: Partial<Library>): Library[] {
    return Array.from(Array(nrOfLibraries))
      .map((_, idx) => LibraryTestData.library(String(idx + startAtId), overrides))
      .sort((a, b) => b.modifiedAt.getTime() - a.modifiedAt.getTime());
  }

  public static filters(nrOfFilters: number, startAtId = 1): AssetFilter[] {
    const filters = Array.from(Array(nrOfFilters)).map((_, idx) => ({
      id: `${idx + startAtId}`,
      name: `Name of filter ${idx + startAtId}`
    }));
    filters.push({
      id: '123',
      name: 'Name of filter very long name very long name'
    });
    return filters;
  }

  public static asset(index: number): Asset {
    const asset = this.mockAssetData[index % 6];
    return {
      ...asset,
      fileName: `File Name ${index}`
    };
  }

  public static assets(noOfAssets: number, startAtId = 1): Asset[] {
    return Array.from(Array(noOfAssets)).map((_, idx) => LibraryTestData.asset(idx + startAtId));
  }

  public static metadatum(id: string, overrides?: Partial<AssetMetadataField>): AssetMetadataField {
    return {
      id,
      name: `Name of metadata ${id}`,
      type: 'LOCALIZED_TEXT',
      labels: {
        en: `english metadata ${id}`,
        de: `Deutsche Meta-Datei ${id}`
      },
      ...overrides
    };
  }

  public static metadata(nrOfMetadata: number, startAtId = 1): AssetMetadataField[] {
    return Array.from(Array(nrOfMetadata)).map((_, idx) => LibraryTestData.metadatum(`${idx + startAtId}`));
  }

  public static format(): LibraryFormat[] {
    return [
      {
        id: 'original',
        name: {
          en: 'Original',
          de: 'Original'
        },
        description: {
          en: 'something',
          de: 'irgendwas'
        },
        icon: undefined
      },
      {
        id: 'small',
        name: {
          en: 'Small',
          de: 'Klein'
        },
        description: {
          en: 'something',
          de: 'irgendwas'
        },
        icon: undefined
      },
      {
        id: 'medium',
        name: {
          en: 'Medium',
          de: 'Mittel'
        },
        description: {
          en: 'something',
          de: 'irgendwas'
        },
        icon: undefined
      },
      {
        id: 'large',
        name: {
          en: 'Large',
          de: 'Groß'
        },
        description: {
          en: 'something',
          de: 'irgendwas'
        },
        icon: undefined
      }
    ];
  }
}
