import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHTML',
  pure: true,
  standalone: false
})
export class StripHtmlPipe implements PipeTransform {
  public transform(text: string): string {
    if (!text) {
      return '';
    }
    const doc = new DOMParser().parseFromString(text, 'text/html');
    return doc.body.textContent ?? '';
  }
}
