import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CelumProgressCircle } from './component/progress-circle';
import { CelumIconModule } from '../icon/icon.module';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, CelumIconModule],
  declarations: [CelumProgressCircle],
  exports: [CelumProgressCircle, MatProgressSpinnerModule]
})
export class CelumProgressCircleModule {}
