import { fromEvent, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { InOrExcluded } from '../../shared';

let prefix = '';
let addEventListener: any;

// detect event model
if (window.addEventListener) {
  addEventListener = 'addEventListener';
} else {
  addEventListener = 'attachEvent';
  prefix = 'on';
}

// detect available wheel event
const support =
  'onwheel' in document.createElement('div')
    ? 'wheel' // Modern browsers support 'wheel'
    : (document as any).onmousewheel !== undefined
      ? 'mousewheel' // Webkit and IE support at least 'mousewheel'
      : 'DOMMouseScroll'; // let's assume that remaining browsers are older Firefox

export class EventHelper {
  public static readonly MIN_SWIPE_DISTANCE = 50;

  private static scrollHandlerMap: Map<any, Map<string, () => any>> = new Map<any, Map<string, () => any>>();

  public static removeEventListener(elem: any, callback: (...args: any[]) => any): void {
    if (this.scrollHandlerMap.has(elem) && this.scrollHandlerMap.get(elem).has(prefix + support)) {
      const functionMap = this.scrollHandlerMap.get(elem);

      elem.removeEventListener(prefix + support, functionMap.get(prefix + support));

      // clean up...
      functionMap.delete(prefix + support);

      if (functionMap.size === 0) {
        this.scrollHandlerMap.delete(elem);
      }

      return;
    }

    // if not present in the map, just try to remove the passed callback fn
    elem.removeEventListener(prefix + support, callback);
  }

  public static addWheelListener(elem: any, callback: (...args: any[]) => any, useCapture: boolean): void {
    this.addWheelListenerInternal(elem, support, callback, useCapture);
  }

  /**
   * Detect swipes on given element and returns swipe distance at touchend
   */
  public static swipe(element: HTMLElement): Observable<number> {
    const touchstart$ = EventHelper.fromUserEvent<TouchEvent>(element, 'touchstart', true);
    const touchend$ = EventHelper.fromUserEvent<TouchEvent>(element, 'touchend', true);

    return touchend$.pipe(
      withLatestFrom(touchstart$),
      map(([end, start]) => this.touchXCoordinate(end) - this.touchXCoordinate(start)),
      filter((distance: number) => Math.abs(distance) >= this.MIN_SWIPE_DISTANCE)
    );
  }

  /**
   * Registers an event handler for specific keyCodes
   * @param keyCodes which should be listened on
   * @param options which modify the function behaviour
   * @returns emits the keycode of pressed key
   */
  public static keyboardEvent(keyCodes: number[], options?: GetKeyEventsOptions): Observable<number> {
    return EventHelper.fromUserEvent<KeyboardEvent>(document, 'keydown').pipe(
      filter(event => EventHelper.isElementIncluded(event.target as HTMLElement, options?.allowedOrExcludedSelector)),
      filter(event => !options?.disableModifierKeys || (!event.ctrlKey && !event.shiftKey && !event.metaKey && !event.altKey)),
      map(event => event.keyCode),
      filter(code => keyCodes.indexOf(code) > -1)
    );
  }

  private static addWheelListenerInternal(elem: any, eventName: string, callback: (...args: any[]) => any, useCapture: boolean): void {
    let handler: any;

    if (support === 'wheel') {
      handler = callback;
    } else {
      // remember the created function in order to be able to remove it afterwards again
      const handlerFn = EventHelper.handleScroll.bind(this, callback);

      if (!this.scrollHandlerMap.has(elem)) {
        this.scrollHandlerMap.set(elem, new Map<string, () => any>());
      }

      const elemHandlerMap = this.scrollHandlerMap.get(elem);

      elemHandlerMap.set(prefix + eventName, handlerFn);

      handler = handlerFn;
    }

    elem[addEventListener](prefix + eventName, handler, useCapture || false);
  }

  private static handleScroll(callback: (...args: any[]) => any, originalEvent: any): void {
    !originalEvent && (originalEvent = window.event);

    // create a normalized event object
    const event = {
      // keep a ref to the original event object
      originalEvent,
      target: originalEvent.target || originalEvent.srcElement,
      type: 'wheel',
      deltaMode: originalEvent.type === 'MozMousePixelScroll' ? 0 : 1,
      deltaX: 0,
      deltaY: 0,
      deltaZ: 0,
      preventDefault: () => {
        originalEvent.preventDefault ? originalEvent.preventDefault() : (originalEvent.returnValue = false);
      }
    };

    // calculate deltaY (and deltaX) according to the event
    if (support === 'mousewheel') {
      event.deltaY = (-1 / 40) * originalEvent.wheelDelta;
      // Webkit also support wheelDeltaX
      originalEvent.wheelDeltaX && (event.deltaX = (-1 / 40) * originalEvent.wheelDeltaX);
    } else {
      event.deltaY = originalEvent.detail;
    }

    // it's time to fire the callback
    callback(event);
  }

  private static touchXCoordinate(touch: TouchEvent): number {
    return touch.changedTouches[0].screenX;
  }

  private static isElementIncluded(elementToCheck: HTMLElement, selectors?: InOrExcluded<string>): boolean {
    if (!selectors) {
      return true;
    }

    if (selectors.included?.length) {
      const elements = Array.from(document.querySelectorAll(selectors.included.join(',')));
      return elements.some(element => element.contains(elementToCheck));
    }

    if (selectors.excluded?.length) {
      const elements = Array.from(document.querySelectorAll(selectors.excluded.join(',')));
      return elements.every(element => !element.contains(elementToCheck));
    }

    return true;
  }

  /** Created as wrapper function for rxjs to be easier mockable */
  private static fromUserEvent<T>(target: Document | HTMLElement, eventName: string, passive = false): Observable<T> {
    return fromEvent<T>(target, eventName, passive ? { passive: true } : {});
  }
}

export interface GetKeyEventsOptions {
  /**
   * Restrict the scope of the keyboard event.
   * In case of an included selector, it emits if the event was triggered on the selector element or a child of it.
   * In case of excluded selectors, it fires as long as the event target is not the excluded element or a child of it
   */
  allowedOrExcludedSelector?: InOrExcluded<string>;
  /** whether to disable modifier keys */
  disableModifierKeys?: boolean;
}
