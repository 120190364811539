export type TicketProgressStates = {
  [K in ProgressStatus]: number;
};

export function createTicketProgressState(queued: number, running: number, success: number, warning: number, error: number): TicketProgressStates {
  return {
    [ProgressStatus.Queued]: queued,
    [ProgressStatus.Running]: running,
    [ProgressStatus.Warning]: warning,
    [ProgressStatus.Error]: error,
    [ProgressStatus.Success]: success
  };
}

export interface ItemListProgress extends ItemProgress {
  items: ItemProgress[];
  ticketProgressStates: TicketProgressStates;
}

export interface ItemProgress {
  identifier: string;
  done: number;
  error?: any;
  size: number;
  status: ProgressStatus;
}

export enum ProgressStatus {
  Queued,
  Running,
  Success,
  Error,
  Warning
}
