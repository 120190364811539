<div
  class="celum-scrollbar"
  click-tap
  #nativeScroller
  (onClick)="onEmptySpaceClick.emit($event)"
  (onTap)="onEmptySpaceClick.emit($event)"
  (contextmenu)="onContextMenu.emit($event)"
  infiniteScroll
  [alwaysCallback]="true"
  [infiniteScrollDistance]="1.5"
  [infiniteScrollThrottle]="50"
  [infiniteScrollContainer]="nativeScroller"
  [fromRoot]="fromRoot"
  [scrollWindow]="false"
  (scrolled)="onScroll.emit(true)"
  (scrolledUp)="onScroll.emit(false)">
  <ng-content></ng-content>
</div>
