import { map, Observable, of } from 'rxjs';

import { appendUrlSearchParam, CelumPropertiesProvider } from '@celum/core';

import { ApiConfiguration, AuthInterceptorConfig, ServiceTokenInterceptorConfig } from '../auth.interceptor';
import { AuthTokenRequestOptions, isB2CAccessTokenRequestDto, isB2CTokenRequestDto, NamedAuthToken, TokenRequestDto } from '../model/auth-token.model';
import { ServiceAccessTokenProvider, ServiceTokenRequestOptions } from '../services/service-access-token-provider.service';

/**
 * Get the authentication token which should be used for the service the passed url is pointing to.
 * Returns `null` in case there is no configuration available.
 *
 * @param url                           the url to get the token for
 * @param interceptorConfig             the interceptor configs for the "supported" services
 * @param serviceAccessTokenProvider    the service to retrieve the token from
 * @param opts                          additional options, e.g. tokenLeadTimeInMilliseconds - the duration in milliseconds before token expiration before it is refreshed
 */
export function getAuthToken(
  url: string,
  interceptorConfig: ServiceTokenInterceptorConfig<AuthInterceptorConfig>,
  serviceAccessTokenProvider: ServiceAccessTokenProvider,
  opts?: AuthTokenRequestOptions
): Observable<NamedAuthToken> {
  const apiConfig = retrieveApiConfig(interceptorConfig, url);

  // Calls to services get the according service access token attached
  if (apiConfig) {
    const serviceRequestOptions: ServiceTokenRequestOptions = {
      ...opts,
      tokenEndpoint: apiConfig.tokenEndpoint
    };

    return serviceAccessTokenProvider
      .getServiceAccessToken(apiConfig.serviceTokenRequestDto, serviceRequestOptions)
      .pipe(map(token => ({ token, name: apiConfig.tokenQueryParamName ?? 'token' })));
  }

  return of(null);
}

export function appendTokensToUrl(url: string, tokens: NamedAuthToken[], replace = true): string {
  if (!url || !tokens) {
    return url;
  }

  return tokens.reduce((acc, token) => appendTokenToUrl(acc, token, { replace }), url);
}

export type AppendTokenOptions = { replace?: boolean };

export function appendTokenToUrl(url: string, token: NamedAuthToken, options?: AppendTokenOptions): string {
  const replace = options?.replace ?? true;
  const tokenName = token?.name ?? 'token';

  if (!url) {
    return url;
  }

  // null means no token could be obtained. Undefined is currently reserved for unprotected portals, which do not require tokens.
  if (token === null) {
    console.debug(`AuthTokenUtil: no token returned for url '${url}'.`);
  }

  if (!token) {
    return url;
  }

  return appendUrlSearchParam(url, tokenName, token.token, replace);
}

export function retrieveApiConfig(interceptorConfig: ServiceTokenInterceptorConfig<AuthInterceptorConfig>, url: string): ApiConfiguration {
  const apiConfigurations = interceptorConfig?.getInterceptorConfiguration().apiConfigurations;
  return apiConfigurations?.find(config => config.apiUrls.some(apiUrl => url.startsWith(apiUrl)));
}

/**
 * Checks if the given request requires a B2C token.
 *
 * @param requestDto the request DTO
 * @param url The URL to check
 */
export function isB2COrSaccRequest(requestDto: TokenRequestDto, url: string): boolean {
  return isB2CTokenRequestDto(requestDto) || isB2CAccessTokenRequestDto(requestDto) || url.includes(CelumPropertiesProvider.properties.authentication.saccUrl);
}
